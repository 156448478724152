import { getCurrentApp } from 'e-submission/apps/selectors'
import { get } from 'lodash/fp'
import { getToggles } from 'quick-quote/feature-toggles'
import values from '../../../core/data-model/constants/values'
import { agent } from './agent-selector'
import { beneficiaries } from './beneficiary-selector'
import { coveragePlan as coveragePlanInvestment } from './coverageplan-investment-selector'
import { coveragePlan, coveragePlanMRTA } from './coverageplan-selector'
import { families } from './family-selector'
import { fna } from './fna-selector'
import { insured } from './insured-selector'
import { kyc } from './kyc-selector'
import { note } from './note-selector'
import { payer } from './payer-selector'
import { pdpaConsents } from './pdpa-consents-selector'
import { currentApp, currentQQ } from './primitive-selector'
import { product as productInvestment } from './product-investment-selector'
import { product } from './product-selector'
import { quickQuote } from './quickquote-selector'
import { luckyNumber } from './lucky-number-selector'

export const getPolicyPayload = async (state) => {
  if (currentQQ('selectedDisplayProduct.category')(state) === values.INVESTMENT) {
    return {
      ...note(state),
      ...pdpaConsents(state),
      ...fna(state),
      agent: agent(state),
      insured: {
        ...insured(state),
        KYC: kyc(state),
      },
      payer: payer(state),
      product: productInvestment(state),
      coveragePlan: await coveragePlanInvestment(state),
      beneficiary: beneficiaries(state),
      FATCA: currentApp('FATCA')(state),
      investment: currentQQ('investment')(state),
      entryComponent: currentQQ('entryComponent')(state),
      quickQuote: quickQuote(state),
      calculatedFromTMORate: currentApp('calculatedFromTMORate')(state),
      createdAt: currentApp('createdAt')(state),
      isRemoteSellingEkyc: currentApp('selectedRemoteSelling')(state),
      ekycInfo: currentApp('ekycInfo')(state),
      remoteSelling: get('remoteSelling', getCurrentApp(state)),
      isVCFormILPORD: getToggles().ENABLE_VCFORM_ILPORD,
      luckyNumber: luckyNumber(state),
    }
  } else if (currentQQ('selectedDisplayProduct.category')(state) === values.MRTA) {
    return {
      ...note(state),
      ...pdpaConsents(state),
      ...fna(state),
      agent: agent(state),
      insured: insured(state),
      payer: payer(state),
      coveragePlan: await coveragePlanMRTA(state),
      product: product(state),
      beneficiary: beneficiaries(state),
      FATCA: currentApp('FATCA')(state),
      calculatedFromTMORate: currentApp('calculatedFromTMORate')(state),
      createdAt: currentApp('createdAt')(state),
      family: families(state),
      entryComponent: currentQQ('entryComponent')(state),
      quickQuote: quickQuote(state),
      isRemoteSellingEkyc: currentApp('selectedRemoteSelling')(state),
      ekycInfo: currentApp('ekycInfo')(state),
      remoteSelling: get('remoteSelling', getCurrentApp(state)),
      isVCFormILPORD: getToggles().ENABLE_VCFORM_ILPORD,
      luckyNumber: luckyNumber(state),
    }
  } else {
    return {
      ...note(state),
      ...pdpaConsents(state),
      ...fna(state),
      agent: agent(state),
      insured: insured(state),
      payer: payer(state),
      coveragePlan: await coveragePlan(state),
      product: product(state),
      beneficiary: beneficiaries(state),
      FATCA: currentApp('FATCA')(state),
      calculatedFromTMORate: currentApp('calculatedFromTMORate')(state),
      createdAt: currentApp('createdAt')(state),
      family: families(state),
      entryComponent: currentQQ('entryComponent')(state),
      quickQuote: quickQuote(state),
      isRemoteSellingEkyc: currentApp('selectedRemoteSelling')(state),
      ekycInfo: currentApp('ekycInfo')(state),
      remoteSelling: get('remoteSelling', getCurrentApp(state)),
      isVCFormILPORD: getToggles().ENABLE_VCFORM_ILPORD,
      luckyNumber: luckyNumber(state),
    }
  }
}
