import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  join,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { buffers, delay, eventChannel } from 'redux-saga'
import {
  assign,
  flow,
  get,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  keyBy,
  map,
  negate,
  omit,
  startsWith,
  cloneDeep,
  find,
  getOr,
} from 'lodash/fp'
import _ from 'lodash'
import {
  getAllAttachment,
  getAppList,
  getCurrentApp,
  getAppInformation,
  getCurrentAppId,
  getData,
  getDraft,
  getReplicationStatus,
  isHideDialog,
  getRelationshipListForNewATP,
  getAIUW,
} from 'e-submission/apps/selectors'
import {
  APPLICATION_FETCH_UPDATE_SUCCESS,
  APPLICATION_FETCH_UPDATE_ERROR,
  APPLICATION_INSTALL_UPDATE_SUCCESS,
  APPLICATION_INSTALL_UPDATE_ERROR,
  REMOTE_SELLING_CREATE_NEW_LINK,
  REMOTE_SELLING_USING_EXISTS_LINK,
  REMOTE_SELLING_RESET_DATA,
  REMOTE_SELLING_SUBMIT_RESUME_MODAL,
  ENTER_REMOTE_SELLING,
  CONFIRM_REMOTE_SELLING,
  CLEAR_REMOTE_SELLING_RESULT,
  CLEAR_EKYC_RESULT,
  ENTER_EKYC_F2F,
} from 'core/action-types'
import {
  deletePhoto,
  hideDialog,
  hideLoading,
  loadApplication,
  mergeApplication,
  removeFile,
  setAppStatusDrafted,
  setAppStatusSigned,
  setAppValue,
  setOffOtp,
  setReplicationStatus,
  showErrorDialog,
  showLoading,
  addError,
  showSubmittedFlashMessage,
  showSyncingDialog,
  cleanDCA,
  setFrontIdCardImage,
  setResendEkycLink,
  removeError,
  setBackIdCardLaserCode,
  showDialog,
  openSendingLinkModalRemoteSelling,
  closeRemoteSellingResumeModal,
  openNative,
  changeCurrentApp,
  initRemoteSelling,
  initOldRemoteSelling,
  closeSendingLinkModalRemoteSelling,
  getAIUWStatus,
  saveAppData,
} from 'e-submission/apps/actions'
import {
  applicationFetchUpdate,
  clearEkycResults,
  confirmUsingRemoteSelling,
  remoteSellingCreateNewLink,
  resetRemoteSellingData,
} from 'core/actions'
import { APP_STATUS, REMOTE_SELLING, REPLICATION_STATUS } from 'e-submission/domain/data-model/constants'
import {
  getAppStatus,
  getInsuredEkycCaseId,
  getPolicyId,
  getRemoteSellingCaseId,
  getRemoteSellingState,
  getSkippedInsuredEkyc,
  getSkippedPayerEkyc,
  insurerDoesEkyc,
  isAdditionalDocument,
  isCreditCardRelationshipDocument,
  isSelectedRemoteSelling,
  listSignature,
  payerDoesEkyc,
} from 'e-submission/domain/data-model/form/selectors'
import { getAppByOptyId } from 'e-submission/domain/service/opty'
import {
  blobToDataUrl,
  isCordova,
  productDisableFlag,
  prepareEkycDetailResult,
  getVerificationDetail,
} from 'e-submission/utils'
import { getIdentityUser } from 'identity/selectors'
import { onListDocument } from './document'
import { onListPayment } from './payment'
import { mappingCaseKeeper, resendCasePayload, setAttachmentPayload } from './policy-payload/ekyc-case-keeper'
import { getAllowedAgentTypes } from 'quick-quote/selectors'

import { validateAdvisorPermission } from 'core/service/distributor/permission'
import { setProcessingSuccess } from 'quick-quote/actions'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { getToggles } from 'quick-quote/feature-toggles'
import { getAppConfig } from 'deploy-env/app-config'
import { isUsedTMORate } from 'core/service/rider/premium-rate'
import { isUsedCoiTMORate } from 'core/service/investment/cash-flow/cash-flow'
import values from 'core/data-model/constants/values'
import { getInCall } from 'quick-quote/remote-selling'
import { analytics } from 'analytics/analytics'
import { updateOptyStageEAppCreate } from 'quick-quote/opty/actions'
import { getQueryParam } from 'quick-quote/opty/utils'
import { getATPDefaultRelation } from 'e-submission/sagas/utils'
import { optionsResumeRMS } from 'e-submission/containers/Dialog/constants'
import ROUTE_PATH from 'quick-quote/constants/path'

export const MINIMUM_DELAY_SHOW_SUBMMIT_DIALOG = 5000
export const CHECK_SUBMITTING_DELAY = 10000
export const CHECK_SYNC_DELAY = 1000

export const DEBOUNCE_DELAY = 600
export const RETRY_DELAY = 0

const isExcludeSave = (action) => {
  const fieldId = get('fieldId', action)

  if (fieldId === 'createdAt') {
    return true
  }

  if (fieldId === 'updatedAt') {
    return true
  }

  if (startsWith('receipts', fieldId) && !isEqual('receipts', fieldId)) {
    return true
  }

  if (startsWith('insured', fieldId) && !isEqual('insured', fieldId)) {
    return true
  }

  if (startsWith('beneficiary', fieldId) && !isEqual('beneficiary', fieldId)) {
    return true
  }

  if (startsWith('payer', fieldId) && !isEqual('payer', fieldId)) {
    return true
  }

  if (startsWith('FATCA', fieldId) && !isEqual('FATCA', fieldId)) {
    return true
  }

  if (startsWith('document.certifyByAgent', fieldId) && !isEqual('certifyByAgent', fieldId)) {
    return true
  }

  if (startsWith('KYC', fieldId) && !isEqual('KYC', fieldId)) {
    return true
  }

  if (startsWith('creditCardRelationship', fieldId) && !isEqual('creditCardRelationship', fieldId)) {
    return true
  }

  if (startsWith('insurancePrivacyConsent', fieldId) && isEqual('insurancePrivacyConsent', fieldId)) {
    return true
  }

  if (startsWith('marketingPrivacyConsent', fieldId) && isEqual('marketingPrivacyConsent', fieldId)) {
    return true
  }

  if (startsWith('DCA', fieldId) && !isEqual('DCA', fieldId)) {
    return true
  }
  if (startsWith('ATP', fieldId) && !isEqual('ATP', fieldId)) {
    return true
  }
  if (isEqual('remoteSelling.email', fieldId) || isEqual('remoteSelling.phoneNumber', fieldId)) {
    return true
  }

  if (
    startsWith('luckyNumber', fieldId) &&
    !isEqual('luckyNumber.status', fieldId) &&
    !isEqual('luckyNumber.selected', fieldId)
  ) {
    return true
  }

  return false
}
const isNoDebounceSave = (action) => {
  const fieldId = get('fieldId', action)

  if (startsWith('receipts', fieldId)) {
    return true
  }

  const noDebounceFields = ['_attachments', 'payment.type', 'policyId', 'receiptNo', 'status']
  return noDebounceFields.includes(fieldId)
}

const getUserId = get('profile.sub')
const isLoggedIn = flow(getUserId, negate(isNil))

export const canSync = function*() {
  const user = yield select(getIdentityUser)

  return isLoggedIn(user)
}

const removeNullValue = (obj) => {
  try {
    return JSON.parse(JSON.stringify(obj), (key, value) => {
      if (value === null) return undefined
      return value
    })
  } catch (err) {
    return obj
  }
}

export let taskSync = null
export const onSave = function*(service, currentApp) {
  const { getApplicationRev, saveApplication } = service

  const user = yield select(getIdentityUser)

  let app = currentApp

  if (isNil(currentApp)) {
    app = yield select(getCurrentApp)
  }

  if (yield call(canSync)) {
    while (true) {
      try {
        const _rev = yield call(getApplicationRev, app, user)
        const newApp = removeNullValue({
          ...app,
          _rev,
          updatedAt: Date.now(),
        })

        if (newApp.status !== 'submitted') {
          taskSync = yield call(saveApplication, newApp, user)
        }

        yield put({ type: 'SAVE_APPLICATION_SUCCESS' })

        break
      } catch (err) {
        if (get('response.status', err) !== 409) {
          yield put({ type: 'SAVE_APPLICATION_FAIL' })
          break
        }
      }

      yield delay(RETRY_DELAY)
    }
  }
}

export const onDebounceSave = function*(service, action) {
  if (isExcludeSave(action)) {
    const fieldId = get('fieldId', action)
    if (getToggles().ENABLE_EDA && (isEqual('ATP.firstName', fieldId) || isEqual('ATP.lastName', fieldId))) {
      const app = yield select(getCurrentApp)
      const relationType = getATPDefaultRelation(app)
      const isManualATPRelationship = get('ATP.isManualRelationship', app)
      if (!isManualATPRelationship) {
        const relationshipList = yield select(getRelationshipListForNewATP)
        const relationship = find(({ value }) => value === relationType, relationshipList)

        yield put(setAppValue('ATP.relations', relationship))
      }
    }

    return
  }

  if (!isNoDebounceSave(action)) {
    yield delay(DEBOUNCE_DELAY)
  }

  yield fork(onSave, service)
}

export const onLoad = function*(service) {
  const { getApplication } = service

  if (yield call(canSync)) {
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)

    const appId = get('_id', app)

    if (appId) {
      const data = yield select(getData)
      if (isEmpty(data)) {
        yield take('SET_DATA')
      }

      let response
      try {
        response = yield call(getApplication, app, user)
        if (response.remoteSelling) {
          response.remoteSelling = {
            ...response.remoteSelling,
            isInCall: false,
            callStatus: { isInCall: false },
            isModalCreatingRemoteSellingOpen: false,
            isResendModalOpen: false,
            isOpenNewRemoteSellingPreviewInfoModal: false,
          }
        }
      } catch (err) {}

      const newApp = removeNullValue(omit(['_rev'], response))
      yield put(loadApplication(newApp))
    }
  }
}

export const onCreate = function*(service, action) {
  const { saveApplication, setAttachment, getApplicationRev } = service
  if (yield call(canSync)) {
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)

    const appId = get('_id', app)
    if (appId) {
      try {
        yield put.resolve(showLoading())

        const biProps = yield select((state) => state.quickQuoteInterface.biProps)
        const bi = JSON.stringify(omit(['accessToken', 'basicPlan.image'], biProps))

        let data = new Blob([bi], { type: 'text/plain', encoding: 'UTF-8' })
        if (isCordova) {
          data = yield call(blobToDataUrl, data)
        }

        yield put(setAppValue('createdAt', Date.now()))
        yield put(setAppValue('updatedAt', Date.now()))

        const draft = yield select(getDraft)

        if (biProps !== undefined) {
          let isTMORate = false
          const coiRateUsed = yield call(isUsedCoiTMORate)
          const tmoRateUsed = yield call(isUsedTMORate)
          if (biProps.productCategory === values.INVESTMENT) {
            isTMORate = coiRateUsed
          } else if (biProps.productCategory === values.WHOLE_LIFE) {
            isTMORate = tmoRateUsed
          }
          yield call(saveApplication, { ...draft, calculatedFromTMORate: isTMORate }, user)
        } else {
          yield call(saveApplication, draft, user)
        }

        const _rev = yield call(getApplicationRev, app, user)
        const newApp = {
          ...app,
          _rev,
        }

        yield call(setAttachment, newApp, 'bi', data, user)

        yield [call(onLoad, service), take('SAVE_APPLICATION_SUCCESS')]

        yield put(updateOptyStageEAppCreate())
      } catch (err) {
        console.error(err)
        const message = err.message
        const notificationMessage = getToggles().ENABLE_RED_TRUCK_ERROR_MSG
          ? { type: 'GenericError', message: message }
          : { type: 'GenericError' }
        yield put(setNotificationMessage(notificationMessage))
        window.history.back()
      } finally {
        yield put(hideLoading())
        yield put(setProcessingSuccess())
      }
    }
  }
}

export const waitInitSubmitPage = function*(service) {
  const app = yield select(getCurrentApp)
  const appStatus = getAppStatus(app)
  if (appStatus === APP_STATUS.SIGNED) {
    yield call(onListPayment, service)
    yield call(onListDocument, service)
  }
}

export const patchBrokenPolicy = function*(service) {
  const { getPolicyStatus } = service

  const app = yield select(getCurrentApp)
  const policyId = get('policyId', app)
  const appStatus = get('status', app)
  const user = yield select(getIdentityUser)

  // Patch broken policy that connection is failed during server response back when OTP.
  if (policyId && appStatus !== APP_STATUS.SIGNED && appStatus !== APP_STATUS.SUBMITTED) {
    try {
      const policy = yield call(getPolicyStatus, user, policyId)
      const policyStatus = get('status', policy)
      if (policyStatus === APP_STATUS.SIGNED) {
        yield [put(setOffOtp()), take('SAVE_APPLICATION_SUCCESS')]
        yield [put(setAppStatusSigned()), take('SAVE_APPLICATION_SUCCESS')]
      }
    } catch (err) {}
  }
}

export const onApplicationCardClicked = function*(service, action) {
  yield put(changeCurrentApp(action.appId))
}

export const onUpdate = function*(service, action) {
  yield put.resolve(showLoading())
  const app = yield select(getCurrentApp)
  const appId = get('_id', app)

  try {
    if (yield call(canSync)) {
      if (appId) {
        try {
          yield call(onLoad, service)
          console.log('after onLoad')
          const status = getOr('drafted', 'status', app)
          if (isNil(status) || status === APP_STATUS.DRAFTED) {
            console.log('is draft')
            const currentApp = yield select(getCurrentApp)
            const remoteSelling = getOr(false, 'isRemoteSelling', currentApp)
            const selectedRemoteSelling = getOr(false, 'selectedRemoteSelling', currentApp)
            const isAllowedRevisedRemoteSelling = getToggles().ENABLE_APPMAN_REMOTE_SELLING
            if (remoteSelling && isAllowedRevisedRemoteSelling) {
              console.log('initRemoteSelling')
              yield put(initRemoteSelling())
            }
            if (selectedRemoteSelling && !isAllowedRevisedRemoteSelling) {
              console.log('initOldRemoteSelling')
              yield put(initOldRemoteSelling())
            }
          }
          yield call(waitInitSubmitPage, service)
          yield call(patchBrokenPolicy, service)
          if (isNil(status) || status === APP_STATUS.SIGNED) {
            const loadedApp = yield select(getCurrentApp)
            const AIUW = getAIUW(loadedApp)
            if (AIUW && AIUW.status === 'requesting') {
              yield put(setAppValue('AIUW.status', 'requested'))
              yield put(saveAppData())
            }
            yield put(getAIUWStatus())
          }
        } catch (err) {
        } finally {
          yield put(hideLoading())
        }
      }
    }
  } catch (e) {
    console.error(`onUpdate :`, e.message)
  }
}

const isListPage = () => {
  const pathname = window.location.href
  return includes('/e-submission/list', pathname)
}

export const onSync = function*(service) {
  if (isCordova) {
    const { subscribeReplicationStatus, listApplicationSummary } = service

    const channel = eventChannel((emit) => {
      subscribeReplicationStatus(emit, (err) => {
        console.error(err)
      })
      return () => subscribeReplicationStatus()
    }, buffers.sliding(1))

    while (true) {
      if (yield cancelled()) {
        channel.close()
        break
      }

      const newStatus = yield take(channel)
      const currentStatus = yield select(getReplicationStatus)

      if (currentStatus === newStatus && currentStatus === REPLICATION_STATUS.IDLE) continue

      yield put(setReplicationStatus(newStatus))

      if (isListPage()) {
        const user = yield select(getIdentityUser)

        if (isLoggedIn(user)) {
          try {
            const response = yield call(listApplicationSummary, user)
            const appSummaries = flow(map(omit(['_rev'])), keyBy('_id'))(response)

            if (isListPage()) {
              const currentAppId = yield select(getCurrentAppId)
              yield put(mergeApplication(currentAppId, appSummaries))
            }
          } catch (err) {}
        }
      }
    }
  }
}

export const onList = function*(service) {
  const { listApplicationSummary } = service
  if (getToggles().ENABLE_MANUAL_CORDOVA_UPDATE && window.chcp) {
    // start update
    yield put(applicationFetchUpdate())
    // wait for update procrss to completed
    const { fetchError } = yield race({
      fetchSuccess: take(APPLICATION_FETCH_UPDATE_SUCCESS),
      fetchError: take(APPLICATION_FETCH_UPDATE_ERROR),
    })
    // block go to esub if failed
    if (fetchError) {
      return
    }

    const { installError } = yield race({
      installSuccess: take(APPLICATION_INSTALL_UPDATE_SUCCESS),
      installError: take(APPLICATION_INSTALL_UPDATE_ERROR),
    })
    // block go to esub if failed
    if (installError) {
      return
    }
  }
  yield put.resolve(showLoading())

  let currentAppId = yield select(getCurrentAppId)
  // const currentAppId = yield select(getCurrentAppId)
  const user = yield select(getIdentityUser)

  if (isLoggedIn(user)) {
    yield put(mergeApplication(currentAppId, {}))
  } else {
    yield put(mergeApplication(null, {}))
    yield put(hideLoading())
    return
  }

  const search = getQueryParam(_.get(window.location, ['search'], null))
  const isDeeplink = localStorage.getItem('isDeeplink') === 'true'
  const deeplinkParam = isDeeplink
    ? JSON.parse(localStorage.getItem('deeplinkParam'))
    : getQueryParam(_.get(history, ['location', 'search'], ''))
  const deeplinkOptyId = _.get(deeplinkParam, ['opty'], null)

  try {
    let deeplinkData = {}
    let appSummaries = {}
    if (search && !_.isEmpty(search) && deeplinkOptyId) {
      const { response } = yield call(getAppByOptyId, user, deeplinkOptyId)
      currentAppId = _.get(response, ['applicationId'], null)
      deeplinkData = {
        [currentAppId]: {
          ...response,
        },
      }
      deeplinkData[currentAppId]._id = currentAppId
      appSummaries = flow(map(omit(['_rev'])), keyBy('_id'))(deeplinkData)
      yield put(mergeApplication(currentAppId, appSummaries))

      const responseDefault = yield call(listApplicationSummary, user)
      appSummaries = flow(map(omit(['_rev'])), keyBy('_id'))(responseDefault)
      // yield put(mergeApplication(currentAppId, appSummaries))
    } else {
      const responseDefault = yield call(listApplicationSummary, user)
      appSummaries = flow(map(omit(['_rev'])), keyBy('_id'))(responseDefault)
      yield put(mergeApplication(currentAppId, appSummaries))
    }
  } catch (err) {
  } finally {
    yield put(hideLoading())
  }
}

export const onValidateAdvisorPermission = function*(service) {
  const { getAdvisor } = service
  const user = yield select(getIdentityUser)
  const agentType = get('profile.agent_type', user)
  const agentCode = get('profile.agent_code', user)

  const advisor = yield getAdvisor(user, agentCode, agentType)

  const allowedAgentTypes = yield select(getAllowedAgentTypes)

  return validateAdvisorPermission(advisor, allowedAgentTypes)
}

export const onSubmit = function*(service) {
  try {
    yield put.resolve(showLoading())

    if (getToggles().ALLOW_CHECK_CREATE_POLICY_PERMISSION) {
      const isValidPermission = yield call(onValidateAdvisorPermission, service)
      if (!isValidPermission) {
        yield put(showErrorDialog('invalidPermission'))
        throw new Error('Invalid permission')
      }
    }

    const app = yield select(getCurrentApp)
    const policyId = getPolicyId(app)

    let action = []
    if (isNil(policyId)) {
      action = yield [
        race({
          succeed: take('CREATE_POLICY_SUCCEED'),
          failed: take('CREATE_POLICY_FAILED'),
        }),
        put({ type: 'CREATE_POLICY' }),
      ]
      console.info('Creating policy')
    } else {
      action = yield [
        race({
          succeed: take('UPDATE_POLICY_SUCCEED'),
          failed: take('UPDATE_POLICY_FAILED'),
        }),
        put({ type: 'UPDATE_POLICY' }),
      ]
    }

    const [{ succeed, failed }] = action

    if (failed) {
      throw new Error(failed.message)
    }

    if (isNil(policyId)) {
      const { policyID } = succeed

      yield [put(setAppValue('policyId', policyID)), take('SAVE_APPLICATION_SUCCESS')]
    }

    const signatures = listSignature(app)

    yield map(({ key }) => put(removeFile(key)), signatures)
  } catch (err) {
    console.error(err)
    window.history.back()
  } finally {
    console.info('onSubmit competed')
    yield put(hideLoading())
  }
}

export const fetchAllDocument = function*(service) {
  const { listDocument } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)
  if (!policyId) return []
  const allDocuments = yield call(listDocument, user, policyId)

  return flow(
    map(({ documentType, documentId, description, code, isInsured }) => {
      if (isAdditionalDocument(documentType)) {
        return {
          name: `${documentType}_${documentId}`,
          text: description,
          isInsured,
        }
      }
      if (isCreditCardRelationshipDocument(documentType)) {
        return {
          name: `${documentType}_${documentId}`,
          text: description,
        }
      }
      return {
        name: code,
      }
    })
  )(allDocuments)
}

export const onValidationSubmit = function*() {
  const validateActions = [cleanDCA]
  for (const action of validateActions) {
    yield put(action())
  }
}

export const onSend = function*(service, action) {
  const history = action.payload
  try {
    const app = yield select(getCurrentApp)
    const isAppmanRemoteSelling = isSelectedRemoteSelling(app)
    const isInCall = getInCall(app)
    if (isAppmanRemoteSelling && isInCall) {
      yield put(
        showDialog({
          name: 'Confirmation',
          message: {
            title: 'ส่งใบคำขอ',
            body: 'หากดำเนินการกดส่งใบคำขอ สายสนทนาระหว่างท่านและผู้เอาประกันจะสิ้นสุดทันที',
          },
          onlyConfirm: true,
          confirmText: 'ส่งใบคำขอ',
        })
      )
      const { canceled } = yield race({ confirm: take('CONFIRM_YES'), canceled: take('HIDE_DIALOG') })
      if (canceled) {
        yield cancel()
        return
      }
    }
    const delayTask = yield fork(delay, MINIMUM_DELAY_SHOW_SUBMMIT_DIALOG)
    const appWithId = yield select(getAppInformation)
    const productDisabled = productDisableFlag(appWithId)
    yield [put(setAppValue('productDisabled', productDisabled)), take('SAVE_APPLICATION_SUCCESS')]
    yield put(showSyncingDialog())

    yield call(onValidationSubmit)

    const documents = yield call(fetchAllDocument, service)

    const allAttachments = flow(assign({ documents }), getAllAttachment)(app)

    yield put(
      showSyncingDialog({
        step: 'uploading',
        attachments: allAttachments,
      })
    )

    yield [put(setAppValue('status', APP_STATUS.SUBMITTING)), take('SAVE_APPLICATION_SUCCESS')]

    if (yield select(isHideDialog)) {
      yield cancel()
    }

    yield put(
      showSyncingDialog({
        step: 'submitting',
        attachments: allAttachments,
      })
    )

    let count = 0,
      invalidMessage = false
    while (true) {
      if (yield select(isHideDialog)) {
        yield cancel()
        break
      }

      count = count + 1

      try {
        const [{ succeed, failed }] = yield [
          race({
            succeed: take('SUBMIT_POLICY_SUCCEED'),
            failed: take('SUBMIT_POLICY_FAILED'),
          }),
          put({ type: 'SUBMIT_POLICY' }),
        ]

        if (failed) {
          invalidMessage = failed.message
          throw new Error(failed.message)
        }

        if (succeed) {
          break
        }
      } catch (err) {
        yield delay(CHECK_SYNC_DELAY)
      } finally {
        if (isArray(invalidMessage)) {
          count = getAppConfig().MAXIMUM_RETRY_SUBMIT_COUNT
          yield put(
            showSyncingDialog({
              step: 'invalid-attachments',
              invalidAttachments: invalidMessage,
            })
          )
          break // NOSONAR
        }

        if (count >= getAppConfig().MAXIMUM_RETRY_SUBMIT_COUNT) {
          yield put(
            showSyncingDialog({
              step: 'failed',
              attachments: allAttachments,
            })
          )
          break // NOSONAR
        }
      }
    }

    yield join(delayTask)

    if (count < getAppConfig().MAXIMUM_RETRY_SUBMIT_COUNT) {
      history.replace('/e-submission/list')

      yield put(hideDialog())
    }
  } catch (err) {
    console.error(err)
    yield put(hideDialog())
  }
}

export const fetchApp = function*(service, app, payload) {
  const { getApplication } = service

  const user = yield select(getIdentityUser)
  const currentApp = yield call(getApplication, app, user)

  const newApp = {
    ...currentApp,
    ...payload,
  }

  yield [take('SAVE_APPLICATION_SUCCESS'), call(onSave, service, newApp)]
  yield put(loadApplication(newApp))
}
export const checkSubmitting = function*(service, app) {
  const { getPolicyStatus } = service

  const user = yield select(getIdentityUser)
  const policyId = getPolicyId(app)

  try {
    const response = yield call(getPolicyStatus, user, policyId)

    if (app.status === response.status) return

    let payload = {
      status: response.status,
    }

    if (response.status === APP_STATUS.FAILED) {
      const documents = yield call(fetchAllDocument, service)

      payload = {
        ...payload,
        documents,
      }
    }

    yield call(fetchApp, service, app, payload)

    if (response.status === APP_STATUS.SUBMITTED) {
      yield put(showSubmittedFlashMessage())
      analytics.sendCustomEvent({
        category: 'Submission_Event',
        action: 'Success_Submit_AG',
        label: '',
      })
    }
  } catch (err) {
    analytics.sendCustomEvent({
      category: 'Submission_Event',
      action: 'Fail_Submit_AG',
      label: '',
    })
    console.error(err)
  }
}
export const onCheckSubmitting = function*(service) {
  yield [take('MERGE_APP'), take('MERGE_APP')]

  while (true) {
    if (yield cancelled()) {
      break
    }

    const appList = yield select(getAppList)
    const submittingList = get(APP_STATUS.SUBMITTING, appList)

    const replicationStatus = yield select(getReplicationStatus)

    if (!isEmpty(submittingList) && replicationStatus === REPLICATION_STATUS.IDLE) {
      yield map((app) => call(checkSubmitting, service, app))(submittingList)
    }

    yield delay(CHECK_SUBMITTING_DELAY)
  }
}

export const onErrorOtpAttachments = function*(service, history, action) {
  const errors = get('payload.errors')(action)
  try {
    yield [put(setAppStatusDrafted()), take('SAVE_APPLICATION_SUCCESS')]

    //TODO should change to "for ( error of errors )" and " put(deletePhoto(get('documentType')(error))),"
    for (var index in errors) {
      yield [put(deletePhoto(get(`${index}.documentType`)(errors))), take('SAVE_APPLICATION_SUCCESS')]
    }
  } catch (e) {
  } finally {
    history.replace('/e-submission/form/signature')
  }
}

export const onCreateEkycByDevice = function*(service, action) {
  try {
    const _action = cloneDeep(action)
    _action.payload.createdFromDevice = true
    yield onSendLinkEkyc(service, _action)
  } catch (e) {
    console.error(`ERROR : onCreateEkycByDevice ${e.message}`)
  }
}

export const onSendLinkEkyc = function*(service, action) {
  const app = yield select(getCurrentApp)
  yield put.resolve(showLoading())
  const user = yield select(getIdentityUser)
  try {
    const { getVerifyCitizenId, createEkycCase, resendEkycLink } = service
    const currentProprietorId = _.get(app, `ekycInfo.${action.payload.insuredOrPayer}.proprietorId`, '')

    if (!action.payload.onDupModal) {
      if (!currentProprietorId) {
        const citizenId = isEmpty(action.payload.citizenId) ? undefined : action.payload.citizenId
        const { response } = yield call(getVerifyCitizenId, user, citizenId)

        const cases = get(['data'], response)
        const verifiedCases = cases.filter((caseElement, index) => {
          const verifiedCase = caseElement.verificationCache.status === 'verified'
          return verifiedCase
        })

        const verifiedCasesAmount = verifiedCases.length
        const isEkycDuplicateCitizenId = verifiedCasesAmount > 0
        if (isEkycDuplicateCitizenId) {
          return yield put(
            addError('EKYC_DUPLICATED_CITIZENID', {
              message: 'duplicate citizenId',
              citizenId,
              count: verifiedCasesAmount,
              cases: verifiedCases,
            })
          )
        }
      } else {
        const resendPayload = resendCasePayload(app, currentProprietorId)
        return yield call(resendEkycLink, user, resendPayload)
      }
    }
    const ekycCaseKeeperPayload = mappingCaseKeeper(
      app,
      action.payload.insuredOrPayer,
      action.payload.createdFromDevice
    )
    const ekycCase = yield call(createEkycCase, user, ekycCaseKeeperPayload)
    yield put(setResendEkycLink(action.payload.insuredOrPayer))
    const proprietorId = get(['response', 'proprietors', '0', 'id'], ekycCase)
    const caseId = get(['response', 'proprietors', '0', 'caseId'], ekycCase)
    const policyId = get(['response', 'policies', '0', 'id'], ekycCase)
    yield put(setAppValue(`ekycInfo.${action.payload.insuredOrPayer}.proprietorId`, proprietorId))
    yield put(setAppValue(`ekycInfo.${action.payload.insuredOrPayer}.caseId`, caseId))
    yield put(setAppValue(`ekycInfo.${action.payload.insuredOrPayer}.policyId`, policyId))
    yield put(setAppValue(`ekycInfo.${action.payload.insuredOrPayer}.resendLink`, true))
  } catch (error) {
    console.error(`ERROR onSendLinkEkyc : ${error.message}`)
    yield put(addError('EKYC_VERIFY_CITIZENID', error))
  } finally {
    yield put(hideLoading())
    yield call(onVerifyEkyc, service, action)
  }
}

export const onVerifyEkyc = function*(service, action) {
  yield put(removeError('EKYC_VERIFY_PROPRIETORID'))
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  let { insuredOrPayer } = action.payload
  let verificationId = null
  let isEkycVerifyProprietorIdError = null
  const proprietorId = _.get(app, `ekycInfo.${insuredOrPayer}.proprietorId`, '')
  const { verifyByProprietorId, saveAttachmentFromURL } = service
  if (proprietorId) {
    try {
      let isSkipped = false
      switch (insuredOrPayer) {
        case 'insured':
          isSkipped = getSkippedInsuredEkyc(app)
          break
        case 'payer':
          isSkipped = getSkippedPayerEkyc(app)
          break
      }
      if (isSkipped) {
        return
      }
      const { response } = yield call(verifyByProprietorId, user, proprietorId)
      const verificationDetail = getVerificationDetail(response)
      verificationId = _.get(response, 'id', null)
      yield put(setAppValue(`ekycInfo.${insuredOrPayer}.verificationDetail`, { ...verificationDetail }, true))
      yield put(setAppValue(`ekycInfo.${insuredOrPayer}.verificationId`, verificationId))

      const { frontIdCardResult, backIdCardResult } = prepareEkycDetailResult(response)
      yield put(setBackIdCardLaserCode(backIdCardResult.laser, insuredOrPayer))

      const verificationDetailEdit = _.get(response, 'frontIdCardResult.edits')

      // check frontIdCardResult.edits is empty or not
      if (verificationDetailEdit) {
        if (getToggles().ENABLE_EKYC_F2F_RECREATE_CASE) {
          yield put(setAppValue(`ekycInfo.${insuredOrPayer}.isDataConfirmed`, true, true))
        } else {
          yield put(setAppValue('ekycInfo.isDataConfirmed', true, true))
        }
        // Set {insured | payer}.idNo.xxxxx to be the same as frontIdCardResult.edits.xxxxx
        // DIGSALTOOL-1991 - Not replacing EKYC data firstname, lastname, citizenID
        if (frontIdCardResult.citizenId) {
          yield put(
            setAppValue(`${insuredOrPayer}.idNo.citizenId`, frontIdCardResult.citizenId.replaceAll('-', ''), true)
          )
        }
      }
      yield take('SAVE_APPLICATION_SUCCESS')

      const url = _.get(response, 'frontIdCardResult.response.idCardImageUrl', null)
      if (url) {
        const payload = setAttachmentPayload(insuredOrPayer, app, url, 'id-card')
        const newAttachments = yield call(saveAttachmentFromURL, user, payload)
        yield put(setAppValue(`_attachments.${payload.attachmentId}`, newAttachments[payload.attachmentId]))
        action.payload.imageName = insuredOrPayer === 'insured' ? 'insured-id-card' : 'owner-id-card'
        yield call(onGetAttachment, service, action)
      }

      yield put(removeError('EKYC_VERIFY_PROPRIETORID'))
    } catch (error) {
      yield put(addError('EKYC_VERIFY_PROPRIETORID', error))
      isEkycVerifyProprietorIdError = true
      if (
        action.payload.createdFromDevice &&
        action.payload.openRespondModalOnFailedCase &&
        typeof action.payload.openRespondModalOnFailedCase === 'function'
      ) {
        action.payload.openRespondModalOnFailedCase()
      }
    } finally {
      if (action.payload.createdFromDevice && !isEkycVerifyProprietorIdError) {
        const ekycConfiguration = {
          tenantConfigClientName: getAppConfig().EKYCSDK_TENANT_CONFIG_CLIENT_NAME,
          baseURL: getAppConfig().EKYCSDK_BASE_URL,
          tenantConfigURL: getAppConfig().EKYCSDK_TENANT_CONFIG_URL,
          ekycURL: getAppConfig().EKYCSDK_EKYC_URL,
          verificationId,
        }
        yield window.ekycCallEvent('open', ekycConfiguration)
        const _action = cloneDeep(action)
        _action.payload.createdFromDevice = false
        yield onVerifyEkyc(service, _action)
      }
    }
  }
}

export const onGetAttachment = function*(service, action) {
  const app = yield select(getCurrentApp)
  const user = yield select(getIdentityUser)
  const imageName = action.payload.imageName
  const insuredOrPayer = action.payload.insuredOrPayer
  const { getAttachment } = service
  try {
    const response = yield call(getAttachment, app, imageName, user)
    const dataUrl = yield call(blobToDataUrl, new Blob([response], { type: 'image/jpeg' }))
    yield put(setFrontIdCardImage(dataUrl, insuredOrPayer))
    return response
  } catch (error) {
    return
  }
}

export const onCreateNewLinkRemoteSelling = function*(service, action) {
  const app = yield select(getCurrentApp)

  const remoteSelling = get('remoteSelling', app)

  const resettingRemoteSelling = {
    ...remoteSelling,
    isLinkModalOpen: true,
    isResendModalOpen: false,
  }
  yield put(setAppValue(`remoteSelling`, resettingRemoteSelling))
}

export const onResetRemoteSelling = function*(service, action) {
  const app = yield select(getCurrentApp)
  const remoteSellingFields = REMOTE_SELLING.initialValue
  const insuredEmail = get('insured.email', app)
  const insuredPhoneNumber = get('insured.mobileNumber', app)

  const resettingRemoteSelling = {
    ...remoteSellingFields,
    email: insuredEmail,
    phoneNumber: insuredPhoneNumber,
    sms: insuredPhoneNumber,
    isDataConfirmed: true,
    isOpenNewRemoteSellingPreviewInfoModal: false,
  }
  yield put(setAppValue(`remoteSelling`, resettingRemoteSelling))
}

export const onReuseEkyc = function*(service, action) {
  yield put.resolve(showLoading())
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)

  const { proprietorId, insuredOrPayer } = action.payload
  // console.log('DIGSALTOOL-1979 onReuseEkyc', { insuredOrPayer, applicationId })
  const { verifyByProprietorId, saveAttachmentFromURL, createEkycCase, updateVerificationRef } = service
  try {
    // get verificationRef
    // console.log('DIGSALTOOL-1979 verifyByProprietorId', { proprietorId, applicationId })
    const { response } = yield call(verifyByProprietorId, user, proprietorId)
    const verificationRef = get(['id'], response)
    yield put(setAppValue(`ekycInfo.${insuredOrPayer}.verificationRef`, verificationRef))

    // create new eKYC without notify method
    const isReuse = true
    // console.log('DIGSALTOOL-1979 mappingCaseKeeper', { isReuse, applicationId })
    const ekycCaseKeeperPayloadWithOutNotifyType = mappingCaseKeeper(app, insuredOrPayer, isReuse)
    // console.log('DIGSALTOOL-1979 createEkycCase', { ekycCaseKeeperPayloadWithOutNotifyType, applicationId })
    const newEkycCase = yield call(createEkycCase, user, ekycCaseKeeperPayloadWithOutNotifyType)
    const newProprietorId = get(['response', 'proprietors', '0', 'id'], newEkycCase)
    const caseId = get(['response', 'proprietors', '0', 'caseId'], newEkycCase)
    const policyId = get(['response', 'policies', '0', 'id'], newEkycCase)

    // update verificationRef to new verificationDetail
    yield call(updateVerificationRef, user, newProprietorId, {
      verificationRef: verificationRef,
    })

    yield put(setAppValue(`ekycInfo.${insuredOrPayer}.proprietorId`, newProprietorId, true))
    yield put(setAppValue(`ekycInfo.${insuredOrPayer}.caseId`, caseId))
    yield put(setAppValue(`ekycInfo.${insuredOrPayer}.policyId`, policyId))
    yield put(setAppValue(`ekycInfo.${insuredOrPayer}.resendLink`, true, true))

    // keep verification detail with new proprietorId
    const newVerifyDetail = yield call(verifyByProprietorId, user, newProprietorId)

    yield put(setAppValue(`ekycInfo.${insuredOrPayer}.verificationDetail`, { ...newVerifyDetail.response }, true))

    // save attachment
    const url = _.get(newVerifyDetail.response, 'frontIdCardResult.response.idCardImageUrl', null)
    if (url) {
      const payload = setAttachmentPayload(insuredOrPayer, app, url, 'id-card')
      const newAttachments = yield call(saveAttachmentFromURL, user, payload)
      yield put(setAppValue(`_attachments.${payload.attachmentId}`, newAttachments[payload.attachmentId]))

      action.payload.imageName = insuredOrPayer === 'insured' ? 'insured-id-card' : 'owner-id-card'
      yield call(onGetAttachment, service, action)
    }

    const { frontIdCardResult, backIdCardResult } = prepareEkycDetailResult(newVerifyDetail.response)
    yield put(setBackIdCardLaserCode(backIdCardResult.laser, insuredOrPayer))

    const verificationDetailEdit = _.get(newVerifyDetail.response, 'frontIdCardResult.edits')

    // check frontIdCardResult.edits is empty or not
    if (verificationDetailEdit) {
      // yield call(console.log, verificationDetailEdit, 'verificationDetailEdit') // I woild like to keep it for p>tee took look file.

      if (getToggles().ENABLE_EKYC_F2F_RECREATE_CASE) {
        yield put(setAppValue(`ekycInfo.${insuredOrPayer}.isDataConfirmed`, true, true))
      } else {
        yield put(setAppValue('ekycInfo.isDataConfirmed', true, true))
      }
      // Set {insured | payer}.idNo.xxxxx to be the same as frontIdCardResult.edits.xxxxx
      // DIGSALTOOL-1991 - Not replacing EKYC data firstname, lastname, citizenID.
      if (!frontIdCardResult.citizenId) return
      yield put(setAppValue(`${insuredOrPayer}.idNo.citizenId`, frontIdCardResult.citizenId.replaceAll('-', ''), true))
    }

    yield take('SAVE_APPLICATION_SUCCESS')
  } catch (error) {
    yield put(addError('EKYC_REUSE_CASE', error))
    return
  } finally {
    yield put(hideLoading())
  }
}

export const onSubmitRemoteSellingResumeModal = function*(service, action) {
  const app = yield select(getCurrentApp)
  const remoteSellingResumeState = get('remoteSelling.resumeState', app)
  const remoteSellingCaseId = getRemoteSellingCaseId(app)
  let { sectionName } = action.payload

  let caseId = null
  let nextProcess = 'ekyc'
  let resume = false

  if (remoteSellingCaseId) {
    caseId = remoteSellingCaseId
  }

  if (sectionName === 'videoConsent') {
    resume = true
    nextProcess = 'none'
  }

  if (remoteSellingResumeState === optionsResumeRMS[2].value) {
    yield put(remoteSellingCreateNewLink())
  } else if (remoteSellingResumeState === optionsResumeRMS[1].value) {
    yield put(closeRemoteSellingResumeModal())
    yield put(openSendingLinkModalRemoteSelling())
  } else if (remoteSellingResumeState === optionsResumeRMS[0].value) {
    yield put(
      openNative({
        caseId,
        nextProcess,
        push: true,
        resume,
      })
    )
  }
}

export const onEnterRemoteSellingMode = function*(service, history, action) {
  const remoteSellingFields = REMOTE_SELLING.fields
  const app = yield select(getCurrentApp)

  const isInCall = get('remoteSelling.callStatus.isInCall', app)
  if (isInCall) {
    const payerNeedsToDoEkyc = yield call(payerDoesEkyc, app)
    const insurerNeedsToDoEkyc = yield call(insurerDoesEkyc, app)
    if (!payerNeedsToDoEkyc && !insurerNeedsToDoEkyc) {
      yield call(history.push, `${ROUTE_PATH.ESUBMISSION_FORM}/document`)
      return
    }
    const insuredCaseId = getInsuredEkycCaseId(app)
    console.log(`insuredCaseId :`, insuredCaseId)
    yield put(
      openNative({
        caseId: insuredCaseId,
        nextProcess: 'ekyc',
        push: true,
        resume: true,
      })
    )
    return
  }

  const email = get(remoteSellingFields.email, app)
  const insuredEmail = get('insured.email', app)
  const phoneNumber = get(remoteSellingFields.phoneNumber, app)
  const sendingMethod = get(remoteSellingFields.sendingMethod, app)
  const insuredPhoneNumber = get('insured.mobileNumber', app)
  const isDataConfirmed = get(remoteSellingFields.isDataConfirmed, app)

  if (!sendingMethod) {
    yield put(setAppValue(remoteSellingFields.sendingMethod, 'sms'))
  }

  if (!email) {
    yield put(setAppValue(remoteSellingFields.email, insuredEmail))
  }

  if (!phoneNumber) {
    yield put(setAppValue(remoteSellingFields.phoneNumber, insuredPhoneNumber))
  }

  if (isDataConfirmed) {
    yield put(confirmUsingRemoteSelling())
  } else {
    yield put(setAppValue('remoteSelling.isOpenNewRemoteSellingPreviewInfoModal', true))
  }
}

export const onConfirmUsingRemoteSellingMode = function*(service) {
  const app = yield select(getCurrentApp)
  try {
    const remoteSellingState = getRemoteSellingState(app)

    if (!remoteSellingState) return null

    let isLinkModalOpen = true
    let isResendModalOpen = false

    if (remoteSellingState.roomId) {
      isLinkModalOpen = false
      isResendModalOpen = true
    }

    yield put(
      setAppValue(`remoteSelling`, {
        ...remoteSellingState,
        isDataConfirmed: true,
        isLinkModalOpen,
        isResendModalOpen,
        resumeState: 'continue',
        isOpenNewRemoteSellingPreviewInfoModal: false,
      })
    )
  } catch (e) {
    console.error(`onConfirmUsingRemoteSellingMode :`, e.message)
  } finally {
    yield put(hideLoading())
  }
}

export const onClearEkycResult = function*(service, action) {
  const remoteSellingFields = REMOTE_SELLING.initialValue
  yield put(setAppValue(`selectedRemoteSelling`, false))
  yield put(setAppValue(`remoteSelling`, remoteSellingFields))

  let { insuredOrPayor } = action.payload

  if (!insuredOrPayor) {
    yield put(
      setAppValue(`ekycInfo`, {
        insured: {
          resendLink: false,
          isDataConfirmed: false,
        },
        payer: {
          resendLink: false,
          isDataConfirmed: false,
        },
        sendLink: {
          textInput: '',
          method: '',
        },
        isOnDevice: {
          value: null,
        },
      })
    )
  } else {
    yield put(setAppValue(`ekycInfo.${insuredOrPayor}`, { isDataConfirmed: false }))
  }
}

export const onClearRemoteSellingResult = function*(service, action) {
  try {
    yield put(clearEkycResults())
  } catch (e) {
    console.error(`onClearRemoteSellingResult :`, e)
  }
}

export const onEnterEkycF2f = function*(service, action) {
  let { insuredOrPayor } = action.payload
  const remoteSellingFields = REMOTE_SELLING.initialValue
  yield put(setAppValue(`ekycInfo.${insuredOrPayor}`, { isDataConfirmed: true }))
  yield put(setAppValue(`remoteSelling`, remoteSellingFields))
}

export const onInitRemoteSelling = function*(service, action) {
  console.log('start onInitRemoteSelling')
  yield put(setAppValue(`selectedRemoteSelling`, true))
  yield put(setAppValue(`isRemoteSelling`, false))
  yield put(setAppValue(`remoteSelling`, {}))
  yield put(setAppValue(`ekycInfo`, {}))
}

export const onInitOldRemoteSelling = function*(service, action) {
  console.log('start onInitOldRemoteSelling')
  yield put(setAppValue(`selectedRemoteSelling`, false))
  yield put(setAppValue(`isRemoteSelling`, true))
  yield put(setAppValue(`remoteSelling`, {}))
  yield put(setAppValue(`ekycInfo`, {}))
}

export default function*(service, history) {
  const SAVE_ACTIONS = getToggles().ENABLE_OPTIMIZE_SAVING_REQUEST
    ? ['SAVE_APP_DATA']
    : ['SET_APP_VALUE', 'UNSET_APP_VALUE', 'REMOVE_APP_MEMBER']

  let replicationTask = null
  let checkSubmittingTask = null

  yield all([
    takeLatest(SAVE_ACTIONS, onDebounceSave, service),
    takeEvery('SET_APP_VALUE', onDebounceSave, service),
    takeLatest(['UNSET_APP_VALUE', 'REMOVE_APP_MEMBER'], onDebounceSave, service),
    takeLatest('ERROR_OTP_ATTACHMENTS', onErrorOtpAttachments, service, history),
    takeLatest('FETCH_ALL_APP', onList, service),
    takeLatest('FETCH_CURRENT_APP', onLoad, service),
    takeLatest('SEND_APPLICATION', onSend, service),
    takeLatest('SUBMIT_APPLICATION', onSubmit, service),
    takeEvery('CREATE_NEW_APP', onCreate, service),
    takeEvery('CHANGE_CURRENT_APP', onUpdate, service),
    takeEvery('HANDLE_CLICKING_APPLICATION_CARD', onApplicationCardClicked, service),
    takeEvery('CREATE_CASE_EKYC', onSendLinkEkyc, service),
    takeEvery('CREATE_CASE_EKYC_DEVICE', onCreateEkycByDevice, service),
    takeEvery('REUSE_CASE_EKYC', onReuseEkyc, service),
    takeEvery('VERIFY_EKYC', onVerifyEkyc, service),
    takeEvery('GET_ATTACHMENT', onGetAttachment, service),
    takeLatest('SUBSCRIBE_REPLICATION_STATUS', function*() {
      replicationTask = yield spawn(onSync, service)
      checkSubmittingTask = yield spawn(onCheckSubmitting, service)
    }),
    takeLatest('UNSUBSCRIBE_REPLICATION_STATUS', function*() {
      if (replicationTask) {
        yield cancel(replicationTask)
        replicationTask = null
      }
      if (checkSubmittingTask) {
        yield cancel(checkSubmittingTask)
        checkSubmittingTask = null
      }
    }),
    takeEvery(REMOTE_SELLING_CREATE_NEW_LINK, onCreateNewLinkRemoteSelling, service),
    takeEvery(REMOTE_SELLING_RESET_DATA, onResetRemoteSelling, service),
    takeEvery(REMOTE_SELLING_USING_EXISTS_LINK, onGetAttachment, service),
    takeEvery(REMOTE_SELLING_SUBMIT_RESUME_MODAL, onSubmitRemoteSellingResumeModal, service),
    takeEvery(ENTER_REMOTE_SELLING, onEnterRemoteSellingMode, service, history),
    takeEvery(CONFIRM_REMOTE_SELLING, onConfirmUsingRemoteSellingMode, service),
    takeEvery(CLEAR_REMOTE_SELLING_RESULT, onClearRemoteSellingResult, service),
    takeEvery(CLEAR_EKYC_RESULT, onClearEkycResult, service),
    takeEvery(ENTER_EKYC_F2F, onEnterEkycF2f, service),
    takeEvery('INIT_REMOTE_SELLING', onInitRemoteSelling, service),
    takeEvery('INIT_OLD_REMOTE_SELLING', onInitOldRemoteSelling, service),
  ])
}
