import { select, call, takeLatest } from 'redux-saga/effects'
import { getIdentityUser } from 'identity/selectors'

// EDA request logic
export const onVerifyImage = function*(service, action) {
  const { onVerifyOCR } = service
  const user = yield select(getIdentityUser)
  try {
    const result = yield call(onVerifyOCR, user, action.payload)
    return result
  } catch (error) {
    return null
  }
}

export default function*(service, history) {
  yield [takeLatest('VERIFY_OCR', onVerifyImage, service)]
}
