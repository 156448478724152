import { requestWrapper } from 'e-submission/domain/data'

export const saveAIUWRequest = (user, payload) => {
  return requestWrapper.postRequest(`esub/ai-uw/${payload.policyId}`, user, payload).then(({ data }) => data)
}

export const getAIUWStatus = (user, payload) => {
  return (
    requestWrapper
      //TODO: Need to remove mockStatus query string
      .getRequest(`esub/ai-uw/status?policyId=${payload.policyId}&mockStatus=STP`, user)
      .then(({ data }) => data)
  )
}
