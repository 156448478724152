import { defaultTo, find, first, flow, get, isEmpty, isNil, join, map, slice, sum, toFinite, toPath } from 'lodash/fp'
import Decimal from 'decimal.js'
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import MESSAGES from 'core/data-model/constants/messages'
import DEFAULTS from 'core/data-model/constants/defaults'
import VALUES from 'core/data-model/constants/values'
import { POLICY_DELIVERY_BY_EMAIL, ECBR_PAYER_TYPE, ECBR_PAYER_DOCTYPE } from 'e-submission/domain/data-model/constants'
import {
  setAppValue,
  setData,
  setDefaultAppValue,
  unsetAppValue,
  setUsedLuckyNumberModal,
  setAutoAssignFailedModal,
  showLoading,
  hideLoading,
} from 'e-submission/apps/actions'
import { updateOptyStagePayment } from 'quick-quote/opty/actions'
import {
  getSumPremiumPayment,
  getCurrentValue,
  getData,
  getDividendList,
  getCountryList,
  getTitle,
  getFirstName,
  getLastName,
  getPersonalDocumentTypeList,
  getPersonalDocumentTypeListEkyc,
  getPersonalNationalityList,
  getSelectedPersonalTitleList,
  havePayer,
  getDefaultInsuredIncomePerMonth,
  isJuvenileSelection,
  isForeignerSelection,
  getInsuredAge,
  getQQPayerRelation,
  getSfReference,
  getProductCategory,
  getCurrentApp,
} from 'e-submission/apps/selectors'
import { isEmail } from 'e-submission/utils/pattern'
import { isCordova } from 'e-submission/utils'

import { getIdentityUser, isBancAgent } from 'identity/selectors'

import AuthApi from 'core/service/distributor/auth-api'
import { getToggles } from 'quick-quote/feature-toggles'
import { isUnderage, isAgeLessThan } from 'core/service/insured/age.js'
import { getAIUWStatus } from 'e-submission/apps/actions'
import { getPaidReceipts } from 'e-submission/domain/data-model/form/selectors'

const getPrefix = flow(toPath, slice(0, -1), defaultTo([]))

export const unsetValue = (name) =>
  function*({ fieldId }) {
    const path = getPrefix(fieldId)
    yield put(unsetAppValue(path.concat(name)))
  }

export const setDefaultDataValue = (src, data, dependency, agrs) =>
  function*({ fieldId }) {
    const path = getPrefix(fieldId)
    const list = yield select(data, path.concat(toPath(dependency)), ...agrs)
    yield put(setAppValue(path.concat(toPath(src)), first(list)))
  }

export const copyData = (src, dest, defaultValue) =>
  function*({ fieldId }) {
    const path = getPrefix(fieldId)
    let value = yield select(getCurrentValue, path.concat(toPath(src)))
    if (!value && defaultValue) {
      value = defaultValue
    }
    yield put(setAppValue(path.concat(toPath(dest)), { value, text: value }))
  }

export const queryData = (serviceName, dataName) =>
  function*(service, { fieldId }) {
    const path = getPrefix(fieldId)

    const value = yield select(getCurrentValue, path)
    const data = yield call(service[serviceName], value)

    yield put(
      setData({
        [dataName]: data,
      })
    )
  }

export const onInitial = function*({
  getProvince,
  getAmphur,
  getTambol,
  getPersonalNationality,
  getPersonalTitle,
  getPersonalTitleEN,
  getPersonalDocumentType,
  getRelationship,
  getBank,
  getNetbank,
  getDividend,
  getCountry,
  getBeneficiary,
}) {
  const personalTitle = yield call(getPersonalTitle)
  const personalTitleEN = yield call(getPersonalTitleEN)
  const personalDocumentType = yield call(getPersonalDocumentType)
  const personalNationality = yield call(getPersonalNationality)
  const province = yield call(getProvince)
  const amphur = yield call(getAmphur)
  const tambol = yield call(getTambol)
  const relationship = yield call(getRelationship)
  const bank = yield call(getBank)
  const netbank = yield call(getNetbank)
  const dividend = yield call(getDividend)
  const country = yield call(getCountry)
  const beneficiary = yield call(getBeneficiary)
  yield put(
    setData({
      personalTitle,
      personalTitleEN,
      personalDocumentType,
      personalNationality,
      province,
      amphur,
      tambol,
      relationship,
      bank,
      netbank,
      dividend,
      country,
      beneficiary,
    })
  )

  yield put({ type: 'INITIAL_E_SUBMISSION_SUCCESS' })
}

export const onCreate = function*(action) {
  yield take('LOAD_APP')

  const data = yield select(getData)

  if (isEmpty(data)) {
    yield take('SET_DATA')
  }

  const personalNationalityList = yield select(getPersonalNationalityList)
  const personalDocumentTypeList = yield select(getPersonalDocumentTypeList)
  const insuredDocumentTypeListEkyc = yield select(
    getPersonalDocumentTypeListEkyc,
    ['insured', 'nationality', 'value'],
    ['quickQuote', 'insured', 'age'],
    'insured'
  )
  const dividendList = yield select(getDividendList)
  const countryList = yield select(getCountryList)
  const insuredTitleList = yield select(getSelectedPersonalTitleList, 'insured')
  const payerTitleList = yield select(getSelectedPersonalTitleList, 'payer')

  const title = yield select(getTitle)
  const firstName = yield select(getFirstName)
  const lastName = yield select(getLastName)

  const isNotPayer = yield select(havePayer)
  const { FIRST_NAME_DEFAULT, LAST_NAME_DEFAULT } = MESSAGES
  const isDefaultName = firstName === FIRST_NAME_DEFAULT && lastName === LAST_NAME_DEFAULT
  const shouldSkipSetName = getToggles().ENABLE_NOT_TRANSFER_DEFAULT_NAME_TO_ESUB && isDefaultName

  if (!shouldSkipSetName) {
    yield put(setDefaultAppValue('insured.firstName', firstName))
    yield put(setDefaultAppValue('insured.lastName', lastName))
  }
  if (getToggles().ENABLE_QQ_INSURED_TITLE) {
    yield put(setDefaultAppValue('insured.title', title))
  } else {
    yield put(setDefaultAppValue('insured.title', first(insuredTitleList)))
  }
  yield put(setDefaultAppValue('insured.nationality', first(personalNationalityList)))
  if (getToggles().ENABLE_PLACEOFBIRTH_SECTION) {
    yield put(setDefaultAppValue('insured.placeOfBirthCountry', first(countryList)))
  }
  yield put(
    setDefaultAppValue(
      'insured.idType',
      getToggles().ENABLE_EKYC_F2F || getToggles().ENABLE_APPMAN_REMOTE_SELLING
        ? first(insuredDocumentTypeListEkyc)
        : first(personalDocumentTypeList)
    )
  )
  if (getToggles().ENABLE_APPMAN_REMOTE_SELLING) {
    yield put(setDefaultAppValue('insured.title', first(insuredTitleList)))
  }
  yield put(setDefaultAppValue('insured.registeredAddress.notUseCurrentAddress', false))
  yield put(setDefaultAppValue('insured.dividend', first(dividendList)))
  yield put(setDefaultAppValue('insured.isWeightChangedRecently', false))

  if (getToggles().TOGGLE_ENABLE_AMT_INCOME_WITH_OCCUPATION_CLASS) {
    const insureIncomePerMonth = yield select(getDefaultInsuredIncomePerMonth)
    yield put(setDefaultAppValue('insured.mainOccupationIncomePerMonth', insureIncomePerMonth))
  }

  yield put(setDefaultAppValue('insured.additionalDeclaration.checked.value', 'no'))

  if (isNotPayer) {
    yield put(setDefaultAppValue('payer.title', first(payerTitleList)))
    yield put(setDefaultAppValue('payer.nationality', first(personalNationalityList)))
    yield put(setDefaultAppValue('payer.idType', first(personalDocumentTypeList)))
    yield put(setDefaultAppValue('payer.payerAddress.useCurrentInsuredAddress', false))
    yield put(setDefaultAppValue('payer.isWeightChangedRecently', false))
  } else {
    yield put(unsetAppValue('payer'))
  }

  const age = yield select(getInsuredAge)
  if (isAgeLessThan(age, { value: 18, unit: 'year' })) {
    yield put(setDefaultAppValue('insured.noOfDependence.text', '0'))
    yield put(setDefaultAppValue('insured.noOfDependence.value', '0'))
  }

  if (getToggles().DEFAULT_EDUCATION_FOR_JUVENILE) {
    if (isUnderage(age)) {
      yield put(setDefaultAppValue('insured.education.value', 'belowBachelor'))
      yield put(setDefaultAppValue('insured.education.text', 'ต่ำกว่าปริญญาตรี'))
    }
  }

  if (getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM) {
    yield put(setDefaultAppValue('requiredCitizenIdConsent', 'belowBachelor'))
  }
  yield put(setDefaultAppValue('ekycInfo.insured.resendLink', false))
  yield put(setDefaultAppValue('ekycInfo.payer.resendLink', false))

  const sfReference = yield select(getSfReference)
  yield put(setAppValue('sfReference', sfReference))

  if (getToggles().ENABLE_NEW_PAYER_SELECTION) {
    const qqPayerRelation = yield select(getQQPayerRelation)
    if (qqPayerRelation === DEFAULTS.SPOUSE)
      yield put(setDefaultAppValue('payer.relationshipToInsured', { text: 'คู่สมรส', value: 'SPOUSE' }))
  }
  const productCategory = yield select(getProductCategory)
  if (productCategory === VALUES.PA) {
    yield put(setDefaultAppValue('insured.isPolicyDeliveryByEmail', POLICY_DELIVERY_BY_EMAIL.HAVE))
  }
  yield put(setAppValue('insured.isPayer', !isNotPayer))

  yield call(setAgentInfo, action)
  yield delay(1500)

  yield call(setUserInfo)
}

export const setUserInfo = function*() {
  try {
    const user = yield select(getIdentityUser)
    const userInfo = yield call(AuthApi.getUserInformation, user)
    const firstName = get('party.firstName', userInfo)
    const lastName = get('party.lastName', userInfo)
    yield put(
      setAppValue('quickQuote.user', {
        firstName,
        lastName,
      })
    )
    yield take('SAVE_APPLICATION_SUCCESS')
  } catch (error) {}
}

export const setAgentInfo = function*(action) {
  const isBanc = yield select(isBancAgent)
  if (!isBanc) {
    const user = yield select(getIdentityUser)
    try {
      const userInfo = yield call(AuthApi.getUserInformation, user)
      const partyCode = flow(get('advisorCode'), slice(0, 6), join(''))(userInfo)
      const fullName = get('party.fullName', userInfo)
      const firstName = get('party.firstName', userInfo)
      const lastName = get('party.lastName', userInfo)
      yield put(
        setAppValue('quickQuote.agent', {
          ...userInfo,
          firstName,
          lastName,
          fullName,
          partyCode,
        })
      )
      yield take('SAVE_APPLICATION_SUCCESS')
    } catch (error) {}
  }
}

export const onChangeApp = function*(action) {
  if (!isCordova) {
    yield take('LOAD_APP')
  }

  const insuredTitle = yield select(getCurrentValue, 'insured.title')
  const insuredValue = yield select(getCurrentValue, 'insured.title.value')
  const insuredTitleList = yield select(getSelectedPersonalTitleList, 'insured')
  const isPatchInsuredTitleNeeded = insuredValue !== 'other' && flow(find(insuredTitle), isNil)(insuredTitleList)

  if (isPatchInsuredTitleNeeded) {
    yield put(setAppValue('insured.title', first(insuredTitleList)))
  }

  yield call(setAgentInfo, action)
}

export const setInsuredIncomePerYear = function*({ fieldId }) {
  const insuredOrPayer = getPrefix(fieldId)[0]

  const mainOccupationIncomePerMonth = yield select(getCurrentValue, `${insuredOrPayer}.mainOccupationIncomePerMonth`)
  const otherOccupationIncomePerMonth = yield select(getCurrentValue, `${insuredOrPayer}.otherOccupationIncomePerMonth`)

  const incomePerYear = flow(map(toFinite), sum, (value) => new Decimal(12).mul(value).toFixed())([
    mainOccupationIncomePerMonth,
    otherOccupationIncomePerMonth,
  ])

  yield put(setAppValue(`${insuredOrPayer}.incomePerYear`, incomePerYear))
}

export const setDefaultsCRSFirstCountry = function*({ fieldId }) {
  const hasCRS = yield select(getCurrentValue, fieldId)
  yield put(unsetAppValue('insured.CRS.detail'))
  yield put(unsetAppValue('insured.CRS.mainDetail'))
  if (hasCRS.value == 'yes') {
    const countryList = yield select(getCountryList)
    yield put(setDefaultAppValue('insured.CRS.detail[0].tax.country', countryList[1]))
  }
}

export const setDefaultsPAFirstTreatment = function*({ fieldId }) {
  const hasChecked = yield select(getCurrentValue, fieldId)
  if (hasChecked.value == 'yes') {
    const field = fieldId.replace(/\.checked$/, '')
    yield put(setDefaultAppValue(`${field}.detail[0]`, null))
  }
}

export const setFirstPayment = function*({ fieldId }) {
  const sumPremiumPayment = yield select(getSumPremiumPayment)
  const getReceiptKey = (fieldId) => {
    const key = fieldId.match(/^receipts\[[0-9]+\]/)
    return key ? key[0] : 'receipts[0]'
  }

  let key = getReceiptKey(fieldId)
  const values = [0, 0, 0, 0].map((value, index) => {
    if (key === `receipts[${index}]`) return sumPremiumPayment
    return 0
  })

  yield put(setAppValue('receipts[0].totalFirstPremiumPayment', values[0]))
  yield put(setAppValue('receipts[1].totalFirstPremiumPayment', values[1]))
  yield put(setAppValue('receipts[2].totalFirstPremiumPayment', values[2]))
  yield put(setAppValue('receipts[3].totalFirstPremiumPayment', values[3]))
  yield put(setAppValue('creditCardRelationship', ''))
  yield put(setAppValue('creditCardMethod', ''))
  if (getToggles().ENABLE_ECBR_SYSTEMIZATION) {
    yield put(unsetAppValue('payerInfo'))
  }
}

export const unsetIfEmptyString = function*({ fieldId, value }) {
  if (value === '') {
    yield put(unsetAppValue(fieldId))
  }
}

export const setJuvenileField = function*() {
  const payerRelationshipValue = yield select(getCurrentValue, 'payer.relationshipToInsured.value')
  const payerRelationshipFlag = payerRelationshipValue === 'PARENT'
  const haveJuvenileForm = yield select(isJuvenileSelection)
  const haveForeignerForm = yield select(isForeignerSelection)

  if (getToggles().ENABLE_JUVENILE_PURCHASING_FORM && payerRelationshipFlag && haveJuvenileForm) {
    const payerGender = yield select(getCurrentValue, 'payer.title.gender')
    const defaultTitle = yield select(getCurrentValue, 'payer.title.value')
    const defaultTitleText = yield select(getCurrentValue, 'payer.title.text')
    const defaultLastName = yield select(getCurrentValue, 'payer.lastName')
    const defaultFirstName = yield select(getCurrentValue, 'payer.firstName')
    const defaultIdNo = yield select(getCurrentValue, 'payer.idNo.citizenId')
    const defaultIdType = yield select(getCurrentValue, 'payer.idType.value')
    const defaultIdTypeText = yield select(getCurrentValue, 'payer.idType.text')

    const fatherOrMother = payerGender === 'M' ? 'father' : 'mother'
    const juvenileDefaultId = `insured.juvenilePurchasing.${fatherOrMother}`

    yield put(setAppValue(`${juvenileDefaultId}.title.value`, defaultTitle))
    yield put(setAppValue(`${juvenileDefaultId}.title.text`, defaultTitleText))
    yield put(setAppValue(`${juvenileDefaultId}.title.gender`, payerGender))
    yield put(setAppValue(`${juvenileDefaultId}.firstName`, defaultFirstName))
    yield put(setAppValue(`${juvenileDefaultId}.lastName`, defaultLastName))
    yield put(setAppValue(`${juvenileDefaultId}.idType.value`, defaultIdType))
    yield put(setAppValue(`${juvenileDefaultId}.idType.text`, defaultIdTypeText))
    yield put(setAppValue(`${juvenileDefaultId}.idNo.citizenId`, defaultIdNo))
  }
  if (getToggles().ENABLE_FOREIGNER_FORM && haveForeignerForm) {
    const insuredPassportValue = yield select(getCurrentValue, 'insured.idNo.passport')
    yield put(setAppValue('insured.foreigner.passport', insuredPassportValue))
  }
}

export const setPassportForeignerQuestion = function*() {
  const haveForeignerForm = yield select(isForeignerSelection)
  if (getToggles().ENABLE_FOREIGNER_FORM && haveForeignerForm) {
    const insuredPassportValue = yield select(getCurrentValue, 'insured.idNo.passport')
    yield put(setAppValue('insured.foreigner.passport', insuredPassportValue))
  }
}

export const setDefaultIsPolicyDeliveryByEmail = function*() {
  try {
    const isPolicyDeliveryByEmail = yield select(getCurrentValue, 'insured.isPolicyDeliveryByEmail')
    const email = yield select(getCurrentValue, 'insured.email')
    if (!isPolicyDeliveryByEmail && isEmail(email)) {
      yield delay(1500)
      yield put(setAppValue('insured.isPolicyDeliveryByEmail', POLICY_DELIVERY_BY_EMAIL.HAVE))
    }
  } catch (e) {}
}

export const setAllocationBeneficiaryMRTA = function*() {
  const beneficiary = yield select(getCurrentValue, 'beneficiary')
  yield put(setAppValue('beneficiary[0].allocation', 101 - beneficiary.length))
}

export const setIdType = function*({ fieldId }) {
  const insuredOrPayer = getPrefix(fieldId)[0]

  if (getToggles().ENABLE_EKYC_F2F) {
    let args = [['quickQuote', 'insured', 'age'], 'insured']
    if (fieldId.includes('payer')) {
      args = [['quickQuote', 'payer', 'age'], 'payer']
    }
    yield call(setDefaultDataValue('idType', getPersonalDocumentTypeListEkyc, 'nationality.value', args), { fieldId })
  } else {
    yield call(setDefaultDataValue('idType', getPersonalDocumentTypeList, 'nationality.value'), { fieldId })
  }
  yield put(setAppValue(`${insuredOrPayer}.idNo.citizenId`, ''))
  yield put(setAppValue(`${insuredOrPayer}.idNo.passport`, ''))
}

export const onUpdatePaymentPaylater = function*({ fieldId, value }) {
  if (value) {
    yield put(updateOptyStagePayment())
    yield put(getAIUWStatus())
  }
}

export const setDefaultPayerIdType = function*() {
  const payerType = yield select(getCurrentValue, 'payerInfo.payerType')

  if (payerType === ECBR_PAYER_TYPE.OTHER) {
    yield put(setAppValue('payerInfo.idType', ECBR_PAYER_DOCTYPE[0]))
  }
}

export const clearPayerIdNo = function*() {
  const payerIdType = yield select(getCurrentValue, 'payerInfo.idType')
  if (payerIdType?.value === ECBR_PAYER_DOCTYPE[0].value) {
    yield put(unsetAppValue('payerInfo.idNo.passport'))
  }
  if (payerIdType?.value === ECBR_PAYER_DOCTYPE[1].value) {
    yield put(unsetAppValue('payerInfo.idNo.citizenId'))
  }
}

export const setDefaultPayerType = function*() {
  const payerInfo = yield select(getCurrentValue, 'payerInfo')
  if (getToggles().ENABLE_ECBR_SYSTEMIZATION && isEmpty(payerInfo)) {
    const isPayer = yield select(getCurrentValue, 'insured.isPayer')
    const defaultPayerType = isPayer ? ECBR_PAYER_TYPE.INSURED : ECBR_PAYER_TYPE.PAYER
    yield put(setAppValue('payerInfo.payerType', defaultPayerType))
  }
}

export const searchLuckyNumberOptions = function*(service) {
  try {
    const { searchLuckyNumberOptions } = service
    const user = yield select(getIdentityUser)
    const luckyNumberDigits = (yield select(getCurrentValue, 'luckyNumber.digits')) ?? Array(7).fill('')
    const pattern = '5??-' + luckyNumberDigits.map((val) => (val === '' ? '?' : val)).join('')
    const productCode = yield select(getCurrentValue, 'quickQuote.selectedDisplayProduct.code')

    const payload = {
      pattern,
      productCode,
    }

    const luckyNumberOptions = yield call(searchLuckyNumberOptions, user, payload)
    const result = luckyNumberOptions?.retrievePolicyNumberResponse?.policyNO

    if (result) {
      yield put(setAppValue('luckyNumber.options', result))
      yield put(setAppValue('luckyNumber.selected', undefined))
      yield put(setAppValue('luckyNumber.status', undefined))
    }
  } catch {}
}

export const submitSelectedLuckyNumber = function*(service) {
  try {
    const { submitSelectedLuckyNumber } = service
    const user = yield select(getIdentityUser)

    const selectedLuckyNumber = yield select(getCurrentValue, 'luckyNumber.selected')

    if (selectedLuckyNumber) {
      const payload = {
        policyId: selectedLuckyNumber.id,
        policyNO: selectedLuckyNumber.policyNumber,
        status: '2', // 1 = to use, 2 = to reserve
      }

      const luckyNumberRequest = yield call(submitSelectedLuckyNumber, user, payload)

      if (luckyNumberRequest) {
        if (luckyNumberRequest.message === `Update policy ${payload.policyNO} Success`) {
          yield put(setAppValue('luckyNumber.status', 'approved'))
        } else if (
          luckyNumberRequest.message === `${payload.policyNO} has already reserved` ||
          luckyNumberRequest.message === `${payload.policyNO} has already used`
        ) {
          yield put(setUsedLuckyNumberModal(true))
        }
      }
    }
  } catch {}
}

export const disableUsedLuckyNumber = function*() {
  try {
    const selectedLuckyNumber = yield select(getCurrentValue, 'luckyNumber.selected')

    if (selectedLuckyNumber) {
      const luckyNumberOptions = yield select(getCurrentValue, 'luckyNumber.options')
      const updatedOptions = luckyNumberOptions.map((item) => {
        if (item.id === selectedLuckyNumber?.id) {
          return { ...item, disabled: true }
        }
        return item
      })

      yield put(setAppValue('luckyNumber.selected', undefined))
      yield put(setAppValue('luckyNumber.options', updatedOptions))
    }
  } catch {}
}

export const autoAssignLuckyNumber = function*(service, history, action) {
  try {
    yield put(setAppValue('luckyNumber.autoAssign', action.payload))
    if (action.payload) {
      yield put.resolve(showLoading())
      const { searchLuckyNumberOptions, submitSelectedLuckyNumber, getApplication } = service
      const app = yield select(getCurrentApp)
      const user = yield select(getIdentityUser)
      const pattern = '5??-???????'
      const productCode = yield select(getCurrentValue, 'quickQuote.selectedDisplayProduct.code')

      let selectedLuckyNumber

      while (!selectedLuckyNumber) {
        const luckyNumberOptions = yield call(searchLuckyNumberOptions, user, {
          pattern,
          productCode,
          requestedPolicyNO: 1,
        })
        const result = luckyNumberOptions?.retrievePolicyNumberResponse?.policyNO
        yield put(setAppValue('luckyNumber.options', result))

        if (result && result.length) {
          selectedLuckyNumber = result[0]
          if (selectedLuckyNumber) {
            const payload = {
              policyId: selectedLuckyNumber.id,
              policyNO: selectedLuckyNumber.policyNumber,
              status: '2', // 1 = to use, 2 = to reserve
            }

            const luckyNumberRequest = yield call(submitSelectedLuckyNumber, user, payload)

            if (luckyNumberRequest?.message === `Update policy ${payload.policyNO} Success`) {
              let delayTime = 500
              let isLuckyNumberValid = false

              while (delayTime < 3000) {
                yield put(setAppValue('luckyNumber.selected', selectedLuckyNumber))
                yield put(setAppValue('luckyNumber.status', 'approved'))
                yield delay(delayTime)

                const application = yield call(getApplication, app, user)
                const luckyNumberFromApplication = get('luckyNumber', application)

                const selected = yield select(getCurrentValue, 'luckyNumber.selected')
                const status = yield select(getCurrentValue, 'luckyNumber.status')
                const selectedFromApplication = get('selected', luckyNumberFromApplication)
                const statusFromApplication = get('status', luckyNumberFromApplication)

                if (selected && status && selectedFromApplication && statusFromApplication) {
                  isLuckyNumberValid = true
                  break
                }

                delayTime += 500
              }

              if (isLuckyNumberValid) {
                history.replace(history.location.pathname.replace('/lucky-number', '/signature'))
              } else {
                selectedLuckyNumber = null
                yield put(setAutoAssignFailedModal(true))
              }
              break
            } else if (
              luckyNumberRequest.message === `${payload.policyNO} has already reserved` ||
              luckyNumberRequest.message === `${payload.policyNO} has already used`
            ) {
              selectedLuckyNumber = null
              yield delay(500)
            } else {
              throw new Error()
            }
          } else {
            break
          }
        } else {
          break
        }
      }

      if (!selectedLuckyNumber) {
        yield put.resolve(hideLoading())
      }
    }
  } catch (err) {
    console.log(err)
    yield put.resolve(hideLoading())
  }
}

export default function*(service, history) {
  const field = (regx) => (action) => {
    if (action && action.type !== 'SET_DEFAULT_APP_VALUE' && action.fieldId) {
      return new RegExp(regx).test(action.fieldId)
    }
    return false
  }

  yield [
    takeEvery('INITIAL_E_SUBMISSION_REQUEST', onInitial, service),
    takeLatest('CREATE_NEW_APP', onCreate),
    takeEvery('CHANGE_CURRENT_APP', onChangeApp),
    takeEvery(field('province$'), unsetValue('amphur')),
    takeEvery(field('amphur$'), unsetValue('tambol')),
    takeEvery(field('tambol$'), copyData('tambol.postal', 'dataPostCode')),
    takeEvery(field('tambol$'), copyData('tambol.postal[0]', 'postCode', '')),
    takeEvery(field('nationality$'), setIdType),
    takeEvery(field('OccupationIncomePerMonth$'), setInsuredIncomePerYear),
    takeEvery(field('CRS.checked$'), setDefaultsCRSFirstCountry),
    takeEvery(
      field('(haveDiagnosis|fiveYearsHealthCheck|useDrug|isDisabledPerson).checked$'),
      setDefaultsPAFirstTreatment
    ),
    takeEvery(field('^KYC.*.otherDetail$'), unsetIfEmptyString),
    takeEvery(field('^payer.*'), setJuvenileField),
    takeEvery(field('^insured.idNo.*'), setPassportForeignerQuestion),
    takeEvery('LOAD_APP', setJuvenileField),
    takeEvery(field('^receipts[[0-9]+].type'), setFirstPayment),
    takeLatest(field('insured.email$'), setDefaultIsPolicyDeliveryByEmail),
    takeLatest('SET_ALLOCATION_BENEFICIARY_MRTA', setAllocationBeneficiaryMRTA),
    takeEvery(field('submitWithoutPayment.reason'), onUpdatePaymentPaylater),
    takeEvery(field('payerInfo.payerType$'), setDefaultPayerIdType),
    takeEvery(field('payerInfo.idType$'), clearPayerIdNo),
    takeLatest(field('payerInfo'), setDefaultPayerType),
    takeLatest('SEARCH_LUCKY_NUMBER_OPTIONS', searchLuckyNumberOptions, service),
    takeLatest('SUBMIT_SELECTED_LUCKY_NUMBER', submitSelectedLuckyNumber, service),
    takeLatest('SET_USED_LUCKY_NUMBER_MODAL', disableUsedLuckyNumber),
    takeLatest('SET_AUTO_ASSIGN_LUCKY_NUMBER', autoAssignLuckyNumber, service, history),
  ]
}
