import _ from 'lodash'
import { takeLatest, select, put, call, take } from 'redux-saga/effects'
import {
  getFormData,
  getPolicyId,
  isATPVerifiedAcc,
  isDCAVerifiedAcc,
  hasATP,
  isShowDCAForm,
  getInsuredDCAProps,
  isBumnanReadyProductCategory,
  isCashDividend,
} from 'e-submission/domain/data-model/form/selectors'
import {
  setAppValue,
  updateDCA,
  updateRecurringPayment,
  resetEDAViewFormOnly,
  clonePhoto,
  getAttachment,
} from 'e-submission/apps/actions'
import { uploadAttachment } from './document'
import VALUES from 'core/data-model/constants/values'

import { getIdentityUser } from 'identity/selectors'
import { confirmDialogCheckACC } from './confirm'
import { checkAccountStatus } from 'e-submission/sagas/utils'
import { getCurrentApp } from 'e-submission/apps/selectors'
import { checkNameOFInsuredPayer } from './utils'

const checkAccFullname = (isDcaForm, app) => {
  let fullname = _.get(app, 'ATP.title.text') + ` ` + _.get(app, 'ATP.firstName') + ` ` + _.get(app, 'ATP.lastName')
  if (isDcaForm) {
    fullname = _.get(app, 'DCA.title.text') + ` ` + _.get(app, 'DCA.firstName') + ` ` + _.get(app, 'DCA.lastName')
  }
  return fullname
}

export const onCheckAccount = function*(service, action) {
  const { checkAccount } = service
  const formType = action.payload.formType
  const app = yield select(getCurrentApp)
  const user = yield select(getIdentityUser)
  const formData = getFormData(formType, app)
  const policyId = getPolicyId(app)
  const isDcaForm = formType === 'DCA'
  const isKTBBank = formData?.bank.value === '006'
  const isATPVerified = isATPVerifiedAcc(app)
  const isDCAVerified = isDCAVerifiedAcc(app)
  const isHasATP = hasATP(app)
  const isHasDCA = isBumnanReadyProductCategory(app) || isCashDividend(app)

  const accountFullname = checkAccFullname(isDcaForm, app)

  // Remove accountName after business condition changed
  const accountNo = isDcaForm ? formData.accountNumber : formData.bankBookNumber

  const payload = {
    policyId,
    bankCode: formData?.bank?.value,
    accountNo,
  }

  try {
    yield call(cleanDataIsAccountChecked, formType)
    const response = yield call(checkAccount, user, payload)

    const checkInsuredName = checkNameOFInsuredPayer(app, isHasDCA)

    let data = {
      name: response?.accountName,
      type: formType,
      app: app,
      state: action.payload.state,
      service: service,
    }

    let accDetail = {
      bankName: formData?.bank?.text,
      accountName: response?.accountName,
      accountNo,
    }

    let isShowCancelBTN = false
    let dataPropDialog = {
      accDetail,
      onlyConfirm: !isShowCancelBTN,
      confirmText: 'ยืนยัน',
      confirmYesWithAction: 'SAVE_DATA_CHECK_ACCOUNT',
    }
    let currentStateDialog = ''

    if (response?.checkAccStatus === 'SUCCESS') {
      data.status = 'Verified'
      if (isHasDCA && isHasATP) {
        if (isDcaForm == true && isATPVerified == false && isKTBBank == true) {
          currentStateDialog = 'SUCCESS_DCA'
          dataPropDialog = {
            ...dataPropDialog,
            onlyConfirm: false,
            cancelText: 'ไม่ต้องการ',
            confirmNoWithAction: 'SAVE_DATA_CHECK_ACCOUNT',
            confirmNoWithData: {
              ...data,
              type: 'DCA',
            },
            confirmYesWithData: {
              ...data,
              type: 'DCA-ATP',
            },
          }
        } else if (isDcaForm == false && isDCAVerified == false && checkInsuredName == true) {
          currentStateDialog = 'SUCCESS_ATP'
          dataPropDialog = {
            ...dataPropDialog,
            onlyConfirm: false,
            cancelText: 'ไม่ต้องการ',
            confirmNoWithAction: 'SAVE_DATA_CHECK_ACCOUNT',
            confirmNoWithData: {
              ...data,
              type: 'ATP',
            },
            confirmYesWithData: {
              ...data,
              type: 'ATP-DCA',
            },
          }
        } else {
          data.type = isDcaForm ? 'DCA' : 'ATP'
          currentStateDialog = isDcaForm ? 'SUCCESS_ONLY_DCA' : 'SUCCESS_ONLY_ATP'
          dataPropDialog = {
            ...dataPropDialog,
            onlyConfirm: false,
            cancelText: 'ยกเลิก',
            confirmYesWithData: data,
          }
        }
      } else {
        data.type = isDcaForm ? 'DCA' : 'ATP'
        currentStateDialog = isDcaForm ? 'SUCCESS_ONLY_DCA' : 'SUCCESS_ONLY_ATP'
        dataPropDialog = {
          ...dataPropDialog,
          onlyConfirm: false,
          cancelText: 'ยกเลิก',
          confirmYesWithData: data,
        }
      }
    } else if (response?.checkAccStatus === 'INVALID') {
      currentStateDialog = 'INVALID'
      delete dataPropDialog.confirmYesWithAction
      dataPropDialog = {
        ...dataPropDialog,
        onlyConfirm: true,
        accDetail: null,
      }
    } else {
      currentStateDialog = 'ERROR'
      delete data.state
      dataPropDialog = {
        ...dataPropDialog,
        accDetail: null,
        onlyConfirm: true,
        confirmYesWithData: {
          ...data,
          name: accountFullname,
          status: 'Not verified',
          type: isDcaForm ? 'DCA-APIDOWN' : 'ATP-APIDOWN',
        },
      }
    }

    yield call(confirmDialogCheckACC, checkAccountStatus[currentStateDialog], dataPropDialog)

    // if (response?.checkAccStatus === 'SUCCESS') {
    //   data.status = 'Verified'
    //   if (isDcaForm) {
    //     if (!isKTBBank || !isHasATP) {
    //       yield call(saveDataCheckAccount, data)
    //       // Update Action
    //     } else {
    //       if (isATPVerified) {
    //         const confirmed = yield call(confirmDialogCheckACC, checkAccountStatus['SUCCESS_ONLY_DCA'], {
    //           accDetail,
    //           onlyConfirm: true,
    //           confirmText: 'ยืนยัน',
    //           confirmYesWithAction: 'SAVE_DATA_CHECK_ACCOUNT',
    //           confirmYesWithData: data,
    //         })
    //         // if (confirmed) {
    //         //   yield call(saveDataCheckAccount, data)
    //         //   // Update Action
    //         // }
    //       } else {
    //         data.type = 'DCA-DA'
    //         const confirmed = yield call(confirmDialogCheckACC, checkAccountStatus['SUCCESS_DCA'], {
    //           accDetail,
    //           onlyConfirm: true,
    //           confirmText: 'ยืนยัน',
    //           confirmYesWithAction: 'SAVE_DATA_CHECK_ACCOUNT',
    //           confirmYesWithData: data,
    //         })
    //         if (confirmed) {
    //           // yield call(saveDataCheckAccount, data)
    //           // Update Action
    //         }
    //       }
    //     }
    //   } else {
    //     data.status = 'Verified'
    //     if (!checkInsuredName) {
    //       yield call(saveDataCheckAccount, data)
    //       // Update Action
    //     } else {
    //       if (isDCAVerified) {
    //         const confirmed = yield call(confirmDialogCheckACC, checkAccountStatus['SUCCESS_ONLY_ATP'], {
    //           accDetail,
    //           onlyConfirm: true,
    //           confirmText: 'ยืนยัน',
    //           confirmYesWithAction: 'SAVE_DATA_CHECK_ACCOUNT',
    //           confirmYesWithData: data,
    //         })
    //         if (confirmed) {
    //           yield call(saveDataCheckAccount, data)
    //           // Update Action
    //         }
    //       } else {
    //         const confirmed = yield call(confirmDialogCheckACC, checkAccountStatus['SUCCESS_ATP'], {
    //           accDetail,
    //           onlyConfirm: true,
    //           confirmText: 'ยืนยัน',
    //           confirmYesWithAction: 'SAVE_DATA_CHECK_ACCOUNT',
    //           confirmYesWithData: data,
    //         })
    //         if (confirmed) {
    //           data.type = 'DCA-DA'
    //           if (isHadDCA) {
    //             yield call(onSetInformationDCA, app)
    //           }
    //           yield call(saveDataCheckAccount, data)
    //           // Update Action
    //         }
    //       }
    //     }
    //   }
    // } else if (response?.checkAccStatus === 'INVALID') {
    //   yield call(confirmDialogCheckACC, checkAccountStatus['INVALID'], {
    //     onlyConfirm: true,
    //     confirmText: 'ยืนยัน',
    //     confirmYesWithAction: 'SAVE_DATA_CHECK_ACCOUNT',
    //   })
    // } else if (response?.checkAccStatus === 'ERROR') {
    //   data.status = 'Not verified'
    //   data.name = accountFullname
    //   accDetail.accountName = accountFullname

    //   const confirmed = yield call(confirmDialogCheckACC, checkAccountStatus['ERROR'], {
    //     accDetail: accDetail,
    //     onlyConfirm: true,
    //     confirmText: 'ยืนยัน',
    //     confirmYesWithAction: 'SAVE_DATA_CHECK_ACCOUNT',
    //   })

    //   if (confirmed) {
    //     if (data.type === 'DCA') {
    //       yield put(setAppValue('DCA.isEnableAccountName', true))
    //       yield put(setAppValue('DCA.accountName', accountFullname))
    //     } else if (data.type === 'ATP') {
    //       yield put(setAppValue('ATP.isEnableBankBookName', true))
    //       yield put(setAppValue('ATP.bankBookName', accountFullname))
    //     } else if (data.type === 'DCA-DA') {
    //       yield put(setAppValue('DCA.accountName', accountFullname))
    //       yield put(setAppValue('ATP.bankBookName', accountFullname))
    //       yield put(setAppValue('ATP.isEnableBankBookName', true))
    //       yield put(setAppValue('DCA.isEnableAccountName', true))
    //     }
    //     // Update Action
    //     yield take('SAVE_APPLICATION_SUCCESS')
    //   }
    // } else {
    //   data.status = 'Not verified'
    //   yield call(saveDataCheckAccount, data)
    // }
  } catch (err) {
    console.error(err)
  }
}

export const saveDataCheckAccount = function*(service, action) {
  console.log('xxxxxx saveDataCheckAccountSuccess xxxxx')
  let data = action.payload
  console.log(data)
  let bankNameForm = ''
  let bankNumberForm = ''
  let bankCode = ''
  let verifyStatus = ''
  try {
    const app = data.app
    const payload = data
    if ('name' in payload) {
      bankNameForm = payload.name
    } else {
      bankNameForm = data.type.startsWith('DCA') ? app.DCA.accountName : app.ATP.bankBookNumber
    }
    if ('status' in payload) {
      verifyStatus = payload.status
    }

    bankNumberForm = data.type.startsWith('DCA') ? app.DCA.accountNumber : app.ATP.bankBookNumber
    bankCode = data.type.startsWith('DCA') ? app.DCA.bank.value : app.ATP.bank.value

    const dataCallAPI = {
      status: verifyStatus,
      bankCode: bankCode,
      bankBookName: bankNameForm,
      bankBookNumber: bankNumberForm,
    }
    if (data.type === 'DCA') {
      yield put(setAppValue('DCA.isAccountChecked', dataCallAPI))
      yield put(setAppValue('DCA.accountName', bankNameForm))
      yield put(setAppValue('DCA.isEnableAccountName', false))
    } else if (data.type === 'ATP') {
      yield put(setAppValue('ATP.isAccountChecked', dataCallAPI))
      yield put(setAppValue('ATP.bankBookName', bankNameForm))
      yield put(setAppValue('ATP.isEnableBankBookName', false))
    } else if (data.type === 'ATP-APIDOWN') {
      yield put(setAppValue('ATP.isAccountChecked', dataCallAPI))
      yield put(setAppValue('ATP.bankBookName', bankNameForm))
      yield put(setAppValue('ATP.isEnableBankBookName', true))
    } else if (data.type === 'DCA-APIDOWN') {
      yield put(setAppValue('DCA.isAccountChecked', dataCallAPI))
      yield put(setAppValue('DCA.accountName', bankNameForm))
      yield put(setAppValue('DCA.isEnableAccountName', true))
    } else if (data.type === 'DCA-ATP') {
      yield put(setAppValue('DCA.isAccountChecked', dataCallAPI))
      yield put(setAppValue('DCA.accountName', bankNameForm))
      yield put(setAppValue('ATP.isAccountChecked', dataCallAPI))
      yield put(setAppValue('ATP.bankBookName', bankNameForm))
      yield put(setAppValue('ATP.bankBookNumber', bankNumberForm))
      yield put(setAppValue('ATP.bank', app.DCA.bank))
      yield put(setAppValue('DCA.isEnableAccountName', false))
      yield put(setAppValue('ATP.isEnableBankBookName', false))
      yield [put(clonePhoto('dca-bank-book', 'bank-book')), take('LOAD_APP')]
    } else {
      let isHasDCA = isShowDCAForm(app)
      if (isHasDCA) {
        yield call(onSetDefaultInformationDCA, app)
      }
      yield put(setAppValue('ATP.isAccountChecked', dataCallAPI))
      yield put(setAppValue('ATP.bankBookName', bankNameForm))
      yield put(setAppValue('DCA.isAccountChecked', dataCallAPI))
      yield put(setAppValue('DCA.accountName', bankNameForm))
      yield put(setAppValue('DCA.accountNumber', bankNumberForm))
      yield put(setAppValue('DCA.bank', app.ATP.bank))
      yield put(setAppValue('DCA.isEnableAccountName', false))
      yield put(setAppValue('ATP.isEnableBankBookName', false))
      yield [put(clonePhoto('bank-book', 'dca-bank-book')), take('LOAD_APP')]
    }

    if ('state' in data) {
      if (data.type.startsWith('DCA')) {
        yield put(updateDCA())
      } else {
        if (data.state) {
          yield put(resetEDAViewFormOnly())
        } else {
          yield put(updateRecurringPayment())
        }
      }
    }
  } catch (error) {
    console.log('error save checkaccount', error)
  }
}

export const onSetDefaultInformationDCA = function*(_app) {
  try {
    yield put(setAppValue('DCA.status', VALUES.DCA_STATUS.INFORMATION))
    const insured = getInsuredDCAProps(_app)
    yield put(setAppValue('DCA.firstName', insured?.firstName))
    yield put(setAppValue('DCA.lastName', insured?.lastName))
    yield put(setAppValue('DCA.title', insured?.title))
    yield put(setAppValue('DCA.bank', VALUES.DCA_DEFAULT_BANK))
    return
  } catch (error) {
    return
  }
}

export const callConeImage = function*(from, destination, service) {
  try {
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)
    let image = yield call(getAttachment, app, from, user)

    const attachment = {
      file: image?.payload?.imageName?._attachments[from],
      name: destination,
    }

    yield call(uploadAttachment, service, attachment)
    return
  } catch (error) {
    console.log('error clone', error)
    return
  }
}

export const onCheckAccountDCAForm = function*(service, action) {
  let bankNameForm = ''
  let bankNumberForm = ''
  let bankCode = ''
  let verifyStatus = ''

  try {
    const app = yield select(getCurrentApp)
    const payload = action
    if ('name' in payload) {
      bankNameForm = payload.name
    } else {
      bankNameForm = app.DCA.accountName
    }
    if ('status' in payload) {
      verifyStatus = payload.status
    }
    bankNumberForm = app.DCA.accountNumber
    bankCode = app.DCA.bank.value

    const dataCallAPI = {
      status: verifyStatus,
      bankCode: bankCode,
      bankBookName: bankNameForm,
      bankBookNumber: bankNumberForm,
    }
    yield put(setAppValue('DCA.isAccountChecked', dataCallAPI))
    yield put(setAppValue('DCA.accountName', bankNameForm))
    return true
  } catch (error) {
    console.log('error', error)
    const dataCallAPI = {
      status: verifyStatus,
      bankCode: bankCode,
      bankBookName: bankNameForm,
      bankBookNumber: bankNumberForm,
    }
    yield put(setAppValue('DCA.isAccountChecked', dataCallAPI))
    return false
  }
}

export const cleanDataIsAccountChecked = function*(_type) {
  const dataCallAPI = {
    status: '',
    bankCode: '',
    bankBookName: '',
    bankBookNumber: '',
  }
  if (_type == 'DCA') {
    yield put(setAppValue('DCA.isAccountChecked', dataCallAPI))
  } else {
    yield put(setAppValue('ATP.isAccountChecked', dataCallAPI))
  }
}

export const onCheckAccountATPForm = function*(service, action) {
  let bankNameForm = ''
  let bankNumberForm = ''
  let bankCode = ''
  let verifyStatus = ''

  try {
    const payload = action
    const app = yield select(getCurrentApp)
    if ('name' in payload) {
      bankNameForm = payload.name
    } else {
      bankNameForm = app.ATP.bankBookName
    }
    if ('status' in payload) {
      verifyStatus = payload.status
    }
    bankNumberForm = app.ATP.bankBookNumber
    bankCode = app.ATP.bank.value

    const dataCallAPI = {
      status: verifyStatus,
      bankCode: bankCode,
      bankBookName: bankNameForm,
      bankBookNumber: bankNumberForm,
    }
    yield put(setAppValue('ATP.isAccountChecked', dataCallAPI))
    yield put(setAppValue('ATP.bankBookName', bankNameForm))

    return true
  } catch (error) {
    const dataCallAPI = {
      status: verifyStatus,
      bankCode: bankCode,
      bankBookName: bankNameForm,
      bankBookNumber: bankNumberForm,
    }
    yield put(setAppValue('ATP.isAccountChecked', dataCallAPI))
    return true
  }
}

export default function*(service, history) {
  yield [takeLatest('CHECK_ACC', onCheckAccount, service)]
  yield [takeLatest('SAVE_DATA_CHECK_ACCOUNT', saveDataCheckAccount, service)]
  yield [takeLatest('VERIFY_CHECK_ACCOUNT_DCA', onCheckAccountDCAForm, service)]
  yield [takeLatest('VERIFY_CHECK_ACCOUNT_ATP', onCheckAccountATPForm, service)]
}
