import {
  addError,
  addFile,
  addFileProps,
  changeCurrentApp,
  clearLoadingMessage,
  hideDialog,
  hideLoading,
  mergeApplication,
  remoteSellingError,
  remoteSellingSuccess,
  removeError,
  setAppStatusSigned,
  setAppValue,
  setInsuredCommandStatusCompleted,
  setInsuredCommandStatusUploading,
  setLoadingMessage,
  setPayerCommandStatusCompleted,
  setPayerCommandStatusUploading,
  showDialog,
  showLoading,
  showSignPad,
  showSubmitPaymentDialog,
  startSubmitPage,
  uploadRemoteSellingReceipt,
  setBackIdCardLaserCode,
  closeSendingLinkModalRemoteSelling,
  closeRemoteSellingResumeModal,
} from 'e-submission/apps/actions'
import { getCurrentApp, getFiles, getFirstName, getLastName, isShowDialog, getPages } from 'e-submission/apps/selectors'
import {
  getVideoConsentData,
  insurerDoesEkyc,
  payerDoesEkyc,
  getPolicyId,
  getRemoteSellingState,
  getUploadedDocuments,
  isProprietorNeedEkyc,
  isSelectedRemoteSelling,
} from 'e-submission/domain/data-model/form/selectors'
import {
  mappingCaseKeeper,
  replaceSendingDetailForResendRemoteSellingCasePayload,
  resendCasePayload,
  setAttachmentPayload,
} from './policy-payload/ekyc-case-keeper'
import { APP_STATUS } from 'e-submission/domain/data-model/constants'
import df2f, { DF2F } from 'e-submission/utils/bridging/df2f'
import { getIdentityUser } from 'identity/selectors'
import _ from 'lodash'
import { isEmpty } from 'lodash'
import { getOr, get } from 'lodash/fp'
import generateUUID from 'uuid/v1'

import { omit, map, flow, keyBy, isNil, findIndex } from 'lodash/fp'
import ROUTE_PATH from 'quick-quote/constants/path'
import { getRemoteSellingCaseId, setRemoteSellingState, START_VIDEO_RECORDING } from 'quick-quote/remote-selling'
import { isLoading } from 'quick-quote/ui/selectors'
import { call, take, put, select, all, takeLatest, race } from 'redux-saga/effects'
import { onCreateAdditionalDocument, onListDocument, uploadAttachment } from './document'
import { waitTillSuccessOrFailed } from './policy'
import { delay } from 'redux-saga'
import { CREATE_REMOTE_SELLING_CASE } from 'core/action-types'
import { getAppConfig } from '../../deploy-env/app-config'
import { getVerificationDetail, prepareEkycDetailResult } from 'e-submission/utils'
import { resetMediaControlState } from 'quick-quote/actions'
import { CHECK_SYNC_DELAY, onGetAttachment } from './application'
import moment from 'moment'
import { clearEkycResults } from 'core/actions'

export const onResumeAppmanRemoteSelling = function*(history, service, action) {
  console.info('onResumeAppmanRemoteSelling')
  const { listApplicationSummary } = service
  const { appId, caseId } = action.payload
  const user = yield select(getIdentityUser)
  try {
    yield put.resolve(showLoading())
    if (!isNil(appId) && !isEmpty(appId)) {
      const response = yield call(listApplicationSummary, user)
      const appSummaries = flow(map(omit(['_rev'])), keyBy('_id'))(response)
      yield put(mergeApplication(appId, appSummaries))
      const app = appSummaries[appId]
      if (app) {
        yield [put(changeCurrentApp(appId)), take('SAVE_APPLICATION_SUCCESS')]
        const loadedApp = yield select(getCurrentApp)
        const { status } = loadedApp
        console.info('loaded app to resume' + (status || 'DRAFTED'))
        if (status === APP_STATUS.SIGNED || status === APP_STATUS.SUBMITTED) {
          yield call(history.push, ROUTE_PATH.REMOTE_SELLING.SUBMIT)
        } else if (isNil(status) || status === APP_STATUS.DRAFTED) {
          const insuredDocs = get('signedDocs.insured', loadedApp) || []
          const acknowledgeDocs = get('signedDocs.acknowledge', loadedApp) || []
          if (insuredDocs.length > 0 && acknowledgeDocs.length > 0) {
            yield call(history.push, ROUTE_PATH.REMOTE_SELLING.VIDEO_CONSENT)
          } else {
            yield call(history.push, ROUTE_PATH.ESUBMISSION_FORM)
          }
        } else {
          yield call(history.push, ROUTE_PATH.NEED_AND_GAP_ANALYSIS)
        }
        if (caseId) {
          yield put(setAppValue('remoteSelling.caseId', caseId))
        }
        yield put(setAppValue('remoteSelling.loadingVideoConsent', false))
        yield put(remoteSellingSuccess())
      } else {
        console.info('App not found', appId)
        yield put(remoteSellingError({ msg: 'App not found', errorCode: 404 }))
      }
    } else {
      console.info('AppId is empty', appId)
      yield put(remoteSellingError({ msg: 'Not resumed' }))
    }
  } catch (err) {
    console.error('Failed to resume ', err)
    yield put(remoteSellingError())
  } finally {
    yield put(hideLoading())
  }
}
export function* createRemoteSellingApp(action) {
  const { appId } = action
  yield take('LOAD_APP')
  const app = yield select(getCurrentApp)
  const insuredInfo = {
    firstNameInsured: yield select(getFirstName),
    lastNameInsured: yield select(getLastName),
  }
  const isRemoteSelling = isSelectedRemoteSelling(app)
  if (isRemoteSelling) {
    try {
      yield call(df2f.sendEvent, DF2F.CREATE_NEW_CASE, { appId, ...insuredInfo })
    } catch (e) {
      console.error('Tried to create new case in DF2F, but failed')
    }
  }
}

export const onSignedAgentDocuments = function*(history, service) {
  const { patchPolicy } = service
  const app = yield select(getCurrentApp)
  try {
    yield put.resolve(showLoading())
    const documents = yield call(onListDocument, service)
    const isNoSignature = documents.every(({ signature }) => _.isEmpty(signature))
    if (isNoSignature) {
      yield put(hideLoading())
      throw new Error('Signature not signed for', isNoSignature)
    } else {
      const user = yield select(getIdentityUser)
      const policyId = getPolicyId(app)
      const reqPayload = {
        status: APP_STATUS.SIGNED,
      }
      const policy = yield call(patchPolicy, user, policyId, reqPayload)
      if (!policy) {
        throw new Error('Could not sign policy')
      } else {
        const setAppSuccess = yield call(waitTillSuccessOrFailed, setAppStatusSigned)
        if (!setAppSuccess) {
          yield put(showDialog({ name: 'Error', type: 'failed' }))
          return
        } else {
          yield put(startSubmitPage())
          yield call(history.push, ROUTE_PATH.REMOTE_SELLING.SUBMIT)
        }
      }
    }
  } catch (err) {
    yield put(showDialog({ name: 'Error', type: 'failed' }))
  } finally {
    yield put(hideLoading())
  }
}

const uploadAdditionalDocument = function*(service, document, actionName, existingIds = []) {
  try {
    const { name, fileType, url } = document
    yield put(
      addFileProps(actionName, {
        description: name,
      })
    )
    const imageName = yield call(onCreateAdditionalDocument, service, actionName, true)
    const attachment = {
      type: fileType,
      url,
      name: imageName,
      isInsured: true,
      fetch: true,
    }
    yield call(uploadDocumentFromUrl, service, { payload: attachment })
    const files = yield select(getFiles)
    const { description, documentId } = getOr({}, imageName, files)
    yield put(
      addFile(imageName, true, {
        documentId,
        description: description ? description : name,
        creationdate: Date.now(),
        isInsured: true,
        fileType,
      })
    )
    yield put(addFileProps(actionName, { description: '' }))
  } catch (e) {
    console.error('Failed to upload additional document', e)
  }
}

export const sendUploadAdditionalDocument = function*(services, action) {
  try {
    const { name, isInsured, limit } = action.payload
    yield put(isInsured ? setInsuredCommandStatusUploading(name) : setPayerCommandStatusUploading(name))
    const app = yield select(getCurrentApp)
    const existingIds = yield call(getUploadedDocuments, name, app)
    const { files, cancel } = yield race({
      files: call(df2f.sendEvent, DF2F.UPLOAD_EVENT, { limit: limit || 1, type: name, documentIds: existingIds || [] }),
      cancel: take('CANCEL_EVENT'),
    })
    if (files && files.length > 0) {
      const file = files[0]
      const attachment = {
        type: 'image/png',
        url: file.url,
        name,
        isInsured: true,
        fetch: true,
      }
      yield call(uploadDocumentFromUrl, services, { payload: attachment })
    }
    if (cancel) {
      console.info('cancel upload additional document')
    }
    yield put(isInsured ? setInsuredCommandStatusCompleted(name) : setPayerCommandStatusCompleted(name))
  } catch (e) {
    console.error('Failed to upload additional document', e)
  }
}
export const onUploadMultipleDocuments = function*(service, action) {
  const { name: actionName, limit } = action
  try {
    yield put(setAppValue('remoteSelling.isUploading', true))
    const app = yield select(getCurrentApp)
    const existingIds = yield call(getUploadedDocuments, actionName, app) || []
    const files = yield call(df2f.sendEvent, DF2F.UPLOAD_EVENT, {
      limit,
      type: actionName,
      documentIds: existingIds || [],
    })
    yield all(files.map((file) => call(uploadAdditionalDocument, service, file, actionName, existingIds)))
    const fileIds = files.map((file) => file.id)
    yield [
      put(
        setAppValue(`remoteSelling.${actionName}`, {
          documentIds: existingIds.concat(fileIds),
        })
      ),
      take('SAVE_APPLICATION_SUCCESS'),
    ]
    yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
  } catch (err) {
    console.error('Failed to upload document', err)
  } finally {
    yield put(setAppValue('remoteSelling.isUploading', false))
  }
}
export const submitCase = function*(service) {
  const user = yield select(getIdentityUser)
  const { submitEkycCase } = service
  const app = yield select(getCurrentApp)
  const isAppmanRemoteSelling = yield call(isSelectedRemoteSelling, app)
  if (isAppmanRemoteSelling) {
    try {
      const caseId =
        getRemoteSellingCaseId(app) ||
        getOr(null, `ekycInfo.insured.caseId`, app) ||
        getOr(null, `ekycInfo.payer.caseId`, app)
      yield call(submitEkycCase, user, caseId)
    } catch (e) {
      console.error(e)
    }
  }
}
export function* uploadDocumentFromUrl(services, action) {
  const { saveAttachmentFromURL, getAttachment } = services
  const { url, name, isInsured, fetch, type } = action.payload
  try {
    yield put(removeError(name))
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)
    const applicationId = get('applicationId', app)
    const document = {
      applicationId,
      url,
      attachmentId: name,
      type,
    }
    yield call(saveAttachmentFromURL, user, document)
    const response = yield call(getAttachment, app, name, user)
    yield [call(uploadAttachment, services, { file: response, name, isInsured }), take('UPLOAD_ATTACHMENT_SUCCESS')]
    if (fetch) {
      yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
    }
  } catch (err) {
    yield put(addError(name, err))
    console.error(err)
  } finally {
    yield put(isInsured ? setInsuredCommandStatusCompleted(name) : setPayerCommandStatusCompleted(name))
  }
}

export const onSentQrPaymentEvent = function*(history, action) {
  const { documentTypeName, data, bankList, documentIds } = action.payload
  const payloadData = {
    type: 'qr',
    data,
    uploadConfig: {
      type: documentTypeName,
      limit: 1,
      locale: 'th',
    },
    bankList,
    documentIds,
  }
  try {
    yield put(showSubmitPaymentDialog())
    yield put(setLoadingMessage('ลูกค้ากำลังชำระเงิน กรุณารอสักครู่'))
    yield delay(1000)
    const { response, cancel } = yield race({
      response: call(df2f.sendEvent, DF2F.PAYMENT_EVENT, payloadData),
      cancel: take('CANCEL_EVENT'),
    })
    console.info('QR PAYMENT EVENT RESPONSE', response)
    if (response) {
      const { success } = response
      if (success) {
        yield put(uploadRemoteSellingReceipt({ data: response, name: documentTypeName }))
      }
    }
    if (cancel) {
      yield put(hideDialog())
      history.push(ROUTE_PATH.REMOTE_SELLING.REMOTE_PAYMENT)
      return
    }
  } catch (e) {
    yield put(hideDialog())
    history.push(ROUTE_PATH.REMOTE_SELLING.REMOTE_PAYMENT)
    console.error('Failed to send QR payment event', e)
  } finally {
    yield put(hideDialog())
    yield put(clearLoadingMessage())
  }
}

export const updateClientStatus = function*(action) {
  const { isClientStreaming } = action.payload
  yield put(setAppValue('remoteSelling.callStatus', action.payload))

  if (isClientStreaming === false) {
    yield call(cancelEvent)
  }
}
export const sendPreviewDocument = function*(service, action) {
  try {
    yield put.resolve(showLoading())
    const { getBase64Document } = service
    const user = yield select(getIdentityUser)
    const document = action.payload
    const response = yield call(getBase64Document, user, document)
    const docData = {
      code: response.code,
      name: document.shortText || document.text,
      data: response.data,
    }
    const payload = {
      needSignature: document?.needSignature || false,
      currentDocument: 1,
      maximumDocuments: 1,
      messageID: generateUUID(),
      documents: docData,
    }
    yield put(setLoadingMessage('ลูกค้ากำลังลงนามเอกสาร กรุณารอสักครู่'))
    const { signature, cancel } = yield race({
      signature: call(df2f.sendEvent, DF2F.REVIEW_DOCUMENT, payload),
      cancel: take('CANCEL_EVENT'),
    })
    if (cancel) {
      return
    }
    if (signature) {
      yield put(setAppValue('clientSignature', signature['url']))
      yield put(hideLoading())
      yield put(showSignPad(document))
    }
  } catch (e) {
    console.error(e)
    yield put(hideLoading())
  } finally {
    yield put(clearLoadingMessage())
  }
}

export const resendSignature = function*(service) {
  try {
    yield put.resolve(showLoading())
    yield put(setLoadingMessage('ลูกค้ากำลังลงนามเอกสาร กรุณารอสักครู่'))
    yield put(setAppValue('clientSignature', null))
    const { signature, cancel } = yield race({
      signature: call(df2f.sendEvent, DF2F.SIGNATURE_EVENT, {
        needSignature: true,
      }),
      cancel: take('CANCEL_EVENT'),
    })
    if (cancel) {
      return
    }
    if (signature) {
      yield put(setAppValue('clientSignature', signature['url']))
    }
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
  } finally {
    yield put(clearLoadingMessage())
  }
}
export const cancelEvent = function*(action) {
  const { name, isInsured } = action?.payload || {}
  const app = yield select(getCurrentApp)
  const isUploading = get('remoteSelling.isUploading', app)
  const isUiLoading = yield select(isLoading)
  const isDialogOpen = yield select(isShowDialog)
  if (name) {
    yield put(isInsured ? setInsuredCommandStatusCompleted(name) : setPayerCommandStatusCompleted(name))
  }
  if (isUploading) yield put(setAppValue('remoteSelling.isUploading', false))

  if (isUiLoading) yield put(hideLoading())

  if (isDialogOpen) yield put(hideDialog())

  yield call(df2f.sendEvent, DF2F.CANCEL_EVENT)
}

export const onConfirmSubmittedPolicy = function*(service) {
  try {
    const { retrieveEkycReport } = service
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)
    const applicationId = get('_id', app)
    const remoteSellingState = getRemoteSellingState(app)
    const insuredProprietorId = isProprietorNeedEkyc(app, 'insured') && _.get(app, 'ekycInfo.insured.proprietorId', '')
    const payerProprietorId = isProprietorNeedEkyc(app, 'payer') && _.get(app, 'ekycInfo.payer.proprietorId', '')
    let requestPayload = {
      applicationId,
    }
    yield put(showLoading())
    if (insuredProprietorId) {
      yield call(retrieveEkycReport, user, {
        ...requestPayload,
        attachmentId: 'insured-ekyc',
        proprietorId: insuredProprietorId,
      })
    }
    if (payerProprietorId) {
      yield delay(3000)
      yield call(retrieveEkycReport, user, {
        ...requestPayload,
        proprietorId: payerProprietorId,
        attachmentId: 'payer-ekyc',
      })
    }
    yield [put({ type: 'FETCH_CURRENT_APP' }), take('LOAD_APP')]
    yield put(hideLoading())
    yield put(startSubmitPage())
  } catch {
    yield put(hideLoading())
    yield put(startSubmitPage())
  }
}

export const createRemoteSellingCase = function*(service) {
  const app = yield select(getCurrentApp)
  const remoteSellingState = getRemoteSellingState(app)
  const user = yield select(getIdentityUser)
  const isLinkModalOpen = false
  const isModalCreatingRemoteSellingOpen = true

  try {
    yield put.resolve(showLoading())
    const {
      createEkycCase,
      closeEkycCase,
      getVerifyCitizenId,
      verifyByProprietorId,
      updateVerificationRef,
      resendEkycLink,
    } = service
    if (remoteSellingState.resumeState === 'sendLink') {
      const currentProprietorId =
        _.get(app, `ekycInfo.insured.proprietorId`, '') || _.get(app, `ekycInfo.payer.proprietorId`, '')
      const resendEkycPayload = resendCasePayload(app, currentProprietorId)
      const resendPayload = replaceSendingDetailForResendRemoteSellingCasePayload(remoteSellingState, resendEkycPayload)

      yield call(resendEkycLink, user, resendPayload)
      yield put(
        setAppValue('remoteSelling', {
          ...remoteSellingState,
          creatingRemoteSellingCaseStatus: 'success',
          isLinkModalOpen,
          isModalCreatingRemoteSellingOpen,
        })
      )
      return
    }

    const applicationId = get('_id', app)
    const existingCaseId =
      getOr(false, 'remoteSelling.ekyc.caseId', app) || getOr(false, 'ekycInfo.insured.caseId', app)

    if (existingCaseId) {
      try {
        yield call(closeEkycCase, user, existingCaseId)
      } catch (error) {
        console.error('Error closing existing case', error)
      } finally {
        yield put(setAppValue('remoteSelling.roomId', null))
        yield put(setAppValue('remoteSelling.ekyc.caseId', null))
        yield put(setAppValue('ekycInfo.insured.caseId', null))
      }
    }

    const payerNeedsToDoEkyc = yield call(payerDoesEkyc, app)
    const insurerNeedsToDoEkyc = yield call(insurerDoesEkyc, app)
    const insuredMapping = get('proprietors.0', mappingCaseKeeper(app, 'insured', false, true))
    const payerMapping = get('proprietors.0', mappingCaseKeeper(app, 'payer', false, true))
    const proprietors = []
    if (insurerNeedsToDoEkyc) proprietors.push(insuredMapping)
    if (payerNeedsToDoEkyc) proprietors.push(payerMapping)
    if (proprietors.length === 0) {
      proprietors.push({
        proprietorType: 'insured',
        firstName: get('firstName', insuredMapping),
        lastName: get('lastName', insuredMapping),
        phoneNumber: get('phoneNumber', insuredMapping),
        email: get('email', insuredMapping),
        inviteType: get('inviteType', insuredMapping),
        expiryDuration: 30,
      })
    }
    if (proprietors.length === 1 && (insurerNeedsToDoEkyc || payerNeedsToDoEkyc)) {
      proprietors[0].inviteType = remoteSellingState.sendingMethod
      if (remoteSellingState.sendingMethod === 'email') {
        proprietors[0].email = remoteSellingState[remoteSellingState.sendingMethod]
      } else {
        proprietors[0].phoneNumber = remoteSellingState[remoteSellingState.sendingMethod]
      }
    }
    const createCaseBody = {
      caseType: {
        key: 'default',
        code: '001',
        translations: {
          en: {
            label: 'Remote Selling',
          },
          th: {
            label: 'Remote Selling',
          },
          id: {
            label: 'Remote Selling',
          },
        },
      },
      proprietors: proprietors,
      attachMeetingRoom: true,
      appNumber: applicationId,
      policies: [
        {
          policyNumber: '',
        },
      ],
      department: '/new-business',
    }

    const { response } = yield call(createEkycCase, user, createCaseBody) || {}
    if (!response) {
      yield [
        put(
          setAppValue('remoteSelling', {
            ...remoteSellingState,
            creatingRemoteSellingCaseStatus: 'failed',
            isLinkModalOpen,
            isModalCreatingRemoteSellingOpen,
          })
        ),
        take('SAVE_APPLICATION_SUCCESS'),
      ]
      throw Error('Error creating new case')
    }
    const { id: caseId, meetingRoomId, status } = response || {}
    yield [
      put(
        setAppValue('remoteSelling', {
          ...remoteSellingState,
          ekyc: {
            caseId,
            status,
          },
          creatingRemoteSellingCaseStatus: 'success',
          roomId: meetingRoomId,
          isLinkModalOpen,
          isModalCreatingRemoteSellingOpen,
        })
      ),
      take('SAVE_APPLICATION_SUCCESS'),
    ]
    const proprietorsList = getOr([], 'proprietors', response)
    const policyId = get(['policies', '0', 'id'], response)

    for (let index = 0; index < proprietorsList.length; index++) {
      const prop = proprietorsList[index]
      const insuredOrPayer = get('proprietorType', prop)
      yield put(setAppValue(`ekycInfo.${insuredOrPayer}.proprietorId`, prop.id))
      yield put(setAppValue(`ekycInfo.${insuredOrPayer}.caseId`, caseId))
      yield put(setAppValue(`ekycInfo.${insuredOrPayer}.policyId`, policyId))

      if (insuredOrPayer === 'payer' && !payerNeedsToDoEkyc) {
        continue
      }
      if (insuredOrPayer === 'insured' && !insurerNeedsToDoEkyc) {
        continue
      }
      const id = _.get(app, `${insuredOrPayer}.idNo.citizenId`)
      const { response } = yield call(getVerifyCitizenId, user, id)

      const cases = get(['data'], response)
      const verifiedCases = cases.filter((caseElement) => {
        const verificationCache = caseElement.verificationCache
        const idFaceRecognitionConfigRequired = _.get(verificationCache, 'idFaceRecognitionConfig.required')
        const idFaceRecognitionResultVerified = _.get(verificationCache, 'idFaceRecognitionResult.verified')
        const idFaceRecognitionResultLiveNessSuccess = _.get(
          verificationCache,
          'idFaceRecognitionResult.livenessResponse.success'
        )
        const faceRecognitionCreatedTime = _.get(verificationCache, 'idFaceRecognitionResult.createdAt')
        const isFaceRecognitionCreatedTimeMoreThan30Days = moment(faceRecognitionCreatedTime)
          .add(30, 'days')
          .isAfter()

        return (
          verificationCache.status === 'verified' &&
          idFaceRecognitionConfigRequired &&
          idFaceRecognitionResultVerified &&
          idFaceRecognitionResultLiveNessSuccess &&
          isFaceRecognitionCreatedTimeMoreThan30Days
        )
      })

      const firstVerifiedCase = verifiedCases[0]
      if (firstVerifiedCase && firstVerifiedCase.id) {
        const { response } = yield call(verifyByProprietorId, user, firstVerifiedCase.id)
        const verificationRef = get(['id'], response)
        yield call(updateVerificationRef, user, prop.id, {
          verificationRef: verificationRef,
        })
      }
    }
  } catch (error) {
    yield [
      put(
        setAppValue('remoteSelling', {
          ...remoteSellingState,
          creatingRemoteSellingCaseStatus: 'failed',
          isLinkModalOpen: false,
          isModalCreatingRemoteSellingOpen: true,
        })
      ),
      take('SAVE_APPLICATION_SUCCESS'),
    ]
    console.error(error)
  } finally {
    yield put(hideLoading())
  }
}

const nativeCallbacks = function*(callback = '') {
  switch (callback) {
    case 'back':
      yield put(setAppValue('remoteSelling.callStatus.isInCall', false))
      break
    case 'hangup': {
      yield put(setAppValue('remoteSelling.callStatus.isInCall', false))
      yield put({
        type: 'HANGUP',
      })
      yield put(resetMediaControlState())
      break
    }
    default:
      break
  }
}

const saveEkycData = function*(proprietorId, insuredOrPayer, service) {
  try {
    if (!proprietorId) return
    const { verifyByProprietorId, saveAttachmentFromURL } = service
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)
    const { response } = yield call(verifyByProprietorId, user, proprietorId)
    const verificationDetail = getVerificationDetail(response)
    const verificationId = _.get(response, 'id', null)
    yield put(setAppValue(`ekycInfo.${insuredOrPayer}.verificationDetail`, { ...verificationDetail }, true))
    yield put(setAppValue(`ekycInfo.${insuredOrPayer}.verificationId`, verificationId))

    const { backIdCardResult, frontIdCardResult } = prepareEkycDetailResult(response)
    yield put(setBackIdCardLaserCode(backIdCardResult.laser, insuredOrPayer))
    const frontIdUrl = _.get(frontIdCardResult, 'idCardImageUrl', null)
    if (frontIdUrl) {
      const payload = setAttachmentPayload(insuredOrPayer, app, frontIdUrl, 'id-card')
      const newAttachments = yield call(saveAttachmentFromURL, user, payload)
      yield put(setAppValue(`_attachments.${payload.attachmentId}`, newAttachments[payload.attachmentId]))
      yield call(onGetAttachment, service, {
        payload: {
          imageName: insuredOrPayer === 'insured' ? 'insured-id-card' : 'owner-id-card',
          insuredOrPayer: insuredOrPayer,
        },
      })
    }
  } catch (error) {
    console.error('Error Setting EKYC data', error)
    throw error
  }
}
export const openNative = function*(history, service, action) {
  const { nextProcess, push, resume } = action.payload || {}
  try {
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)
    const status = app.status
    const isInCall = get('remoteSelling.callStatus.isInCall', app)

    yield put(closeSendingLinkModalRemoteSelling())
    yield put(closeRemoteSellingResumeModal())
    let event = DF2F.OPEN_DF2F_APP
    const df2fOption = {
      env: getAppConfig().OC_ENVIRONMENT || 'uat',
      user: get('profile', user),
      reconnectTimeout: 60,
      isJoinedWithVDOCall: get('remoteSelling.callStatus.isInCall', app) ?? false,
      ...action.payload,
    }

    const payerNeedsToDoEkyc = yield call(payerDoesEkyc, app)
    const insurerNeedsToDoEkyc = yield call(insurerDoesEkyc, app)

    if (
      (nextProcess !== 'videoConsent' && !payerNeedsToDoEkyc && !insurerNeedsToDoEkyc) ||
      nextProcess === 'videoConsent'
    ) {
      df2fOption.nextProcess = 'none'
    }

    if (isInCall && nextProcess === 'ekyc') {
      event = DF2F.OPEN_ON_BOARDING_STEP
      df2fOption.isJoinedWithVDOCall = true
      df2fOption.step = nextProcess
    }

    yield put(setAppValue('remoteSelling.selectedRemoteSelling', true))
    let respondValue = { name: null, payload: null }
    try {
      respondValue = yield call(df2f.sendEvent, event, df2fOption)
    } catch (e) {
      yield put(setRemoteSellingState({ isInCall: false }))
    }
    const name = respondValue.name

    yield put.resolve(showLoading())
    const pages = yield select(getPages)
    const currentPageIndex = findIndex(['id', 'ekyc'], pages)
    const nextPage = get(currentPageIndex + 1, pages)

    const goToPage = (pageId) => push && history.push(`/e-submission/form/${pageId}`)
    switch (name) {
      case 'startVideoCall': {
        yield put(setRemoteSellingState({ isInCall: true }))
        yield delay(CHECK_SYNC_DELAY)
        goToPage(nextPage.id)
        break
      }
      case 'nextStep': {
        yield put(setRemoteSellingState({ isInCall: true }))
        if (nextProcess === 'none' && status === 'signed') {
          break
        }
        if (nextProcess === 'ekyc') {
          const insuredProprietorId = _.get(app, `ekycInfo.insured.proprietorId`, undefined)
          if (insuredProprietorId) {
            yield call(saveEkycData, insuredProprietorId, 'insured', service)
          }
          const payerProprietorId = _.get(app, `ekycInfo.payer.proprietorId`, undefined)
          if (payerProprietorId) {
            yield call(saveEkycData, payerProprietorId, 'payer', service)
          }
        }
        if (nextProcess === 'videoConsent') {
          yield call(nativeCallbacks, 'hangup')
          if (resume) {
            yield [put(setAppValue('remoteSelling.videoConsented', true)), take('SAVE_APPLICATION_SUCCESS')]
            yield call(submitCase, service)
            history.push(ROUTE_PATH.REMOTE_SELLING.SUBMIT)
            break
          }
        }
        goToPage(nextPage.id)
        yield delay(CHECK_SYNC_DELAY)
        yield put(setRemoteSellingState({ isInCall: true }))
        break
      }
      default: {
        yield call(nativeCallbacks, name)
        break
      }
    }
    yield put.resolve(hideLoading())
  } catch (error) {
    yield put.resolve(hideLoading())
    console.error(error)
  }
}

export const startVideoConsent = function*(history, service) {
  try {
    const app = yield select(getCurrentApp)
    yield put.resolve(showLoading())
    const videoConsentPayload = yield call(getVideoConsentData, app)
    const roomId = get('remoteSelling.roomId', app)
    const { name } = yield call(df2f.sendEvent, DF2F.OPEN_ON_BOARDING_STEP, {
      roomId,
      step: 'recordVideoConsent',
      isJoinedWithVDOCall: get('remoteSelling.callStatus.isInCall', app) ?? false,
      videoConsentPayload,
    })
    if (name === 'nextStep') {
      yield [put(setAppValue('remoteSelling.videoConsented', true)), take('SAVE_APPLICATION_SUCCESS')]
      yield call(nativeCallbacks, 'hangup')
      yield call(submitCase, service)
      history.push(ROUTE_PATH.REMOTE_SELLING.SUBMIT)
    }
    yield put.resolve(hideLoading())
    yield call(nativeCallbacks, name)
  } catch (error) {
    yield put.resolve(hideLoading())
  }
}

export const uncheckAppmanRemoteSelling = function*() {
  yield put(setAppValue('selectedRemoteSelling', false))
  yield put(setAppValue('remoteSelling.isDataConfirmed', false))
  yield put(clearEkycResults())
}

export const checkAppmanRemoteSelling = function*() {
  yield put(clearEkycResults())
  yield delay(100)
  yield put(setAppValue('selectedRemoteSelling', true))
}

export default function*(service, history) {
  yield all([
    takeLatest('UPLOAD_MULTIPLE_DOCUMENTS', onUploadMultipleDocuments, service),
    takeLatest('RESUME_APPMAN_REMOTE_SELLING', onResumeAppmanRemoteSelling, history, service),
    takeLatest('SIGNED_AGENT_DOCUMENTS', onSignedAgentDocuments, history, service),
    takeLatest('SUBMIT_POLICY', submitCase, service),
    takeLatest('ESUB_UPLOAD_DOCUMENT_FROM_URL', sendUploadAdditionalDocument, service),
    takeLatest('SEND_QR_PAYMENT_EVENT', onSentQrPaymentEvent, history),
    takeLatest('UPDATE_CALL_STATUS_REMOTE', updateClientStatus),
    takeLatest('START_VIDEO_RECORDING', startVideoConsent, history, service),
    takeLatest('SEND_PREVIEW_DOCUMENT', sendPreviewDocument, service),
    takeLatest('RESEND_SIGNATURE', resendSignature, service),
    takeLatest('CANCEL_EVENT', cancelEvent),
    takeLatest('CREATE_NEW_APP', createRemoteSellingApp),
    takeLatest(CREATE_REMOTE_SELLING_CASE, createRemoteSellingCase, service),
    takeLatest('UNCHECK_APPMAN_REMOTE_SELLING', uncheckAppmanRemoteSelling, service),
    takeLatest('CHECK_APPMAN_REMOTE_SELLING', checkAppmanRemoteSelling, service),
    takeLatest('SUBMITTED_POLICY', onConfirmSubmittedPolicy, service),
    takeLatest('BACK_TO_HOME', function*() {
      yield put('RESET_APP_STATE')
    }),
    takeLatest('OPEN_NATIVE', openNative, history, service),
  ])
}
