import { getCurrentApp } from 'e-submission/apps/selectors'
import { get } from 'lodash/fp'

export const luckyNumber = (state) => {
  const data = get('luckyNumber', getCurrentApp(state))

  if (data) {
    const { status, selected } = data
    return { status, selected }
  }

  return undefined
}
