import { spawn, put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { includes, endsWith, getOr, has } from 'lodash/fp'

import policy from './policy'
import form from './form'
import application from './application'
import document from './document'
import payment from './payment'
import dca from './dca'
import core from 'core/sagas'
import remoteSelling from './remoteSelling'
import opty from './opty'
import checkAccount from './checkAccount'
import verifyOcr from './verify_ocr'

import * as services from 'e-submission/domain/service'

import { showErrorDialog } from 'e-submission/apps/actions'
import requestWrapper, { instance } from 'e-submission/domain/data/request-wrapper'
import { instance as appInstance } from 'e-submission/domain/data/application-web-wrapper'
import { analyticsPageViewEvent } from 'analytics/actions'

import { getValidationErrorMessage, VALIDATION } from 'core/service/advisorzone-middleware/validation-error-message'
import { getToggles } from 'quick-quote/feature-toggles'
import _ from 'lodash'

export const interceptResponse = (emit) => {
  const id = instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorResponse = getOr({}, 'response', error)
      const statusCode = getOr('network_error', 'status', errorResponse)

      if (
        getToggles().ENABLE_WAF_LOG &&
        errorResponse.data &&
        _.isString(errorResponse.data) &&
        errorResponse.data.indexOf('The requested URL was rejected') > 0
      ) {
        const idTokenInfo = JSON.parse(localStorage.getItem('userInfo'))
        requestWrapper.postRequest('logger/waf', idTokenInfo, { body: errorResponse.data })
      }

      if (
        includes('/esub', error.config.url) ||
        includes('/waf', error.config.url) ||
        /\/advisors[\/](\w|\d)/.test(error.config.url)
      ) {
        const ignore =
          (error.config.method === 'patch' && includes('status', error.config.data)) ||
          endsWith('/payments/credit-card', error.config.url) ||
          endsWith('/status', error.config.url) ||
          has('otp-pincode', error.config.headers) ||
          (endsWith('/otp', error.config.url) && statusCode === 412)

        emit({
          error: errorResponse,
          url: `${error.config.url}/error/truck/statusCode/${statusCode}`,
          ignore,
          statusCode,
        })
      } else if (includes('/e-submission', error.config.currentHref)) {
        emit({
          error: errorResponse,
          url: `${error.config.url}/error/truck/statusCode/${statusCode}`,
          ignore: true,
          statusCode,
        })
      }
      return Promise.reject(error)
    }
  )
  return () => {
    instance.interceptors.response.eject(id)
  }
}

export const interceptAppResponse = (emit) => {
  const id = appInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorResponse = getOr({}, 'response', error)
      emit(errorResponse)
      return Promise.reject(error)
    }
  )
  return () => {
    appInstance.interceptors.response.reject(id)
  }
}

export const getDialogType = (result) => {
  const errorType = getOr('', 'error.data.type', result)
  switch (errorType) {
    case 'RATE_VALIDATION':
      return 'failedNewRate'
    case 'RATE_SIGNED_VALIDATION':
      return 'failedSignedNewRate'
    case 'PAYMENT_PENDING':
      return 'failedPayment'
    default:
      return 'failed'
  }
}

const channel = eventChannel(interceptResponse)
const appApiChannel = eventChannel(interceptAppResponse)

export function* watchAppApiError() {
  // prettier-ignore
  while (true) { // NOSONAR
    const result = yield take(appApiChannel)
    if (result.status === 403 && getToggles().ENABLE_OFFLINE_ACCESS_FLOW_ONLY) {
      yield put(showErrorDialog('unauthorized'))
    }
  }
}

export default function*(history) {
  yield [
    spawn(payment, services, history),
    spawn(document, services),
    spawn(dca, services, history),
    spawn(application, services, history),
    spawn(policy, services, history),
    spawn(form, services, history),
    spawn(core),
    spawn(remoteSelling, services, history),
    spawn(opty),
    spawn(checkAccount, services, history),
    spawn(verifyOcr, services, history),
    spawn(function*() {
      // prettier-ignore
      while (true) { // NOSONAR
        const result = yield take(channel)
        if (getToggles().ENABLE_REMOVE_POLICY_NO_GA4) {
          yield put(analyticsPageViewEvent(result.url))
        }
        if (!result.ignore) {
          let dialogType
          switch (result.statusCode) {
            case 401:
            case 403:
              dialogType = 'unauthorized'
              break
            default:
              dialogType = getDialogType(result)
              break
          }

          if (result.error.data) {
            const errorRes = getErrorMsgFromErrorData(result.error.data)
            yield put(showErrorDialog(dialogType, getValidationErrorMessage(getToggles().ENABLE_RED_TRUCK_ERROR_MSG ? errorRes : result.error.data)))
          }
        }
      }
    }),
    spawn(watchAppApiError),
  ]
}

export const getErrorMsgFromErrorData = (errorData) => {
  if (errorData.message) {
    return { messages: [JSON.stringify(errorData)], type: VALIDATION }
  } else {
    return errorData
  }
}
