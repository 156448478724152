import _getOr from 'lodash/fp/getOr'
import moment from 'moment'
import { titleEkyc } from '../../domain/data-model/constants'
import { isSelectedRemoteSelling } from 'quick-quote/selectors'
import { getRemoteSellingState } from 'e-submission/domain/data-model/form/selectors'

function getSendingMethodDetail(remoteSellingState, sendLinkEkycDetail, isReuse, insuredOrPayer) {
  let method = _getOr('', ['method'], sendLinkEkycDetail)
  let textInput = _getOr('', ['textInput'], sendLinkEkycDetail)
  let notifyType = isReuse ? { notifyType: 'none' } : { notifyType: method }

  if (remoteSellingState.isDataConfirmed) {
    method = insuredOrPayer === 'insured' ? remoteSellingState.sendingMethod : 'none'
    textInput = remoteSellingState[method]
    notifyType = { notifyType: method }
  }

  return {
    method,
    textInput,
    notifyType,
  }
}

function getTextInput(decision, textInput) {
  if (decision) return textInput
  return ''
}

export const mappingCaseKeeper = (app, insuredOrPayer, isReuse = false, remoteSelling = false) => {
  const insuredInfo = _getOr({}, ['quickQuote', 'insured'], app)
  const payerInfo = _getOr({}, ['quickQuote', 'insured', 'payer'], app)
  const sendLinkEkycDetail = _getOr({}, ['ekycInfo', 'sendLink'], app)
  const title = _getOr('other', [insuredOrPayer, 'title', 'value'], app)

  const mapPersonalInfo = {
    insured: { ...insuredInfo, esub: _getOr({}, ['insured'], app) },
    payer: { ...payerInfo, esub: _getOr({}, ['payer'], app) },
  }
  const defaultProcessConfig = {
    required: true,
    attempts: 3,
    dependenciesRequired: false,
    livenessCount: 1,
  }

  const remoteSellingState = getRemoteSellingState(app)
  const { method, textInput, notifyType } = getSendingMethodDetail(
    remoteSellingState,
    sendLinkEkycDetail,
    isReuse,
    insuredOrPayer
  )

  const proprietorsObject = {
    firstName: _getOr('', [insuredOrPayer, 'esub', 'firstName'], mapPersonalInfo),
    lastName: _getOr('', [insuredOrPayer, 'esub', 'lastName'], mapPersonalInfo),
    proprietorType: insuredOrPayer,
    ...notifyType,
    countryCode: 'TH',
    citizenId: _getOr('', [insuredOrPayer, 'esub', 'idNo', 'citizenId'], mapPersonalInfo),
    phoneNumber: getTextInput(method === 'sms', textInput),
    email: getTextInput(method === 'email', textInput),
    title: titleEkyc[title],

    frontIdCardConfig: {
      ...defaultProcessConfig,
      threshHold: 0.8,
      isEditable: true,
    },
    backIdCardConfig: {
      ...defaultProcessConfig,
      threshHold: 0.95,
      isEditable: true,
    },
    dopaConfig: {
      ...defaultProcessConfig,
      threshHold: 1.0,
      isEditable: false,
      dependenciesRequired: true,
    },
    expiryDuration: 30,
    expiresAt: moment()
      .add(30, 'days')
      .format(),
  }

  if (remoteSelling) {
    Object.assign(proprietorsObject, {
      idFaceRecognitionConfig: {
        ...defaultProcessConfig,
        threshHold: 0.9,
      },
      inviteType: method,
      notifyType: undefined,
      notifyDuration: undefined,
      notifyInterval: undefined,
      meta: {
        insuredTitle: {
          key: '005',
          code: '005',
          sex: 'M',
          translations: {
            en: {
              label: 'Mr.',
            },
            th: {
              label: 'นาย',
            },
            id: {
              label: 'Mr.',
            },
          },
          value: '005',
          label: 'Mr.',
        },
        insuredLastName: _getOr('', ['insured', 'esub', 'lastName'], mapPersonalInfo),
        insuredFirstName: _getOr('', ['insured', 'esub', 'firstName'], mapPersonalInfo),
        insuredMiddleName: null,
      },
    })
  }

  const caseKeeperObject = {
    caseType: {
      key: 'default',
      code: '000',
      translations: {
        en: {
          label: 'Default',
        },
        th: {
          label: 'Default',
        },
        id: {
          label: 'Default',
        },
      },
    },
    proprietors: [proprietorsObject],
    remark: '',
    attachMeetingRoom: false,
    appNumber: '',
    policies: [
      {
        policyNumber: '',
      },
    ],
    department: '/new-business',
  }
  return caseKeeperObject
}

export const resendCasePayload = (app, currentProprietorId) => {
  const sendLinkEkycDetail = _getOr({}, ['ekycInfo', 'sendLink'], app)
  const method = _getOr('', ['method'], sendLinkEkycDetail)
  const data = _getOr('', ['textInput'], sendLinkEkycDetail)

  return {
    proprietorId: currentProprietorId,
    data: {
      method,
      email: method === 'email' ? data : '',
      sms: method === 'sms' ? data : '',
    },
    type: isSelectedRemoteSelling(app) ? 'invite' : 'notification',
  }
}

export const replaceSendingDetailForResendRemoteSellingCasePayload = (remoteSelling, payload) => {
  try {
    if (!remoteSelling) {
      return {}
    }
    const { sendingMethod, email, sms } = remoteSelling

    return {
      ...payload,
      type : 'invite',
      data: {
        method : sendingMethod,
        email: sendingMethod === 'email' ? email : '',
        sms: sendingMethod === 'sms' ? sms : '',
      }
    }
  } catch (e) {
    console.error(``)
  }
}

export const setAttachmentPayload = (insuredOrPayer, app, url, name, type = 'image/jpeg') => {
  const attachmentId =
    name === 'id-card'
      ? insuredOrPayer === 'insured'
        ? `insured-${name}`
        : `owner-${name}`
      : `${insuredOrPayer}-${name}`
  const applicationId = _getOr('', 'applicationId', app)

  return {
    attachmentId,
    applicationId,
    url,
    type,
  }
}
