import moment from 'moment'
import {
  assign,
  concat,
  uniqBy,
  isEmpty,
  isNil,
  reject,
  flow,
  map,
  get,
  getOr,
  toString,
  startsWith,
  toFinite,
  has,
  find,
} from 'lodash/fp'
import { christianToThaiWithoutFormat } from 'core/service/lib/date'
import { select, call, put, take, takeLatest, race } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
  addError,
  showErrorDialog,
  showSubmitPaymentDialog,
  hideDialog,
  fetchSignature,
  setAppValue,
  unsetAppValue,
  saveAppData,
  showLoading,
  hideLoading,
  deletePhoto,
  createNewEda,
  startEDACheckingStatus,
  stopEDACheckingStatus,
  saveEcbrPhoto,
  setShowEcbrSystemizationFailedModal,
  unsetEDACheckStatus,
  validateApplication,
  resetValidation,
  checkPaymentStatus,
} from 'e-submission/apps/actions'
import {
  getReceipts,
  getReceiptType,
  getPayment,
  getPolicyId,
  getReceiptAmount,
  getReceiptBank,
  getRecurringPayment,
  getReceiptTransactionId,
  getQuickQuoteProductName,
  getQuickQuoteProductCategory,
  getSelectedModelFactorLabel,
  isSelectedRemoteSelling,
  getClientCancelPayment,
  isFormTypeEDA,
  isCheckStatusEDA,
  getReceiptDocumentType,
  isPaymentTypeCreditCard,
  isHasPayerInfoEcbrNo,
  isPaymentTypeNetbank,
  getQRRef2,
  isEcbrStatusCreated,
  getBypassPayingCounter,
  getBankCode,
} from 'e-submission/domain/data-model/form/selectors'
import { documentEcbrNewName } from 'e-submission/utils/documents'
import { getCurrentApp, getGA4ccpaSuccess, getRelationshipListForNewATP } from 'e-submission/apps/selectors'
import { getInsuredCitizenId, getInsuredPassport, getInsuredIdType } from 'quick-quote/insured-information/selectors'
import { confirmDialog, confirmDialogEDA } from './confirm'
import { paymentLinkToCustomer, paymentLinkToCustomerCCPA } from './policy-payload/quickpay'
import { getIdentityUser } from 'identity/selectors'
import { quickPayStatus, ccpaStatus, createDialogPropsForBypassToManuallyAttachSlip } from 'e-submission/sagas/utils'
import { getToggles } from 'quick-quote/feature-toggles'
import {
  convertMomentDateToObject,
  getATPDefaultRelation,
  attachPaidPaymentIntoReceipts,
  receiptWithPaid,
  checkAppReceiptHaveMatchUnsigned,
  cloneDataUnsignedPaymentReceiptToNetbank,
  cloneDataReceiptPaidForQuickpayError,
  cloneDataUnsignedPaymentReceiptToCreditCard,
  getPaymentActualAmount,
  getProviderFromUnsignPayment,
  getReceiptsWithAmountAndType,
} from './utils'
import { getAppConfig } from 'deploy-env/app-config'
import df2f, { DF2F } from 'e-submission/utils/bridging/df2f'
import { uploadDocumentFromUrl } from './remoteSelling'
import { analytics } from 'analytics/analytics'
import { IsRealDevice } from 'e-submission/utils'
import { CREDIT_CARD_PAYMENT_METHOD, PAYMENT_TYPE } from 'e-submission/domain/data-model/constants'

const mapWithKey = map.convert({ cap: false })
const concatWith = concat.convert({ rearg: true })

const cybersourceActionData = { action: '' }

export const onListPayment = function*(service) {
  const { listPayment, listReceipts, listCybersourceReceipts } = service
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)

  const isNewListReceiptsResponse = has('isPaymentError')
  try {
    let listReceiptsResponse = yield call(
      getToggles().ENABLE_CYBERSOURCE ? listCybersourceReceipts : listReceipts,
      user,
      policyId
    )
    // TODO: remove this condition after TOGGLE_CHECK_OMISE_ON_LIST_RECEIPTS in middleware is enabled on PROD
    let serverReceipts = isNewListReceiptsResponse(listReceiptsResponse)
      ? listReceiptsResponse.receipts
      : listReceiptsResponse
    let isPaymentError = isNewListReceiptsResponse(listReceiptsResponse) ? listReceiptsResponse.isPaymentError : false

    if (isPaymentError) {
      console.error('failedPayment')
      yield put(showErrorDialog('failedPayment'))
    }

    if (!isEmpty(serverReceipts)) {
      const payment = yield call(listPayment, user, policyId)

      serverReceipts = flow(map(assign({ signed: true })), concatWith(serverReceipts), uniqBy('receiptNo'))(payment)
    }
  } catch (err) {
    console.error(err)
    window.history.back()
    yield put(showErrorDialog('failed'))
  }
}

export const onRemovePaymentReceipt = function*(service, action) {
  const { removeReceipt } = service

  const receiptNoList = action.payload
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)

  try {
    yield put.resolve(showLoading())

    const policyId = getPolicyId(app)

    const params = {
      policyId,
      receiptNoList,
    }

    yield call(removeReceipt, user, params)

    yield [put(setAppValue('isEvidenceUploaded', false))]
    localStorage.removeItem('payerInfoImage')
  } catch (err) {
    console.error(err)
  } finally {
    yield put(hideLoading())
  }
}

export const checkQrPaying = function*(service, action) {
  const { checkQrPaying } = service
  const {
    creditCardPaymentMethod,
    isRemoteSelling,
    toggleQuickpayModal,
    toggleModal,
    isOnlyCheckQr,
    callbackCheckingQr,
    quickpayRefToken,
  } = action.payload
  const app = yield select(getCurrentApp)
  const user = yield select(getIdentityUser)
  let ref2 = getQRRef2(app)
  const responseCheckingQrPaying = yield call(checkQrPaying, user, {
    ref2,
  })

  if (
    getToggles().ENABLE_QR_PAYMENT_STATUS &&
    responseCheckingQrPaying.messageCode === 'paid success' &&
    !isOnlyCheckQr
  ) {
    yield call(
      confirmDialog,
      {
        title: 'ผลการตรวจสอบการชำระเงิน',
        body: `<p>ท่านได้มีการชำระเงินผ่าน QR Code เรียบร้อยแล้ว <br> กรุณาทำรายการต่อในเมนู QR Code</p>`,
      },
      { onlyConfirm: true }
    )
    return false
  }

  if (isOnlyCheckQr) {
    if (responseCheckingQrPaying.messageCode === 'paid unsuccess') {
      yield call(
        confirmDialog,
        {
          title: 'ผลการตรวจสอบการชำระเงิน',
          body: `ตรวจสอบไม่พบรายการชำระเงินกรุณากดยืนยันเพื่อกลับสู่หน้าชำระเงินอีกครั้ง</p>`,
        },
        { onlyConfirm: true }
      )
    } else {
      if (callbackCheckingQr) callbackCheckingQr()
    }
    return true
  }

  if (isRemoteSelling) {
    yield put(setAppValue('creditCardMethod', CREDIT_CARD_PAYMENT_METHOD.ON_AZ))
  }

  if (creditCardPaymentMethod === CREDIT_CARD_PAYMENT_METHOD.QUICKLINK) {
    if (!_.isUndefined(quickpayRefToken) && quickpayRefToken) {
      yield put(checkPaymentStatus(toggleQuickpayModal))
    } else {
      toggleQuickpayModal()
    }
  } else {
    yield put(resetValidation())
    toggleModal()
  }
}

export const updateQrPaymentToCheckedStatus = function*(service, isBypassCheckCreatedStatus) {
  const internalServerErrorObjectModal = {
    title: 'ระบบไม่สามารถออกใบรับเงินชั่วคราวได้',
    body: `<p>ขออภัยในความไม่สะดวก <br> ขณะนี้ระบบไม่สามารถดำเนินการออกใบรับเงินชั่วคราวได้ กรุณาลองใหม่อีกครั้ง</p>`,
  }
  try {
    const { checkPaidQrPaymentStatus, createEcbrFiles } = service

    const app = yield select(getCurrentApp)
    const policyId = getPolicyId(app)
    const user = yield select(getIdentityUser)

    let receiptsWhenPaid = getReceipts(app)
    let bankCode = getBankCode(receiptsWhenPaid[3])
    let ref2 = receiptsWhenPaid[3]?.ref2
    const amountApp = getPaymentActualAmount(receiptsWhenPaid[3])
    const isBypassPayingCounter = getBypassPayingCounter(app)

    const response = yield call(checkPaidQrPaymentStatus, user, {
      policyId,
      bankCode,
      ref2,
      amount: amountApp,
    })

    const typeOfPayment = 'net bank'
    if (response.messageCode === 'paid unsuccess') {
      let counter = +localStorage.getItem('retryOfCheckQrPayment')
      const dialogProps = {
        onlyConfirm: true,
      }
      let body = `ตรวจสอบไม่พบรายการชำระเงิน กรุณากดยืนยันเพื่อกลับสู่หน้าชำระเงินอีกครั้ง</p>`
      if (counter === 4) {
        dialogProps.confirmText = 'ยืนยัน'
        dialogProps.confirmYesWithAction = 'BYPASS_PAYING_COUNTER_TO_SIGNING_PAGE'
        body = `ตรวจสอบไม่พบรายการชำระเงิน กรุณากดยืนยันเพื่อดำเนินการแนบหลักฐานการชำระเงิน</p>`
        localStorage.setItem('retryOfCheckQrPayment', 1)
      } else {
        localStorage.setItem('retryOfCheckQrPayment', ++counter)
      }
      yield put(hideLoading())
      yield call(
        confirmDialog,
        {
          title: 'ผลการตรวจสอบการชำระเงิน',
          body,
        },
        dialogProps
      )
      return false
    } else if (response.message === 'Internal Server Error') {
      yield put(hideLoading())
      yield call(confirmDialog, internalServerErrorObjectModal, { onlyConfirm: true })
      return false
    } else if (response.ecbrStatus === 'USED') {
      receiptsWhenPaid = attachPaidPaymentIntoReceipts(response, receiptsWhenPaid, typeOfPayment)
      yield call(createEcbrFiles, user, {
        policyId,
        ecbrNo: response.ecbrNo,
        bankCode: getBankCode(receiptsWhenPaid[3]),
        ref2,
        bypassPayingCounter: isBypassPayingCounter ? true : false,
      })
    } else if (response.ecbrStatus === 'CREATED') {
      yield put(hideLoading())
      if (isBypassCheckCreatedStatus) {
        receiptsWhenPaid[3].bypassPayingCounter = isBypassPayingCounter ? true : false
        return attachPaidPaymentIntoReceipts(response, receiptsWhenPaid, typeOfPayment, false)
      }
      const confirmed = yield call(
        confirmDialog,
        {
          title: 'ผลการตรวจสอบการชำระเงิน',
          body: `<p>ขณะนี้ระบบไม่สามารถตรวจสอบการชำระเงินได้ <br> กรุณาแนบหลักฐานการชำระเงินด้วยตนเอง</p>`,
        },
        { onlyConfirm: true, confirmText: 'แนบสลิป' }
      )
      if (confirmed) {
        receiptsWhenPaid = attachPaidPaymentIntoReceipts(response, receiptsWhenPaid, typeOfPayment, false)
      }
    }
    console.info('SUCCESS - updateQrPaymentToCheckedStatus receiptsWhenPaid', receiptsWhenPaid)
    return receiptsWhenPaid
  } catch (e) {
    console.error(`ERROR updateQrPaymentToCheckedStatus checkPaidQrPaymentStatus`, e.code)
    yield put({
      type: 'CHECK_PAYMENT_STATUS_SERVER_ERROR',
    })
    yield put(hideLoading())
  } finally {
    //yield put(hideLoading())
  }
}

export const onCreateReceipt = function*(service, action) {
  console.info('CONTINUE CREATE RECEIPT')
  const {
    createReceipt,
    checkExistUnsignedPaymentReceipt,
    removeReceipt,
    submitCreateEcbrNo,
    updateUnsignReceipt,
  } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const checkIsCreditCard = isPaymentTypeCreditCard(app)
  const isBypassPayingCounter = getBypassPayingCounter(app)

  try {
    yield put.resolve(showLoading())

    const policyId = getPolicyId(app)

    let receiptNo = ''
    if (getToggles().ENABLE_ECBR_SYSTEMIZATION && checkIsCreditCard) {
      const payload = {
        policyId,
      }
      const payerInfos = yield call(submitCreateEcbrNo, user, payload)
      if (payerInfos) {
        yield put(setAppValue('payerInfo', payerInfos?.payerInfo)) // Use Middleware update
        receiptNo = payerInfos?.payerInfo?.ecbrNo
      } else {
        return
      }
    }

    const receipts = flow(
      getReceipts,
      mapWithKey((receipt, key) => {
        let response = {
          ...receipt,
          key,
          amount: toString(getReceiptAmount(receipt)),
          type: getReceiptType(receipt),
          transactionId: getReceiptTransactionId(receipt),
          currency: 'thb',
          receiptNo,
        }
        return response
      }),
      reject(({ amount }) => toFinite(amount) === 0),
      reject(({ type }) => isNil(type))
    )(app)

    let payment = {
      policyId,
      receipts,
    }

    if (getToggles().ENABLE_ECBR_SYSTEMIZATION && checkIsCreditCard) {
      // const receiptWithReciptNo = payment?.receipts?.map((item) => Object.assign({}, item, { receiptNo: receiptNo }))
      const defaultReceiptNo = [{}]
      const receiptWithReciptNo = payment?.receipts
        ? payment?.receipts.map((item) => Object.assign({}, item, { receiptNo: receiptNo }))
        : defaultReceiptNo

      payment = {
        policyId,
        receipts: [receiptWithReciptNo[0]],
      }
    }

    let receiptsWhenPaid = getReceipts(app)
    const unsignedPaymentReceipt = yield call(checkExistUnsignedPaymentReceipt, user, payment) // NOTED: Used response to save data to application.receipt to resolve issues Dec 2025
    let cloneAppReceiptByType = []
    if (unsignedPaymentReceipt) {
      const unsignedPaymentReceiptType = unsignedPaymentReceipt?.type

      const receiptPaid = receiptWithPaid(receiptsWhenPaid)
      cloneAppReceiptByType = checkAppReceiptHaveMatchUnsigned(unsignedPaymentReceipt, receiptPaid) // RETURN OBJECT
      const noPaidReceipt = receiptsWhenPaid.every(function(receipt) {
        return !receipt?.paid
      })

      const findUnsignedPaymentDataInApplicationReceipts = receiptsWhenPaid.some(function(receipts) {
        return receipts?.type === unsignedPaymentReceiptType && receipts?.amount && receipts?.paid
      })

      if (noPaidReceipt || !findUnsignedPaymentDataInApplicationReceipts) {
        const params = {
          policyId,
        }
        yield call(removeReceipt, user, params)
        yield delay(4000)
      }
    }

    if (getToggles().ENABLE_QR_PAYMENT_STATUS && isPaymentTypeNetbank(app)) {
      const paidPayment = yield call(updateQrPaymentToCheckedStatus, service)
      if (!paidPayment) {
        yield put(hideLoading())
        return // EXIT FUNCTION CREATE_RECEIPT
      }
      yield delay(2000)
      const receiptPaid = receiptWithPaid(paidPayment) // ADD HERE

      if (receiptPaid?.receiptNo && receiptPaid?.ecbrStatus !== 'CREATED') {
        yield call(updateUnsignReceipt, user, {
          ...receiptPaid,
          policyId,
          bypassPayingCounter: isBypassPayingCounter ? true : false,
        })

        paidPayment[3] = {
          ...paidPayment[3],
          bankCode: receiptPaid?.bankCode,
          bypassPayingCounter: isBypassPayingCounter ? true : false,
          isCloned: true,
        }
        receiptsWhenPaid = paidPayment
        yield put(setAppValue('receipts', receiptsWhenPaid)) // ISSUES
        yield delay(4000)
        yield put(hideLoading())
        return // EXIT FUNCTION CREATE_RECEIPT
      }
    }
    const response = yield call(createReceipt, user, payment)
    const responseReceiptType = get('[0].type', response)
    console.info('CREATE RECRIPT - RESPONSE RECEIPT TYPE', responseReceiptType)
    if (!_.isEmpty(cloneAppReceiptByType) && cloneAppReceiptByType) {
      receiptsWhenPaid = receiptsWhenPaid.map((receipt) => {
        if (receipt?.paid && receipt?.type && receipt?.type === PAYMENT_TYPE.NETBANK) {
          // NETBANK
          const objectCloneNetbank = cloneDataUnsignedPaymentReceiptToNetbank(cloneAppReceiptByType) // RETURN OBJECT
          console.info('START -- CREATE-RECRIPT CONTINUE CLONE DATA NETBANK', objectCloneNetbank)
          return {
            ...receipts[0],
            receiptNo: receiptNo ? receiptNo : get('[0].receiptNo', response),
            ...objectCloneNetbank,
          }
        } else if (receipt?.paid && receipt?.type && receipt?.type === PAYMENT_TYPE.CREDITCARD) {
          // CREDIT CARD
          const objectCloneCreditCard = cloneDataUnsignedPaymentReceiptToCreditCard(cloneAppReceiptByType) // RETURN OBJECT
          console.info('START -- CREATE-RECRIPT CONTINUE CLONE DATA CREDIT CARD', objectCloneCreditCard)
          return {
            ...receipts[0],
            receiptNo: receiptNo ? receiptNo : get('[0].receiptNo', response),
            ...objectCloneCreditCard,
          }
        } else {
          return receipt
        }
      })
      console.info('CREATE RECRIPT - RECEIPTWHENPAID', receiptsWhenPaid)
    } else {
      receiptsWhenPaid = receiptsWhenPaid.map((receipt) => {
        console.info('OLD JOUNEY SAVE RECEIPTS END', receiptsWhenPaid)
        return receipt?.type === responseReceiptType
          ? {
              ...receipts[0],
              ...receipt,
              totalFirstPremiumPayment: get('[0].amount', response),
              receiptNo: get('[0].receiptNo', response),
              oldReceiptNo: get('[0].oldReceiptNo', response),
              maskCreditCardNumber: get('[0].maskCreditCardNumber', response),
              paid: true,
            }
          : receipt
      })
    }

    yield put(setAppValue('receipts', receiptsWhenPaid)) // ISSUES
    yield delay(4000)
    yield put(hideLoading())
  } catch (err) {
    console.error(err)
    yield put(setAppValue('payerInfo.status', 'error'))
    yield put(hideLoading())
  } finally {
  }
}

export const onUpdateReceipt = function*(service, action) {
  console.info('CONTINUE UPDATE RECEIPT')
  const { updateReceipt } = service

  const id = action.id
  const name = action.name
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)

  try {
    yield put.resolve(showLoading())

    const receipt = get(id, app)
    const policyId = getPolicyId(app)
    const bank = getReceiptBank(receipt)

    const params = {
      ...receipt,
      bank,
      policyId,
    }

    yield call(updateReceipt, user, params)

    yield [put(setAppValue('isEvidenceUploaded', true))]
  } catch (err) {
    yield put(deletePhoto(name))
    console.error(err)
  } finally {
    yield put(hideLoading())
  }
}

export const onUpdateUnsignReceipt = function*(service, action) {
  console.info('UPDATE UNSIGN PAYMENT RECEIPT')
  const { updateUnsignReceipt, submitCreateEcbrNo, checkExistUnsignedPaymentReceipt } = service

  const id = action.id
  const name = action.name
  const isUploaded = action.isUploaded

  if (!isUploaded) {
    yield put(validateApplication())
    return
  }

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)

  if (getToggles().ENABLE_QR_PAYMENT_STATUS && isPaymentTypeNetbank(app) && !isEcbrStatusCreated(app)) {
    const { checkQrPaying } = service
    let ref2 = getQRRef2(app)

    const responseCheckingQrPaying = yield call(checkQrPaying, user, {
      ref2,
    })

    if (responseCheckingQrPaying.messageCode === 'paid unsuccess') {
      yield call(
        confirmDialog,
        {
          title: 'ผลการตรวจสอบการชำระเงิน',
          body: `<p>ตรวจสอบไม่พบรายการชำระเงินกรุณากดยืนยันเพื่อกลับสู่หน้าชำระเงินอีกครั้ง</p>`,
        },
        { onlyConfirm: true }
      )
      return false
    }
  }

  try {
    yield put.resolve(showLoading())

    const receipt = get(id, app)
    const policyId = getPolicyId(app)
    const bank = getReceiptBank(receipt)
    const isBypassPayingCounter = getBypassPayingCounter(app)
    yield [put(saveAppData()), take('SAVE_APPLICATION_SUCCESS')] // Waiting EDC payment

    let receiptNo = '',
      isCreateEcbrNo = ''
    if (getToggles().ENABLE_ECBR_SYSTEMIZATION && !isHasPayerInfoEcbrNo(app)) {
      const payload = {
        policyId,
      }

      if (receipt && receipt?.qrStatusChecked === false && isPaymentTypeCreditCard(app)) {
        payload.checkPaymentFlag = 'N'
      }

      if (isBypassPayingCounter) {
        payload.paidFromCounter = true
      }
      payload.bankCode = getBankCode(receipt)
      const payerInfos = yield call(submitCreateEcbrNo, user, payload)
      if (payerInfos) {
        yield put(setAppValue('payerInfo', payerInfos?.payerInfo)) // Use Middleware update
        receiptNo = payerInfos?.payerInfo?.ecbrNo
        isCreateEcbrNo = payerInfos?.payerInfo?.ecbrNo ? true : false
      }

      const payerInfosEcbrNo = payerInfos?.payerInfo?.ecbrNo ? payerInfos?.payerInfo?.ecbrNo : null
      const payerInfosOldReceiptNo = payerInfos?.payerInfo?.oldReceiptNo ? payerInfos?.payerInfo?.oldReceiptNo : null

      // START CLONE 2025
      let receiptsWhenPaid = getReceipts(app)
      const receiptPaid = receiptWithPaid(receiptsWhenPaid)

      if (isPaymentTypeNetbank(app)) {
        if (isBypassPayingCounter) {
          if (receiptPaid[3]?.bypassPayingCounter && !_.isEmpty(receiptPaid[3].bypassPayingCounter)) {
            receiptPaid[3].bypassPayingCounter =
              !_.isUndefined(isBypassPayingCounter) || !_.isEmpty(isBypassPayingCounter) ? true : false
          }
        }
      }
      console.info('STATUS - UPDATE UNSIGNED receiptPaid', receiptPaid)
      let payment = {
        policyId,
        paymentId: receiptPaid?.receiptNo,
      }
      const params = {
        ...receiptPaid,
        receiptNo: receiptNo,
        policyId,
        bypassPayingCounter: isBypassPayingCounter ? true : false,
      }
      yield call(updateUnsignReceipt, user, params)
      console.info('ECBR-SYS UPDATE UNSIGNED RECEIPT', params)

      const unsignedPaymentReceipt = yield call(checkExistUnsignedPaymentReceipt, user, payment) // NOTED: Used response to save data to application.receipt to resolve issues Dec 2025
      yield delay(1000)
      console.info('STATUS - UPDATE UNSIGNED unsignedPaymentReceipt', unsignedPaymentReceipt)
      const cloneAppReceiptByType = checkAppReceiptHaveMatchUnsigned(unsignedPaymentReceipt, receiptPaid) // RETURN OBJECT
      console.info('SUCCESS - UPDATE UNSIGNED cloneAppReceiptByType', cloneAppReceiptByType)

      if (!_.isEmpty(cloneAppReceiptByType) && cloneAppReceiptByType) {
        receiptsWhenPaid = receiptsWhenPaid.map((receipt) => {
          const amountApp = getPaymentActualAmount(receipt)

          if (receipt && receipt?.qrStatusChecked === false && isPaymentTypeCreditCard(app)) {
            // Verify quickpay | Check quickpay eror
            const objectClonedQuickPay = cloneDataReceiptPaidForQuickpayError(receiptPaid) // RETURN OBJECT
            console.info('START -- UPDATE UNSIGNED CLONE DATA QUICK PAY', objectClonedQuickPay)
            return {
              bankCode: getBankCode(receiptsWhenPaid[3]),
              ref2: payment?.ref2,
              ...objectClonedQuickPay,
            }
          } else {
            if (receipt?.paid && receipt?.type && receipt?.type === PAYMENT_TYPE.NETBANK) {
              // NETBANK
              const objectCloneNetbank = cloneDataUnsignedPaymentReceiptToNetbank(cloneAppReceiptByType) // RETURN OBJECT
              console.info('START -- UPDATE UNSIGNED CLONE DATA NETBANK', objectCloneNetbank)
              return {
                ...receipt,
                receiptNo: receiptNo,
                ...objectCloneNetbank,
              }
            } else if (receipt?.paid && receipt?.type && receipt?.type === PAYMENT_TYPE.CREDITCARD) {
              // CREDIT CARD
              const objectCloneCreditCard = cloneDataUnsignedPaymentReceiptToCreditCard(cloneAppReceiptByType) // RETURN OBJECT
              console.info('START -- UPDATE UNSIGNED CLONE DATA CREDIT CARD', objectCloneCreditCard)
              return {
                receiptNo: receiptNo,
                ...objectCloneCreditCard,
              }
            } else {
              return receipt
            }
          }
        })
      }
      // EBD CLONE 2025

      // NOT ALLOW FOR CREDIT CARD
      if (payerInfosOldReceiptNo && payerInfosOldReceiptNo !== receiptPaid?.receiptNo) {
        const documentType = getReceiptDocumentType(receiptPaid)
        const documentTypeName = documentEcbrNewName(documentType.name, payerInfosEcbrNo)
        const oldDocumentTypeName = documentEcbrNewName(documentType.name, payerInfosOldReceiptNo)
        yield put(saveEcbrPhoto(oldDocumentTypeName, documentTypeName))
        yield take('ECBR_PHOTO_SUCCESS')
      }
      yield [put(setAppValue('receipts', receiptsWhenPaid)), take('SAVE_APPLICATION_SUCCESS')] // ISSUES
      console.info('UPDATE UNSIGNED RECEIPT -- SUCCESS', receiptsWhenPaid)
    } else {
      const params = {
        ...receipt,
        bank,
        policyId,
        bypassPayingCounter: isBypassPayingCounter ? true : false,
      }
      console.info('OLD JOURNEY UPDATE UNSIGNED RECEIPT', params)
      yield call(updateUnsignReceipt, user, params)
    }
    yield [put(setAppValue('isEvidenceUploaded', true))]
  } catch (err) {
    yield put(deletePhoto(name))
    console.error(err)
    yield put(unsetAppValue('submitWithoutPayment.selected'))
    yield put(setAppValue('payerInfo.status', 'error'))
    yield put(setShowEcbrSystemizationFailedModal())
  } finally {
    yield put(hideLoading())
  }
}

export const onUpdateRecurringPayment = function*(service, action) {
  const { updateRecurringPayment } = service

  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const isEDA = isFormTypeEDA(app)

  try {
    yield put.resolve(showLoading())

    const payment = getPayment(app)
    const recurringPayment = getRecurringPayment(app)
    const policyId = getPolicyId(app)

    const params = {
      ...payment,
      ...recurringPayment,
      type: 'cash',
      policyId,
    }

    yield call(updateRecurringPayment, user, params)
    if (action.isEdit) {
      yield [put(fetchSignature()), take('HIDE_LOADING')]
    } else {
      if (getToggles().ENABLE_EDA && isEDA) {
        const newEDAPayload = {
          accountNo: get('bankBookNumber', recurringPayment),
          policyNo: policyId,
          bankName: 'KTB', // only KTB for now
          applyDtm: new Date().getTime(),
        }
        yield put(createNewEda(newEDAPayload))
        yield put(unsetEDACheckStatus())
      } else {
        yield put(setAppValue('ATP.paid', true))
      }
    }
  } catch (err) {
    console.error(err)
  } finally {
    if (getToggles().ENABLE_EDA) {
      yield put(saveAppData())
    }

    // if EDA is not enabled or the payment form is not EDA form, hide loading as before
    if (!getToggles().ENABLE_EDA || !isEDA) {
      yield put(hideLoading())
    }
  }
}

export const onSubmitRecurringPayment = function*(service, history, action) {
  const { submitRecurringPayment } = service
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)

  try {
    yield put.resolve(showLoading())

    const policyId = getPolicyId(app)

    const params = {
      policyId,
    }
    yield call(submitRecurringPayment, user, params)
    yield put(setAppValue('ATP.submitted', true))
  } catch (err) {
    console.error(err)
  } finally {
    const recurringPayment = getRecurringPayment(app)

    const isTypeEDA = getToggles().ENABLE_EDA && get('typeDA', recurringPayment) === 'ONLINE'
    history.replace(history.location.pathname.replace(isTypeEDA ? '/eda' : '/atp', ''))

    yield put(hideLoading())
  }
}

export const onSetDefaultReceiptValue = function*({ fieldId, value }) {
  yield take('LOAD_APP')

  yield put(setAppValue(fieldId, value))
}

export const onSubmitCreditCard = function*(service, action) {
  const { initOmise, getCreditCardPaymentOmiseToken, chargeCreditCard, retrieveCreditCard } = service
  try {
    yield put(showSubmitPaymentDialog({ status: 'loading' }))

    yield call(initOmise)

    const token = yield call(getCreditCardPaymentOmiseToken, action.payload)

    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)

    const policyId = getPolicyId(app)
    const response = yield call(chargeCreditCard, user, token, action.payload.amount, policyId)

    if (window.cordova) {
      const otpWindow = window.cordova.InAppBrowser.open(response.authorize_uri, '_blank', 'location=no')
      while (true) {
        try {
          yield otpWindow.addEventListener('loadstart', (event) => {
            if (event.url.indexOf('return-otp') > 0) {
              otpWindow.close()
            }
          })

          const waitOTPWindow = (eventName) =>
            new Promise((resolve) => {
              otpWindow.addEventListener(eventName, (event) => {
                resolve(event)
              })
            })

          yield call(waitOTPWindow, 'exit')
          const transaction = yield call(retrieveCreditCard, user, response.charge_id, policyId)
          if (transaction.status === 'successful') {
            yield put(showSubmitPaymentDialog({ status: 'success', payment: response }))
            yield put(setAppValue(`${action.payload.id}.transactionId`, transaction.transaction))
            yield put(setAppValue(`${action.payload.id}.approvalCode`, transaction.metadata.approvalCode))
            yield put(setAppValue(`${action.payload.id}.paid`, true))
            otpWindow.window.close()
            break
          } else {
            yield put(hideDialog())
            yield put(setAppValue(`${action.payload.id}.paid`, false))
            yield put(addError('OMISE_PAYMENT', getOr(null, 'failure_code', response)))
            otpWindow.window.close()
            break
          }
        } catch (e) {
          // ignore the redirecting from OTP-payment
        }

        yield delay(600)
      }
    } else {
      const otpWindow = yield window.open(response.authorize_uri, '_blank')

      while (true) {
        try {
          if (otpWindow.window && otpWindow.window.location.href.indexOf('return-otp') > 0) {
            otpWindow.window.close()
          }
          if (otpWindow?.closed) {
            const transaction = yield call(retrieveCreditCard, user, response.charge_id, policyId)
            if (transaction.status === 'successful') {
              yield put(showSubmitPaymentDialog({ status: 'success', payment: response }))
              yield put(setAppValue(`${action.payload.id}.transactionId`, transaction.transaction))
              yield put(setAppValue(`${action.payload.id}.approvalCode`, transaction.metadata.approvalCode))
              yield put(setAppValue(`${action.payload.id}.paid`, true))

              break
            } else {
              yield put(hideDialog())
              yield put(setAppValue(`${action.payload.id}.paid`, false))
              yield put(addError('OMISE_PAYMENT', getOr(null, 'failure_code', response)))

              break
            }
          }
        } catch (e) {
          // ignore the redirecting from OTP-payment
          if (otpWindow === null) {
            yield put(hideDialog())
            yield put(addError('OMISE_PAYMENT', 'browser_popup_blocker'))

            break
          }
        }

        yield delay(600)
      }
    }
  } catch (err) {
    console.error(err)

    yield put(addError('OMISE_PAYMENT', getOr(null, 'code', err)))

    yield put(hideDialog())
  } finally {
  }
}

function handlePaymentPostMessage(event) {
  cybersourceActionData.action = event.data
  event.target.removeEventListener(event.type, handlePaymentPostMessage, true)
}

function* waitForPaymentWindowMessage() {
  window.addEventListener('message', handlePaymentPostMessage, true)
}

function* ifPaymentWindowClosed(paymentWindow) {
  while (true) {
    if (yield paymentWindow?.closed) {
      break
    }
    yield delay(4500)
  }
}

function findIdDocumentNumberByIdTypeValue(app) {
  const idTypeValue = getInsuredIdType(app)
  const idDocumentNumber = {
    PASSPORT: getInsuredPassport(app),
    CITIZEN_ID: getInsuredCitizenId(app),
  }

  return idDocumentNumber[idTypeValue] || ''
}

export const getCybsProductType = (app) => {
  const type = getQuickQuoteProductCategory(app)
  const typeMapping = {
    INVESTMENT: 'ILP',
  }
  return typeMapping[type] ? typeMapping[type] : 'Life'
}

export const onStartCybsPayment = function*(service, history, action) {
  const {
    getCybersourceTransactionControl,
    checkPaidPolicyNumber,
    signCybersourceCreditCard,
    retrieveCybersourceCreditCard,
    retrieveCybersourceCreditCardPaymentGateway,
    updateCybersourceStatus,
  } = service
  const { product, selectedModelFactorLabel, remoteSelling } = action.payload
  try {
    yield put(showSubmitPaymentDialog({ status: 'loading' }))

    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)

    const policyId = getPolicyId(app)
    const isAppmanRemoteSelling = isSelectedRemoteSelling(app)
    const receipt = get(action.payload.id, app)
    const amount = getReceiptAmount(receipt)
    const insured = {
      firstName: get('insured.firstName', app),
      lastName: get('insured.lastName', app),
      idDocumentNumber: findIdDocumentNumberByIdTypeValue(app),
    }

    const getStatus = getToggles().ENABLE_CYBERSOURCE_PHASE2
      ? retrieveCybersourceCreditCardPaymentGateway
      : retrieveCybersourceCreditCard

    const productType = getCybsProductType(app)

    if (getToggles().ENABLE_RECHECK_POLICY_IS_PAID) {
      const checkPaidPolicyNumberResponse = yield call(
        checkPaidPolicyNumber,
        user,
        policyId,
        amount,
        productType,
        insured
      )
      if (checkPaidPolicyNumberResponse.policyNumberHasPaid) {
        const transactionControlResult = yield call(getCybersourceTransactionControl, user, policyId)
        const { referenceNumber, profileId } = transactionControlResult
        const pollingData = { referenceNumber, policyId, profileId }
        if (referenceNumber && !isEmpty(referenceNumber)) {
          let transactionResult = yield call(getStatus, user, pollingData)
          const timeoutTransaction = {
            amount,
            currency: 'THB',
            status: '',
            referenceNumber,
            policyId,
            product,
            selectedModelFactorLabel,
            profileId,
          }
          yield* checkTransaction(transactionResult, timeoutTransaction)
          return
        }
      }
    }

    const signedResponse = yield call(
      signCybersourceCreditCard,
      user,
      policyId,
      amount,
      productType,
      insured,
      isAppmanRemoteSelling
    )
    const referenceNumber = signedResponse.reference_number
    const profileId = signedResponse.profile_id
    let paymentWindow
    let clientCancelPayment
    cybersourceActionData.action = ''

    if (window.cordova && !remoteSelling) {
      let hiddenFields
      const createElement = ([key, value]) => {
        hiddenFields += `<input type="hidden" name="${key}" value="${value}">`
      }
      Object.entries(signedResponse).forEach(createElement)

      const pageContent = `<html><head></head><body style="color:white"><form id="cybersourceForm" action="${
        getAppConfig().CYBERSOURCE_PAY_URL
      }" method="post">${hiddenFields}
      </form></body><script type="text/javascript">document.getElementById("cybersourceForm").submit();</script></html>`

      const pageContentUrl = `data:text/html;base64,${window.btoa(pageContent)}`
      paymentWindow = window.cordova.InAppBrowser.open(
        pageContentUrl,
        '_blank',
        'hidden=no,location=no,clearsessioncache=yes,clearcache=yes'
      )

      const waitWindowClose = (eventName) =>
        new Promise((resolve) => {
          paymentWindow.addEventListener(eventName, (event) => {
            resolve(event)
          })
        })

      yield paymentWindow.addEventListener('loadstart', (event) => {
        if (event.url.indexOf('axa') > 0) {
          cybersourceActionData.action = event.url.indexOf('cancel') > 0 ? 'cancel' : 'checkout'
          paymentWindow.close()
        }
      })

      yield call(waitWindowClose, 'exit')
    } else {
      if (remoteSelling) {
        const payloadData = {
          type: 'credit',
          data: {
            policyId,
            product,
            selectedModelFactorLabel,
            value: amount,
            amount,
            currency: 'บาท',
          },
          uploadConfig: {
            locale: 'th',
          },
          payload: {
            paymentUrl: getAppConfig().CYBERSOURCE_PAY_URL,
            config: {
              ...signedResponse,
            },
          },
          documentIds: [],
        }
        yield put(showSubmitPaymentDialog({ status: 'loading' }))
        cybersourceActionData.action = 'checkout'
        while (true) {
          const { completed, timeout } = yield race({
            completed: call(df2f.sendEvent, DF2F.PAYMENT_EVENT, payloadData),
            // TODO: should be CYBERSOURCE_CLIENT_PAYMENT_TIMEOUT in UAT config
            // for some reasons it is not there
            timeout: delay(300000),
          })
          if (completed) {
            clientCancelPayment = true
            break
          }
          if (timeout) {
            clientCancelPayment = false
            break
          }
        }
      } else {
        const form = document.createElement('form')
        form.method = 'POST'
        form.action = getAppConfig().CYBERSOURCE_PAY_URL
        form.target = 'view'
        const createElement = ([key, value]) => {
          const input = document.createElement('input')
          input.setAttribute('type', 'hidden')
          input.setAttribute('name', key)
          input.setAttribute('value', value)
          form.appendChild(input)
        }

        Object.entries(signedResponse).forEach(createElement)

        document.body.appendChild(form)
        paymentWindow = window.open('', 'view')
        form.submit()
        yield* waitForPaymentWindowMessage()
        yield* ifPaymentWindowClosed(paymentWindow)
      }
    }

    const pollingData = { referenceNumber, policyId, profileId }
    if (cybersourceActionData.action === 'cancel' || cybersourceActionData.action === '') {
      if (getToggles().ENABLE_CYBERSOURCE_RETRY)
        yield call(updateCybersourceStatus, user, policyId, referenceNumber, 'cancel')

      yield put(hideDialog())
    } else {
      let transactionResult
      let retryCount = 0
      const maxCount = 10
      const startTime = Date.now()

      while (retryCount < maxCount && Date.now() - startTime < getAppConfig().CYBERSOURCE_POLLING_TIMEOUT) {
        try {
          yield delay(4000)
          if (getToggles().ENABLE_CYBERSOURCE_RETRY) {
            const { pollingResult, timeout } = yield race({
              pollingResult: call(getStatus, user, pollingData),
              timeout: delay(getAppConfig().CYBERSOURCE_POLLING_TIMEOUT),
            })

            if (timeout) {
              continue
            } else {
              transactionResult = pollingResult
            }
          } else {
            transactionResult = yield call(getStatus, user, pollingData)
          }
          if (transactionResult.retryFlag === 0 || cybersourceActionData.action !== 'checkout') {
            break
          }
        } catch (e) {
          console.error(e)
          if (paymentWindow === null) {
            yield put(hideDialog())
            break
          }
        }
        retryCount++
      }
      const timeoutTransaction = {
        amount,
        currency: 'THB',
        status: '',
        referenceNumber,
        policyId,
        product,
        selectedModelFactorLabel,
        profileId,
      }
      yield* checkTransaction(transactionResult, timeoutTransaction)
    }
    function* checkTransaction(transactionResult, timeoutTransaction) {
      const transaction =
        transactionResult && transactionResult.status !== 'initial'
          ? { ...transactionResult, amount: amount }
          : timeoutTransaction

      if (transaction.status === 'successful') {
        yield put(showSubmitPaymentDialog({ status: 'success', payment: transaction }))
        yield put(setAppValue(`${action.payload.id}.transactionId`, transaction.transactionId))
        yield put(setAppValue(`${action.payload.id}.approvalCode`, transaction.metadata.approvalCode))
        yield put(setAppValue(`${action.payload.id}.maskCreditCardNumber`, transaction.maskCreditCardNumber))
        yield put(setAppValue(`${action.payload.id}.paid`, true))
        if (isSelectedRemoteSelling(app)) {
          yield put(hideDialog())
          yield call(onCreateReceipt, service)
          history.replace(history.location.pathname.replace('/payment', ''))
        }
        // yield call(df2f.sendEvent, DF2F.PAYMENT_RESULT_EVENT, {
        //   success: true,
        // })
        yield put(hideDialog())
      } else if (
        (transaction.status === 'error' || transaction.status === 'decline') &&
        cybersourceActionData.action === 'checkout'
      ) {
        yield put(setAppValue(`${action.payload.id}.paid`, false))
        // yield call(df2f.sendEvent, DF2F.CANCEL_EVENT)
        yield put(showSubmitPaymentDialog({ status: transaction.status, payment: transaction }))
        yield put(setAppValue('clientCancelPayment', clientCancelPayment))
        if (clientCancelPayment) {
          // yield call(df2f.sendEvent, DF2F.PAYMENT_RESULT_EVENT, {
          //   success: false,
          // })
        }
      } else if (getToggles().ENABLE_CYBERSOURCE_RETRY) {
        const forceOverruled =
          transactionResult && transactionResult.forceOverruled ? transactionResult.forceOverruled : false
        yield put(setAppValue('cybsRetry', transaction.status === ''))
        yield put(setAppValue(`${action.payload.id}.paid`, false))
        // only hide dialog if status cancel or empty and agent must be timeout
        if (
          (transaction.status === 'cancel' || transaction.status === '' || transaction.status === 'initial') &&
          clientCancelPayment
        ) {
          yield put(hideDialog())
        } else {
          yield put(showSubmitPaymentDialog({ status: transaction.status, payment: transaction, forceOverruled }))
        }
        yield put(setAppValue('clientCancelPayment', clientCancelPayment))
        if (clientCancelPayment) {
          // yield call(df2f.sendEvent, DF2F.PAYMENT_RESULT_EVENT, {
          //   success: false,
          // })
        }
      } else {
        yield put(hideDialog())
      }
    }
  } catch (err) {
    console.error(err)
    yield put(hideDialog())
  }
}

export const onStartCybsRetry = function*(service, history, action) {
  const { retrieveCybersourceCreditCard, retrieveCybersourceCreditCardPaymentGateway } = service
  const { referenceNumber, profileId } = action.payload

  try {
    yield put(showSubmitPaymentDialog({ status: 'loading' }))

    const id = 'receipts[2]'
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)

    const policyId = getPolicyId(app)
    const receipt = get(id, app)
    const amount = getReceiptAmount(receipt)
    const product = getQuickQuoteProductName(app)
    const selectedModelFactorLabel = getSelectedModelFactorLabel(app)
    const isAppmanRemoteSelling = isSelectedRemoteSelling(app)

    let transactionResult
    let retryCount = 0
    const maxCount = 10
    const startTime = Date.now()
    const pollingData = { referenceNumber, policyId, profileId, isRetryFlow: true, isRetryFlagUpdated: true, amount }
    while (retryCount < maxCount && Date.now() - startTime < getAppConfig().CYBERSOURCE_RETRY_POLLING_TIMEOUT) {
      try {
        yield delay(3000)
        const getStatus = getToggles().ENABLE_CYBERSOURCE_PHASE2
          ? retrieveCybersourceCreditCardPaymentGateway
          : retrieveCybersourceCreditCard
        const { pollingResult, timeout } = yield race({
          pollingResult: call(getStatus, user, pollingData),
          timeout: delay(getAppConfig().CYBERSOURCE_RETRY_POLLING_TIMEOUT),
        })

        if (timeout) {
          continue
        } else {
          transactionResult = pollingResult
        }

        pollingData.isRetryFlagUpdated = false

        if (transactionResult.retryFlag === 0) {
          break
        }
      } catch (e) {
        console.error(e)
      }
      retryCount++
    }

    const timeoutTransaction = {
      amount,
      currency: 'THB',
      status: '',
      referenceNumber,
      policyId,
      product,
      selectedModelFactorLabel,
      profileId,
    }
    const transaction =
      transactionResult && transactionResult.status !== 'initial'
        ? { ...transactionResult, amount: amount }
        : timeoutTransaction

    if (transaction.status === 'successful') {
      yield put(showSubmitPaymentDialog({ status: 'success', payment: transaction }))
      yield put(setAppValue(`${id}.totalFirstPremiumPayment`, amount))
      yield put(setAppValue(`${id}.transactionId`, transaction.transactionId))
      yield put(setAppValue(`${id}.maskCreditCardNumber`, transaction.maskCreditCardNumber))
      yield put(setAppValue(`${id}.approvalCode`, transaction.metadata.approvalCode))
      yield put(setAppValue(`${id}.paid`, true))
      if (isSelectedRemoteSelling(app)) {
        yield call(onCreateReceipt, service)
        yield put(hideDialog())
        // yield call(df2f.sendEvent, DF2F.PAYMENT_RESULT_EVENT, {
        //   success: true,
        // })
      }
      yield put(hideDialog())
    } else if (transaction.status === 'error' || transaction.status === 'decline') {
      yield put(setAppValue(`${id}.paid`, false))
      yield put(showSubmitPaymentDialog({ status: transaction.status, payment: transaction }))
      if (isAppmanRemoteSelling) {
        action.payload.id = id
        action.payload.remoteSelling = true
        yield call(onStartCybsPayment, service, action)
      }
    } else if (getToggles().ENABLE_CYBERSOURCE_RETRY) {
      if (isEmpty(transactionResult) || transactionResult.status === 'cancel') {
        yield put(setAppValue('cybsRetryUntilCancel', true))
        yield put(setAppValue('cybsRetry', false))
        history.replace(history.location.pathname.replace('/payment', ''))
        yield put(hideDialog())
      } else {
        yield put(setAppValue('cybsRetryUntilCancel', false))
        yield put(setAppValue('cybsRetry', true))
        const forceOverruled =
          transactionResult && transactionResult.forceOverruled ? transactionResult.forceOverruled : false
        yield put(setAppValue(`${id}.paid`, false))
        yield put(showSubmitPaymentDialog({ status: transaction.status, payment: transaction, forceOverruled }))
      }
      if (isAppmanRemoteSelling) {
        action.payload.id = id
        action.payload.remoteSelling = true
        yield call(onStartCybsPayment, service, action)
      }
    } else {
      yield put(hideDialog())
    }
  } catch (err) {
    console.error(err)
    yield put(hideDialog())
  }
}

export const onStartCybsCheckRetry = function*(service, action) {
  const { getCybersourceTransactionControl } = service
  try {
    yield put(showSubmitPaymentDialog({ status: 'loading' }))
    const user = yield select(getIdentityUser)
    const app = yield select(getCurrentApp)
    const policyId = getPolicyId(app)
    const isClientCancelPayment = getClientCancelPayment(app)
    const transactionControlResult = yield call(getCybersourceTransactionControl, user, policyId)

    const id = 'receipts[2]'
    const product = getQuickQuoteProductName(app)
    const selectedModelFactorLabel = getSelectedModelFactorLabel(app)

    if (isEmpty(transactionControlResult)) {
      yield put(setAppValue('cybsRetry', false))
      yield put(hideDialog())
    } else {
      yield put(setAppValue('cybsRetry', true))
      const { referenceNumber, status, profileId, amount } = transactionControlResult
      const timeoutTransaction = {
        amount,
        currency: 'THB',
        status,
        referenceNumber,
        policyId,
        product,
        selectedModelFactorLabel,
        profileId,
      }

      const forceOverruled = transactionControlResult.forceOverruled ? transactionControlResult.forceOverruled : false
      yield put(setAppValue(`${id}.paid`, false))
      yield put(setAppValue(`${id}.totalFirstPremiumPayment`, amount))
      if (
        isClientCancelPayment ||
        timeoutTransaction.status === 'cancel' ||
        timeoutTransaction.status === '' ||
        timeoutTransaction.status === 'initial'
      ) {
        yield put(hideDialog())
      } else {
        yield put(
          showSubmitPaymentDialog({ status: timeoutTransaction.status, payment: timeoutTransaction, forceOverruled })
        )
      }
    }
  } catch (err) {
    console.error(err)
    yield put(hideDialog())
  } finally {
  }
}

export const onCreateLinkQuickpay = function*(service, action) {
  try {
    const { createPaymentLink } = service
    const app = yield select(getCurrentApp)
    const policyId = getPolicyId(app)
    const user = yield select(getIdentityUser)
    const quickpayPayload = paymentLinkToCustomer(policyId, app)
    const result = yield call(createPaymentLink, user, quickpayPayload)
    const thaiDate = christianToThaiWithoutFormat(get('expiredDate', result))
    const expiredDateTime = convertMomentDateToObject(thaiDate)
    const ref = get('linkToken', result)
    yield put(setAppValue('quickpayLink.refToken', ref))
    yield put(setAppValue('quickpayLink.isSendLink', true))
    yield put(setAppValue('quickpayLink.expiredDateTime', expiredDateTime))

    yield call(
      confirmDialog,
      {
        title: 'ยืนยันการสร้างลิงก์',
        body: `<p>บริษัทฯ ได้ส่งลิงก์เพื่อชำระเบี้ยประกันให้กับผู้เอาประกันเรียบร้อยแล้ว <br> กรุณากด ยืนยัน เพื่อไปยังขั้นตอนถัดไป</p>
            <span class="info">ลิงก์ในการชำระเบี้ยประกันของท่านจะหมดอายุ <br> ภายในวันที่ ${expiredDateTime.date} เวลา ${expiredDateTime.time} น.</span>`,
      },
      { onlyConfirm: true }
    )
  } catch (error) {
    const errorMsg = get('response.data.message', error) || ''
    const isErrorMatchWithActivatedTransction = errorMsg.match(/The policy has activate transaction/)
    const isErrorMatchWithActivatedTransctionCode = errorMsg.match(/00018/)
    if (isErrorMatchWithActivatedTransction || isErrorMatchWithActivatedTransctionCode) {
      yield call(
        confirmDialog,
        {
          title: 'การสร้างลิงก์ไม่สำเร็จ',
          body:
            '<p>กรุณาตรวจสอบการชำระเงินกับลูกค้าของท่านในลิงก์แรก <br> ท่านสามารถสร้างลิงก์ใหม่ได้ใน 3 นาทีหลังสร้างลิงก์แรก</p>',
        },
        { onlyConfirm: true, confirmText: 'ตกลง' }
      )
    } else {
      yield call(
        confirmDialog,
        {
          title: 'การสร้างลิงก์ไม่สำเร็จ',
          body: '<p>กรุณากด ตกลง เพื่อกลับไปสร้างลิงก์ชำระเบี้ยอีกครั้ง หรือ เลือกช่องทางชำระเบี้ยแบบอื่น</p>',
        },
        { onlyConfirm: true, confirmText: 'ตกลง' }
      )
    }
  } finally {
    if (getToggles().ENABLE_MWGA4_TRACKING) {
      if (IsRealDevice) {
        const { sentEventToGA4 } = service
        const user = yield select(getIdentityUser)
        const dataMobileGA4 = {
          client_id: user.profile.agent_code,
          name: 'SENT_QUICKPAY_LINK' + IsRealDevice ? '_MOBILE' : '_WEB',
          event_category: 'QUICKPAY_LINK',
          label: '',
        }
        yield call(sentEventToGA4, user, dataMobileGA4)
      }
    } else {
      analytics.sendCustomEvent({
        category: 'QUICKPAY_LINK',
        action: 'SENT_QUICKPAY_LINK',
        label: IsRealDevice ? 'MOBILE' : 'WEB',
      })
    }
  }
}

export const onCreateLinkCCPA = function*(service, action) {
  try {
    const { createCCPAlink } = service
    const app = yield select(getCurrentApp)
    const policyId = getPolicyId(app)
    const user = yield select(getIdentityUser)

    const ccpaPayload = paymentLinkToCustomerCCPA(policyId, app)
    const result = yield call(createCCPAlink, user, ccpaPayload)
    const thaiDate = christianToThaiWithoutFormat(get('expiredDate', result))
    const expiredDateTime = convertMomentDateToObject(thaiDate)
    const ref = get('linkToken', result)
    yield put(setAppValue('CCPA.refToken', ref))
    yield put(setAppValue('CCPA.isSendLink', true))
    yield put(setAppValue('CCPA.expiredDateTime', expiredDateTime))

    if (result.linkToken) {
      yield call(
        confirmDialog,
        {
          title: 'ยืนยันการสร้างลิงก์สมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต',
          body: `<p>บริษัทฯ ได้สร้างลิงก์เพื่อสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต <br> ให้กับผู้เอาประกันเรียบร้อยแล้ว <br> กรุณากด ยืนยัน เพื่อไปยังขั้นตอนถัดไป</p>
              <span class="info">ลิงก์ในการสมัครของท่านจะหมดอายุภายในวันที่ ${expiredDateTime.date} เวลา ${expiredDateTime.time} น.</span>`,
          cssClass: 'modal-title-wide',
        },
        { onlyConfirm: true }
      )
    } else {
      yield call(
        confirmDialog,
        {
          title: 'การสร้างลิงก์ไม่สำเร็จ',
          body:
            '<p>ท่านได้ทำการสร้างลิงก์มากเกินที่กำหนด กรุณาดำเนินการสมัครที่ลิงก์ของท่าน หรือสมัครหักค่าเบี้ยประกันอัตโนมัติด้วยช่องทางอื่น</p>',
        },
        { onlyConfirm: true }
      )
    }
  } catch (error) {
    yield call(
      confirmDialog,
      {
        title: 'การสร้างลิงก์ไม่สำเร็จ',
        body:
          '<p>การสร้างลิงก์ไม่สำเร็จ กรุณากด ยืนยัน เพื่อดำเนินการสร้างลิงก์อีกครั้ง <br> หรือเปลี่ยนการสมัครด้วยช่องทางอื่น</p>',
      },
      { onlyConfirm: true }
    )
  } finally {
    if (getToggles().ENABLE_MWGA4_TRACKING) {
      const { sentEventToGA4 } = service
      const user = yield select(getIdentityUser)
      const dataMobileGA4 = {
        client_id: user.profile.agent_code,
        name: 'SEND_CCPA_LINK' + IsRealDevice ? '_MOBILE' : '_WEB',
        event_category: 'CCPA_TRACKING',
        label: '',
      }
      yield call(sentEventToGA4, user, dataMobileGA4)
    } else {
      analytics.sendCustomEvent({
        category: 'CCPA_TRACKING',
        action: 'SEND_CCPA_LINK',
        label: IsRealDevice ? 'MOBILE' : 'WEB',
      })
    }
  }
}

export const bypassPayingCounterToSigningPage = function*(service) {
  const { checkPaidQrPaymentStatus, createEcbrFiles } = service
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)
  const user = yield select(getIdentityUser)

  const applicationReceipts = getReceipts(app)
  let bankCode = getBankCode(applicationReceipts[3])
  let ref2 = applicationReceipts[3]?.ref2
  const amountApp = getPaymentActualAmount(applicationReceipts[3])

  try {
    const response = yield call(checkPaidQrPaymentStatus, user, {
      policyId,
      checkPaymentFlag: 'N',
      paidFromCounter: true,
      ref2,
      bankCode,
      amount: amountApp,
    })

    if (response.ecbrNo) {
      let receiptsWhenPaid = attachPaidPaymentIntoReceipts(response, applicationReceipts, 'net bank', false)
      receiptsWhenPaid[3].bypassPayingCounter = true
      yield put(setAppValue('receipts', receiptsWhenPaid)) // ISSUES
      yield call(createEcbrFiles, user, {
        policyId,
        ecbrNo: response.ecbrNo,
        ref2: receiptsWhenPaid[3]?.ref2,
        bankCode: getBankCode(receiptsWhenPaid[3]),
        bypassPayingCounter: receiptsWhenPaid[3].bypassPayingCounter,
      })
      return receiptsWhenPaid
    }
  } catch (e) {
    console.error(`bypassCreditCardToSigningPage :`, e.message)
  } finally {
    yield put(hideLoading())
  }
}

export const bypassCreditCardToSigningPage = function*(service) {
  const { checkPaidQrPaymentStatus, createEcbrFiles } = service
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)
  const user = yield select(getIdentityUser)

  const applicationReceipts = getReceipts(app)
  let bankCode = getBankCode(applicationReceipts[3])
  let ref2 = applicationReceipts[3]?.ref2
  const amountApp = getPaymentActualAmount(applicationReceipts[3])

  try {
    const response = yield call(checkPaidQrPaymentStatus, user, {
      policyId,
      checkPaymentFlag: 'N',
      bankCode,
      ref2,
      amount: amountApp,
    })

    if (response.ecbrNo) {
      yield put.resolve(showLoading())
      let receiptsWhenPaid = attachPaidPaymentIntoReceipts(response, getReceipts(app), 'credit card', false)
      yield put(setAppValue('receipts', receiptsWhenPaid))
      yield call(createEcbrFiles, user, {
        policyId,
        ecbrNo: response.ecbrNo,
        bankCode: getBankCode(receiptsWhenPaid[3]),
        ref2,
        bypassPayingCounter: receiptsWhenPaid[3].bypassPayingCounter,
      })
    }
  } catch (e) {
    console.error(`bypassCreditCardToSigningPage :`, e.message)
  } finally {
    yield put(hideLoading())
  }
}

export const onVerifyPayment = function*(service, action) {
  console.info('onVerifyPayment verifyQuickPayStatus')
  const { verifyQuickPayStatus, checkExistUnsignedPaymentReceipt } = service
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)
  try {
    const result = yield call(verifyQuickPayStatus, user, policyId)
    const expiredDateTime = get('quickpayLink.expiredDateTime', app)
    const maskedCardNo = getOr('', 'maskedCardNo', result)
    let receiptsWhenPaid = getReceipts(app)

    if (!isEmpty(result.linkStatus)) {
      const receipts = getReceiptsWithAmountAndType(app)
      let payment = {
        policyId,
        receipts,
      }
      const unsignedPaymentReceipt = yield call(checkExistUnsignedPaymentReceipt, user, payment)
      let cloneAppReceiptByType = []
      if (unsignedPaymentReceipt) {
        const receiptPaid = receiptWithPaid(receiptsWhenPaid)
        cloneAppReceiptByType = checkAppReceiptHaveMatchUnsigned(unsignedPaymentReceipt, receiptPaid) // RETURN OBJECT
      }

      const isSuccess = result.linkStatus === 'SUCCESS'
      if (isSuccess) {
        yield put(setAppValue('receipts[2].paid', true))

        if (getToggles().ENABLE_MWGA4_TRACKING) {
          const { sentEventToGA4 } = service
          const dataMobileGA4 = {
            client_id: user.profile.agent_code,
            name: 'PAYMENT_SUCCESS' + IsRealDevice ? '_MOBILE' : '_WEB',
            event_category: 'QUICKPAY_LINK',
            label: '',
          }
          yield call(sentEventToGA4, user, dataMobileGA4)
        } else {
          const dataGA4 = {
            category: 'QUICKPAY_LINK',
            action: 'PAYMENT_SUCCESS',
            label: '',
          }
          analytics.sendCustomEvent(dataGA4)
        }

        if (!_.isEmpty(cloneAppReceiptByType) && cloneAppReceiptByType) {
          receiptsWhenPaid = receiptsWhenPaid.map((receipt) => {
            if (
              receipt?.type &&
              cloneAppReceiptByType.type === PAYMENT_TYPE.CREDITCARD &&
              receipt?.type === PAYMENT_TYPE.CREDITCARD
            ) {
              // CREDIT CARD
              const objectCloneCreditCard = cloneDataUnsignedPaymentReceiptToCreditCard(cloneAppReceiptByType) // RETURN OBJECT
              console.info('START -- ONVERIPAY CONTINUE CLONE DATA CREDIT CARD')
              return {
                ...receipts[0],
                totalFirstPremiumPayment: objectCloneCreditCard?.amount,
                ...objectCloneCreditCard,
              }
            } else {
              return receipt
            }
          })
        }
        console.info('onVerifyPayment - receiptsWhenPaid', receiptsWhenPaid)
        yield put(setAppValue('receipts', receiptsWhenPaid))
        yield delay(4000)
      }
      console.info('onVerifyPayment - result', result)
      const thaiDate = christianToThaiWithoutFormat(get('successfullDateTime', result))
      const successfullDateTime = convertMomentDateToObject(thaiDate)
      const provider = getProviderFromUnsignPayment(cloneAppReceiptByType?.provider)
      yield put(setAppValue('quickpayLink.status', result.linkStatus))
      yield put(setAppValue('creditCardMethod', provider)) // ADD QP LINK BECAUSE CHANGE PAYMENT / VERIFY IS QUICKPAY
      const displayTime = isSuccess ? successfullDateTime : expiredDateTime
      yield call(confirmDialog, quickPayStatus[result.linkStatus](displayTime, maskedCardNo), {
        onlyConfirm: true,
      })
    }
  } catch (err) {
    yield put(setAppValue('quickpayLink.status', 'error'))
    const { dialogProps, message } = createDialogPropsForBypassToManuallyAttachSlip()
    yield call(confirmDialog, message, dialogProps)
  }
}

export const onVerifyCCPA = function*(service, history, action) {
  const { verifyCCPAStatus } = service
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)
  try {
    const result = yield call(verifyCCPAStatus, user, policyId)
    const expiredDateTime = get('CCPA.expiredDateTime', app)
    const maskedCardNo = getOr('', 'maskedCardNo', result)
    if (!isEmpty(result.linkStatus)) {
      const isSuccess =
        result.linkStatus === 'SUCCESS' || result.linkStatus === 'IN PROGRESS' || result.linkStatus === 'FAIL'
      const thaiDate = christianToThaiWithoutFormat(get('successfullDateTime', result))
      const successfullDateTime = convertMomentDateToObject(thaiDate)

      yield put(setAppValue('CCPA.status', result.linkStatus))
      yield put(setAppValue('CCPA.successfullDateTime', successfullDateTime))
      yield put(setAppValue('CCPA.maskedCardNo', maskedCardNo))
      if (!isSuccess && action.type !== 'RESEND_LINK_CCPA' && action.type !== 'CHANGE_CCPA_METHOD') {
        yield call(confirmDialog, ccpaStatus[result.linkStatus](expiredDateTime, maskedCardNo), {
          onlyConfirm: true,
        })
      }
      if (isSuccess) {
        if (getToggles().ENABLE_MWGA4_TRACKING) {
          const { sentEventToGA4 } = service
          const dataMobileGA4 = {
            client_id: user.profile.agent_code,
            name: 'CCPA_SUBSCRIBTION_SUCCESS' + IsRealDevice ? '_MOBILE' : '_WEB',
            event_category: 'CCPA_TRACKING',
            label: '',
          }
          yield call(sentEventToGA4, user, dataMobileGA4)
        } else {
          analytics.sendCustomEvent({
            category: 'CCPA_TRACKING',
            action: 'CCPA_SUBSCRIBTION_SUCCESS',
            label: IsRealDevice ? 'MOBILE' : 'WEB',
          })
        }
      }
    }

    return result.linkStatus
  } catch (err) {
    console.error(err)
    yield put(setAppValue('CCPA.status', 'ERROR'))
    const comfirmed = yield call(confirmDialog, ccpaStatus['ERROR'], { onlyConfirm: true })
    if (comfirmed) {
      history.replace(history.location.pathname.replace('/ccpa', ''))
    }
  }
}

export const onGA4VerifyCCPA = function*(service, history, action) {
  const { verifyCCPAStatus } = service
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)
  try {
    const result = yield call(verifyCCPAStatus, user, policyId)
    const expiredDateTime = get('CCPA.expiredDateTime', app)
    const maskedCardNo = getOr('', 'maskedCardNo', result)
    if (!isEmpty(result.linkStatus)) {
      const isSuccess =
        result.linkStatus === 'SUCCESS' || result.linkStatus === 'IN PROGRESS' || result.linkStatus === 'FAIL'
      const thaiDate = christianToThaiWithoutFormat(get('successfullDateTime', result))
      const successfullDateTime = convertMomentDateToObject(thaiDate)

      if (isSuccess) {
        getGA4ccpaSuccess()
      }

      yield put(setAppValue('CCPA.status', result.linkStatus))
      yield put(setAppValue('CCPA.successfullDateTime', successfullDateTime))
      yield put(setAppValue('CCPA.maskedCardNo', maskedCardNo))
      if (!isSuccess && action.type !== 'RESEND_LINK_CCPA' && action.type !== 'CHANGE_CCPA_METHOD') {
        yield call(confirmDialog, ccpaStatus[result.linkStatus](expiredDateTime, maskedCardNo), {
          onlyConfirm: true,
        })
      }
      if (isSuccess) {
        if (getToggles().ENABLE_MWGA4_TRACKING) {
          const { sentEventToGA4 } = service
          const dataMobileGA4 = {
            client_id: user.profile.agent_code,
            name: 'CCPA_SUBSCRIBTION_SUCCESS' + IsRealDevice ? '_MOBILE' : '_WEB',
            event_category: 'CCPA_TRACKING',
            label: '',
          }
          yield call(sentEventToGA4, user, dataMobileGA4)
        } else {
          analytics.sendCustomEvent({
            category: 'CCPA_TRACKING',
            action: 'CCPA_SUBSCRIBTION_SUCCESS',
            label: IsRealDevice ? 'MOBILE' : 'WEB',
          })
        }
      }
    }

    return result.linkStatus
  } catch (err) {
    console.error(err)
    yield put(setAppValue('CCPA.status', 'ERROR'))
    const comfirmed = yield call(confirmDialog, ccpaStatus['ERROR'], { onlyConfirm: true })
    if (comfirmed) {
      history.replace(history.location.pathname.replace('/ccpa', ''))
    }
  }
}

export const onCheckPaymentStatus = function*(service, action) {
  console.info('onCheckPaymentStatus verifyQuickPayStatus')
  const { verifyQuickPayStatus, checkExistUnsignedPaymentReceipt } = service
  const user = yield select(getIdentityUser)
  const app = yield select(getCurrentApp)
  const policyId = getPolicyId(app)
  let status = null
  try {
    const result = yield call(verifyQuickPayStatus, user, policyId)
    const expiredDateTime = get('quickpayLink.expiredDateTime', app)
    const maskedCardNo = getOr('', 'maskedCardNo', result)
    let receiptsWhenPaid = getReceipts(app)
    console.info(`result :`, result)
    console.info(`onCheckPaymentStatus result.linkStatus) :`, result.linkStatus)

    if (!isEmpty(result.linkStatus)) {
      const isSuccess = result.linkStatus === 'SUCCESS'
      status = result.linkStatus
      if (isSuccess) {
        const receipts = getReceiptsWithAmountAndType(app)
        let payment = {
          policyId,
          receipts,
        }
        const unsignedPaymentReceipt = yield call(checkExistUnsignedPaymentReceipt, user, payment)
        let cloneAppReceiptByType = []
        if (unsignedPaymentReceipt) {
          const receiptPaid = receiptWithPaid(receiptsWhenPaid)
          cloneAppReceiptByType = checkAppReceiptHaveMatchUnsigned(unsignedPaymentReceipt, receiptPaid) // RETURN OBJECT
        }
        yield put(setAppValue('receipts[2].paid', true))

        if (!_.isEmpty(cloneAppReceiptByType) && cloneAppReceiptByType) {
          receiptsWhenPaid = receiptsWhenPaid.map((receipt) => {
            if (
              receipt?.type &&
              cloneAppReceiptByType.type === PAYMENT_TYPE.CREDITCARD &&
              receipt?.type === PAYMENT_TYPE.CREDITCARD
            ) {
              // CREDIT CARD
              const objectCloneCreditCard = cloneDataUnsignedPaymentReceiptToCreditCard(cloneAppReceiptByType) // RETURN OBJECT
              console.info('START -- ONCHECKPAYMENT CONTINUE CLONE DATA CREDIT CARD')
              return {
                ...receipts[0],
                totalFirstPremiumPayment: objectCloneCreditCard?.amount,
                ...objectCloneCreditCard,
              }
            } else {
              return receipt
            }
          })
        }

        console.info('onCheckPaymentStatus - receiptsWhenPaid', receiptsWhenPaid)
        const thaiDate = christianToThaiWithoutFormat(get('successfullDateTime', result))
        const successfullDateTime = convertMomentDateToObject(thaiDate)
        const provider = getProviderFromUnsignPayment(cloneAppReceiptByType?.provider)
        yield put(setAppValue('receipts', receiptsWhenPaid))
        yield delay(4000)
        yield put(setAppValue('quickpayLink.status', result.linkStatus))
        yield put(setAppValue('creditCardMethod', provider)) // ADD QP LINK BECAUSE CHANGE PAYMENT / VERIFY IS QUICKPAY
        const displayTime = isSuccess ? successfullDateTime : expiredDateTime
        yield call(confirmDialog, quickPayStatus[result.linkStatus](displayTime, maskedCardNo), {
          onlyConfirm: true,
        })
      } else {
        yield put(setAppValue('quickpayLink.status', result.linkStatus))
        action?.payload?.toggleQuickpayModal()
      }
    }
    return result.linkStatus
  } catch (err) {
    if (status) {
      yield call(confirmDialog, quickPayStatus['ERROR'], { onlyConfirm: true })
    }
    yield put(setAppValue('quickpayLink.status', 'error'))
    action?.payload?.toggleQuickpayModal()
    console.error(err)
  }
}

export const onChangePaymentMethod = function*(service, action) {
  try {
    const quickpayStatus = yield call(onCheckPaymentStatus, service, action)
    if (quickpayStatus !== 'SUCCESS') {
      const confirmed = yield call(
        confirmDialog,
        {
          title: 'เปลี่ยนวิธีการชำระเบี้ย',
          body:
            '<p>กรุณากด ยืนยัน กรณีที่่ท่านต้องการกลับไปยังหน้าแรกเพื่อเปลี่ยนวิธีการชำระ <br>ระบบจะทำการยกเลิกการชำระผ่านลิงก์ของท่าน</p>',
        },
        { onlyConfirm: true }
      )
      if (confirmed) {
        yield put(setAppValue('quickpayLink.isSendLink', false))
        yield put(setAppValue('quickpayLink.status', ''))
        yield put(setAppValue('quickpayLink.isCancelled', true))
        yield put(setAppValue('creditCardMethod', ''))
        yield put(setAppValue('creditCardRelationship', ''))
        yield put(setAppValue('quickpayLink.expiredDateTime', null))
        if (getToggles().ENABLE_ECBR_SYSTEMIZATION) {
          yield put(unsetAppValue('payerInfo'))
        }
      }
    }
  } catch (err) {
    console.error(err)
  } finally {
    if (getToggles().ENABLE_MWGA4_TRACKING) {
      const { sentEventToGA4 } = service
      const user = yield select(getIdentityUser)
      const dataMobileGA4 = {
        client_id: user.profile.agent_code,
        name: 'CHANGE_PAYMENT_METHOD' + IsRealDevice ? '_MOBILE' : '_WEB',
        event_category: 'QUICKPAY_LINK',
        label: '',
      }
      yield call(sentEventToGA4, user, dataMobileGA4)
    } else {
      analytics.sendCustomEvent({
        category: 'QUICKPAY_LINK',
        action: 'CHANGE_PAYMENT_METHOD',
        label: IsRealDevice ? 'MOBILE' : 'WEB',
      })
    }
  }
}

export const onChangeCCPAMethod = function*(service, history, action) {
  try {
    const result = yield call(onVerifyCCPA, service, history, action)
    const isSuccess = result === 'SUCCESS' || result === 'IN PROGRESS' || result === 'FAIL'
    if (!isSuccess) {
      yield put(setAppValue('CCPA.isSendLink', false))
      yield put(setAppValue('CCPA.status', ''))
      yield put(setAppValue('CCPA.refToken', ''))
      yield put(setAppValue('CCPA.successfullDateTime', null))
      yield put(setAppValue('CCPA.expiredDateTime', null))

      history.replace(history.location.pathname.replace('/ccpa', ''))
    }
  } catch (err) {
    console.error(err)
  } finally {
    if (getToggles().ENABLE_MWGA4_TRACKING) {
      const { sentEventToGA4 } = service
      const user = yield select(getIdentityUser)
      const dataMobileGA4 = {
        client_id: user.profile.agent_code,
        name: 'CHANGE_SUBSCRIPTION_CCPA_METHOD' + IsRealDevice ? '_MOBILE' : '_WEB',
        event_category: 'CCPA_TRACKING',
        label: '',
      }
      yield call(sentEventToGA4, user, dataMobileGA4)
    } else {
      analytics.sendCustomEvent({
        category: 'CCPA_TRACKING',
        action: 'CHANGE_SUBSCRIPTION_CCPA_METHOD',
        label: IsRealDevice ? 'MOBILE' : 'WEB',
      })
    }
  }
}

export const onResendLinkCCPA = function*(service, history, action) {
  try {
    const result = yield call(onVerifyCCPA, service, history, action)
    const isSuccess = result === 'SUCCESS' || result === 'IN PROGRESS' || result === 'FAIL'
    if (!isSuccess) {
      yield put(setAppValue('CCPA.isSendLink', false))
      yield put(setAppValue('CCPA.status', ''))
      yield put(setAppValue('CCPA.refToken', ''))
      yield put(setAppValue('CCPA.successfullDateTime', null))
      yield put(setAppValue('CCPA.expiredDateTime', null))

      if (getToggles().ENABLE_MWGA4_TRACKING) {
        const { sentEventToGA4 } = service
        const user = yield select(getIdentityUser)
        const dataMobileGA4 = {
          client_id: user.profile.agent_code,
          name: 'RE_GENERATED_CCPA_LINK' + IsRealDevice ? '_MOBILE' : '_WEB',
          event_category: 'CCPA_TRACKING',
          label: '',
        }
        yield call(sentEventToGA4, user, dataMobileGA4)
      } else {
        analytics.sendCustomEvent({
          category: 'CCPA_TRACKING',
          action: 'RE_GENERATED_CCPA_LINK',
          label: '',
        })
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export const onUploadRemoteSellingReceipt = function*(service, history, action) {
  const { updateUnsignReceipt, createReceipt } = service
  const { data, name } = action.payload
  yield put.resolve(showLoading())
  yield put(setAppValue('ATP.bank', get('selectedBank.bank', data)))
  try {
    const app = yield select(getCurrentApp)
    const receipts = flow(
      getReceipts,
      mapWithKey((receipt, key) => {
        let response = {
          key,
          amount: toString(getReceiptAmount(receipt)),
          type: getReceiptType(receipt),
          transactionId: getReceiptTransactionId(receipt),
          currency: 'thb',
        }
        return response
      }),
      reject(({ amount }) => toFinite(amount) === 0),
      reject(({ type }) => isNil(type))
    )(app)
    const policyId = getPolicyId(app)
    const user = yield select(getIdentityUser)
    const payment = {
      policyId,
      receipts,
      bank: get('selectedBank.bank', data),
    }

    yield call(uploadDocumentFromUrl, service, {
      payload: { name, url: data.images[0].url, isInsured: false, fetch: true },
    })
    const response = yield call(createReceipt, user, payment)
    const receiptsWhenPaid = getReceipts(app).map((receipt) =>
      receipt?.type === get('[0].type', response)
        ? {
            ...receipts[0],
            totalFirstPremiumPayment: get('[0].amount', response),
            receiptNo: get('[0].receiptNo', response),
            paid: true,
          }
        : receipt
    )
    yield call(uploadDocumentFromUrl, service, {
      payload: {
        name: `${name}_${get('[0].receiptNo', response)}`,
        url: data.images[0].url,
        isInsured: false,
        fetch: true,
      },
    })

    yield [put(setAppValue('receipts', receiptsWhenPaid)), take('SAVE_APPLICATION_SUCCESS')]
    const params = {
      type: 'net bank',
      bank: get('selectedBank.bank', data),
      bankReceiptDate: moment(getOr(new Date(), 'selectedBank.uploadDate', data)).format('L'),
      policyId,
      receiptNo: get('[0].receiptNo', response),
    }
    yield call(updateUnsignReceipt, user, params)
    yield [put(setAppValue('isEvidenceUploaded', true)), take('SAVE_APPLICATION_SUCCESS')]
    yield put(hideLoading())
  } catch (e) {
    console.error(e)
    yield put(addError(name, e))
  }
}

function* onCreateCcpaDefaultValue(service, action) {
  try {
    // const app = yield select(getCurrentApp)
    yield put({
      type: 'SET_APP_VALUE',
      fieldId: 'CCPA',
      value: action.value,
    })
  } catch (e) {
    console.error(e)
    yield put(addError(name, e))
  }
}

export const onUpdateConsentEda = function*(service, history) {
  if (getToggles().ENABLE_EDA) {
    const app = yield select(getCurrentApp)
    const relationshipList = yield select(getRelationshipListForNewATP)
    try {
      yield put(showLoading())

      const relationType = getATPDefaultRelation(app)

      const relationship = find(({ value }) => value === relationType, relationshipList)
      yield put(setAppValue('ATP.relations', relationship))
      yield put(setAppValue('consentDA', true))
    } catch (err) {
      console.error(err)
    } finally {
      yield put(hideLoading())
    }
  }
}

export const onCreateEcbrNo = function*(service, action) {
  const { updateUnsignReceipt } = service
  try {
    const app = yield select(getCurrentApp)
    const user = yield select(getIdentityUser)
    const policyId = getPolicyId(app)

    const payerInfo = get('payerInfo', app)
    const isCreateEcbrNo = payerInfo?.ecbrNo ? true : false
    const isBypassPayingCounter = getBypassPayingCounter(app)
    const receiptsWhenPaid = getReceipts(app).map((item) => {
      return item.paid
        ? {
            ...item,
            receiptNo: payerInfo?.ecbrNo,
            oldReceiptNo: payerInfo?.oldReceiptNo,
            isCreateEcbrNo: isCreateEcbrNo,
          }
        : item
    })

    const receiptPaid = receiptWithPaid(receiptsWhenPaid)
    const params = {
      ...receiptPaid,
      receiptNo: payerInfo?.ecbrNo,
      policyId,
      bypassPayingCounter: isBypassPayingCounter ? true : false,
    }
    yield call(updateUnsignReceipt, user, params)
    yield take('ECBR_PHOTO_SUCCESS')

    // NOT ALLOW FOR CREDIT CARD
    if (payerInfo?.oldReceiptNo && payerInfo?.oldReceiptNo !== receiptPaid?.receiptNo) {
      const documentType = getReceiptDocumentType(receiptPaid)
      const documentTypeName = documentEcbrNewName(documentType.name, payerInfo?.ecbrNo)
      const oldDocumentTypeName = documentEcbrNewName(documentType.name, payerInfo?.oldReceiptNo)
      yield put(saveEcbrPhoto(oldDocumentTypeName, documentTypeName))
    }
    yield [put(setAppValue('receipts', receiptsWhenPaid)), take('SAVE_APPLICATION_SUCCESS')]
  } catch (e) {
    console.error(e)
    yield put(setAppValue('payerInfo.status', 'error'))
    yield put(setShowEcbrSystemizationFailedModal())
    yield put(unsetAppValue('submitWithoutPayment.selected'))
  }
}

export const onDownloadEcbrFile = function*(service, action) {
  const { createDownloadEcbrFile } = service
  try {
    const app = yield select(getCurrentApp)
    const user = yield select(getIdentityUser)
    const ecbrNo = get('payerInfo.ecbrNo', app)
    yield call(createDownloadEcbrFile, user, ecbrNo)
  } catch (e) {
    console.error(e)
  }
}

export const setEDACheckStatus = function*() {
  yield put(setAppValue('ATP.checkStatusEDA', true))
  yield put(saveAppData())
}

export const removeEDACheckStatus = function*(service, history) {
  yield put(unsetAppValue('ATP.checkStatusEDA'))
  yield put(saveAppData())

  history.replace(history.location.pathname.replace('/eda', ''))
}

// EDA request logic
export const onCreateNewEdaRequest = function*(service, action) {
  const { createEdaRequest } = service

  try {
    const app = yield select(getCurrentApp)
    const isShowCheckStatusPage = isCheckStatusEDA(app)

    yield put(showLoading())
    const resp = yield call(createEdaRequest, action.payload)

    // Handling statusText = ["TECHNICAL_ERROR", "INVALID_ACCOUNT_NO"] ---> error
    // "TOKEN_EXPIRED" ---> this status is on checkStatus process (not register)
    if (resp) {
      yield put(hideLoading())
      if (resp.statusText === 'SUCCESS') {
        // Success case
        yield call(
          confirmDialogEDA,
          {
            title: 'ยืนยันสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
            body:
              'อยู่ระหว่างรอดำเนินการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคารกรุณากด ยืนยัน เพื่อตรวจสอบสถานะการสมัคร',
          },
          {
            onlyConfirm: true,
            confirmText: 'ยืนยัน',
            customConfirmWithDispatchName: 'SET_EDA_CHECK_STATUS',
          }
        )
      } else if (resp.statusText === 'INVALID_ACCOUNT_NO') {
        // data invalid case
        yield call(
          confirmDialogEDA,
          {
            title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
            body: 'ระบบไม่สามารถทำรายการได้ เนื่องจากข้อมูลบัญชีไม่ถูกต้องกรุณาตรวจสอบข้อมูลการสมัครอีกครั้ง',
          },
          {
            onlyConfirm: true,
            confirmText: 'ยืนยัน',
          }
        )
      } else if (resp.statusText === 'DUPLICATED_DATA') {
        // this one for users who register EDA directly from NEXT app
        yield call(
          confirmDialogEDA,
          {
            title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
            body:
              'ระบบไม่สามารถทำรายการได้ เนื่องจากท่านทำการสมัครผ่าน KTB Next โดยตรงเข้ามาแล้ว กรุณาเปลี่ยนเป็น DA เข้ามาแทน',
          },
          {
            onlyConfirm: true,
            confirmText: 'ยืนยัน',
          }
        )
      } else if (resp.statusText === 'TECHNICAL_ERROR') {
        // error case
        yield call(
          confirmDialogEDA,
          {
            title: 'ระบบการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคารขัดข้อง',
            body: 'กรุณาทำรายการใหม่อีกครั้งในภายหลังหรือเปลี่ยนวิธีการสมัคร',
          },
          {
            onlyConfirm: true,
            confirmText: 'ยืนยัน',
          }
        )
      } else if (resp.statusText === 'PREVIOUS_SUCCESS' && isShowCheckStatusPage) {
        // previous success case --> resend /register on check status page
        yield call(
          confirmDialogEDA,
          {
            title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
            body:
              '<p>ระบบตรวจสอบพบลูกค้าทำรายการสมัครก่อนหน้านี้สำเร็จแล้ว</p>กรุณา กดยืนยัน เพื่อตรวจสอบสถานะอีกครั้ง',
          },
          {
            onlyConfirm: true,
            confirmText: 'ยืนยัน',
            customConfirmWithDispatchName: 'EDA_CONFIRM_PREVIOUS_SUCCESS',
          }
        )
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export const onGetEdaStatus = function*(service, action) {
  const { getEdaStatusRequest } = service

  try {
    const app = yield select(getCurrentApp)
    const policyId = getPolicyId(app)
    const recurringPayment = getRecurringPayment(app)

    yield put(startEDACheckingStatus())

    const bankBookNumber = get('bankBookNumber', recurringPayment)
    const payload = {
      accountNo: bankBookNumber,
      policyNo: policyId,
      bankName: 'KTB', // only KTB for now
    }
    const resp = yield call(getEdaStatusRequest, payload)

    if (resp) {
      yield put(stopEDACheckingStatus())

      const last4DigitAccNo = bankBookNumber.slice(-4)

      if (resp.statusText === 'SUCCESS') {
        // Success case [2597]
        yield call(
          confirmDialogEDA,
          {
            title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
            body: `การสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคารกรุงไทยเลขที่บัญชี x-${last4DigitAccNo} (4 ตัวท้าย) สำเร็จ`,
          },
          {
            onlyConfirm: true,
            confirmText: 'ยืนยัน',
            customConfirmWithDispatchName: 'EDA_CHECK_STATUS_SUCCESS',
          }
        )
      } else if (resp.statusText === 'TOKEN_EXPIRED') {
        // token expired case [2538]
        yield call(
          confirmDialogEDA,
          {
            title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
            body: 'ท่านทำรายการเกินระยะเวลาที่กำหนด กรุณาทำรายการใหม่อีกครั้งหรือเปลี่ยนวิธีการสมัคร',
          },
          {
            onlyConfirm: true,
            confirmText: 'ยืนยัน',
            customConfirmWithDispatchName: 'EDA_CHECK_STATUS_EXPIRED',
          }
        )
      } else if (resp.statusText === 'REJECTED') {
        // rejected case [2578]
        yield call(
          confirmDialogEDA,
          {
            title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
            body: `การสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคารกรุงไทยเลขที่บัญชี x-${last4DigitAccNo} (4 ตัวท้าย) ไม่สำเร็จ กรุณาตรวจสอบข้อมูลหรือสมัครอีกครั้งด้วยเลขที่บัญชีใหม่`,
          },
          {
            onlyConfirm: true,
            confirmText: 'ยืนยัน',
            customConfirmWithDispatchName: 'EDA_CHECK_STATUS_REJECTED',
          }
        )
      } else if (resp.statusText === 'TECHNICAL_ERROR' || resp.statusText === 'PENDING') {
        // error case [2536]
        yield call(
          confirmDialogEDA,
          {
            title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
            body: 'ไม่พบข้อมูลการสมัคร กรุณาตรวจสอบผลการสมัครผ่านธนาคารที่ท่านทำรายการอีกครั้ง หรือเปลี่ยนวิธีการสมัคร',
          },
          {
            onlyConfirm: true,
            confirmText: 'ยืนยัน',
            customConfirmWithDispatchName: 'EDA_CHECK_STATUS_ERROR',
          }
        )
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export const checkPaymentStatusInternalServerError = function*() {
  yield call(
    confirmDialog,
    {
      title: 'ระบบไม่สามารถออกใบรับเงินชั่วคราวได้',
      body: `<p>ขออภัยในความไม่สะดวก <br> ขณะนี้ระบบไม่สามารถดำเนินการออกใบรับเงินชั่วคราวได้ กรุณาลองใหม่อีกครั้ง</p>`,
    },
    { onlyConfirm: true }
  )
}

export const pretendPayingQrCode = function*(service) {
  const { callCreatePaymentStatusSuccessForQrPayment } = service
  const app = yield select(getCurrentApp)
  const applicationReceipts = getReceipts(app)
  if (applicationReceipts && applicationReceipts[3]) {
    const policyId = getPolicyId(app)
    const ref2 = applicationReceipts[3]?.ref2
    const user = yield select(getIdentityUser)
    const payload = {
      paymentStatus: 'SUCCESS',
      policyId,
      ref2,
    }
    yield call(callCreatePaymentStatusSuccessForQrPayment, user, payload)
  }
}

export default function*(service, history) {
  yield [
    takeLatest(
      (action = {}) => action.type === 'SET_DEFAULT_APP_VALUE' && startsWith('receipts', action.fieldId),
      onSetDefaultReceiptValue
    ),
    takeLatest('CREATE_RECEIPT', onCreateReceipt, service),
    takeLatest('UPDATE_RECEIPT', onUpdateReceipt, service),
    takeLatest('UPDATE_UNSIGN_RECEIPT', onUpdateUnsignReceipt, service),
    takeLatest('UPDATE_RECURRING_PAYMENT', onUpdateRecurringPayment, service),
    takeLatest('SUBMIT_RECURRING_PAYMENT', onSubmitRecurringPayment, service, history),
    takeLatest('UPDATE_CONSENT_EDA_TERMS', onUpdateConsentEda, service, history),
    takeLatest('SUBMIT_CREDIT_CARD', onSubmitCreditCard, service),
    takeLatest('REMOVE_PAYMENT_RECEIPT', onRemovePaymentReceipt, service),
    takeLatest('START_CYBS_PAYMENT', onStartCybsPayment, service, history),
    takeLatest('START_CYBS_RETRY', onStartCybsRetry, service, history),
    takeLatest('START_CYBS_CHECK_RETRY', onStartCybsCheckRetry, service),
    takeLatest('SEND_LINK_QUICK_PAY', onCreateLinkQuickpay, service),
    takeLatest('SEND_LINK_CCPA', onCreateLinkCCPA, service),
    takeLatest('RESEND_LINK_CCPA', onResendLinkCCPA, service, history),
    takeLatest('VERIFY_PAYMENT', onVerifyPayment, service),
    takeLatest('VERIFY_CCPA', onVerifyCCPA, service, history),
    takeLatest('CHECK_PAYMENT_STATUS', onCheckPaymentStatus, service),
    takeLatest('CHECK_QR_PAYING', checkQrPaying, service),
    takeLatest('CHANGE_PAYMENT_METHOD', onChangePaymentMethod, service),
    takeLatest('CHANGE_CCPA_METHOD', onChangeCCPAMethod, service, history),
    takeLatest('UPLOAD_REMOTE_SELLING_RECEIPT', onUploadRemoteSellingReceipt, service, history),
    takeLatest('CREATE_CCPA_DEFAULT_VALUE', onCreateCcpaDefaultValue, service),
    takeLatest('CREATE_ECBR_NO', onCreateEcbrNo, service),
    takeLatest('SAVE_DOWNLOAD_ECBR_FILE', onDownloadEcbrFile, service),
    takeLatest('CREATE_NEW_EDA', onCreateNewEdaRequest, service),
    takeLatest('GET_EDA_STATUS', onGetEdaStatus, service),
    takeLatest('BYPASS_CREDIT_CARD_TO_SIGNING_PAGE', bypassCreditCardToSigningPage, service),
    takeLatest('BYPASS_PAYING_COUNTER_TO_SIGNING_PAGE', bypassPayingCounterToSigningPage, service),
    takeLatest('SET_EDA_CHECK_STATUS', setEDACheckStatus),
    takeLatest('REMOVE_EDA_CHECK_STATUS', removeEDACheckStatus, service, history),
    takeLatest('CHECK_PAYMENT_STATUS_SERVER_ERROR', checkPaymentStatusInternalServerError, service, history),
    takeLatest('PRETEND_PAYING_QR_CODE', pretendPayingQrCode, service, history),
  ]
}
